const versionService = {
  version: process.env.REACT_APP_VERSION,

  build: process.env.REACT_APP_BUILD_ID,

  env: process.env.REACT_APP_ENV,

  getFormattedVersion: () => {
    return `v${versionService.version}-${versionService.build} (env:${versionService.env})`;
  },
};

export default versionService;
