import Comparator from "../bids/helper/Comparator";

const AD_MODEL_CONTEXT = {
  // business context info:
  //   group by request id & ad id (order using prevtenant/nexttenant(/ttype if needed))
  //   keep ad id 0 isolated
  //  TODO order by ads: zonerolltype (pre, mid, post, und, any), zonetype (audio, display, any), adid (ASC)

  contractImps: undefined, // imps ordered using the tx type
  adImps: undefined, // should be ONE entry
  adTrails: undefined, // array of list of ordered nodes (events)
};

const processContractImpEvents = (adModelItems, contractImpEvents) => {
  contractImpEvents.forEach((event) => {
    const requestid = event.requestid;
    const adid = event.adid;

    // group events with same request id
    let adModelItem = adModelItems.find(
      ($) =>
        ($.contractImps &&
          $.contractImps.find(
            ($$) => $$ && $$.requestid === requestid && $$.adid === adid
          ) !== undefined) ||
        ($.adImps &&
          $.adImps.find(
            ($$) => $$ && $$.requestid === requestid && $$.adid === adid
          ) !== undefined)
    );

    // init case
    if (!adModelItem) {
      adModelItem = {
        ...AD_MODEL_CONTEXT,
        contractImps: [],
        adImps: [],
      };

      adModelItems.push(adModelItem);
    }

    adModelItem.contractImps.push(event);
  });

  // reminder hops are not ordered at this stage!

  // order cimps ads -> PRE, MID, POST, UND, rest
  adModelItems.sort((i1, i2) => {
    const cis1 = i1.contractImps;
    const cis2 = i2.contractImps;

    if (!(cis1 && cis1.length > 0 && cis2 && cis2.length > 0)) return 0;

    return zoneRollTypeComp(cis1[0], cis2[0]);
  });
};

const processImpEvents = (adModelItems, impEvents) => {
  impEvents.forEach((event) => {
    const requestid = event.requestid;
    const adid = event.adid;

    // group events with same request id
    let adModelItem = adModelItems.find(
      ($) =>
        ($.contractImps &&
          $.contractImps.find(
            ($$) => $$ && $$.requestid === requestid && $$.adid === adid
          ) !== undefined) ||
        ($.adImps &&
          $.adImps.find(
            ($$) => $$ && $$.requestid === requestid && $$.adid === adid
          ) !== undefined)
    );

    // init case
    if (!adModelItem) {
      adModelItem = {
        ...AD_MODEL_CONTEXT,
        contractImps: [],
        adImps: [],
      };
      adModelItems.push(adModelItem);
    }

    adModelItem.adImps.push(event);
  });

  // reminder hops are not ordered at this stage!

  // order adImps ads -> adid
  adModelItems.sort((i1, i2) => {
    const ais1 = i1.adImps;
    const ais2 = i2.adImps;

    if (!(ais1 && ais1.length > 0 && ais2 && ais2.length > 0)) return 0;

    return ais1[0].adid - ais2[0].adid;
  });
};

const removeItem = (items, i) =>
  items.slice(0, i).concat(items.slice(i + 1, items.length));

// try to find a case with the zrt matching, pick one if none
const selectEventIndex = (imps, event) => {
  const index = imps.findIndex(
    ($) =>
      event.client === $.prevtenant && event.zonerolltype === $.zonerolltype
  );

  return index === -1
    ? imps.findIndex(($) => event.client === $.prevtenant)
    : index;
};

const AdModel = {
  populateModel: (contractImpEvents, impEvents) => {
    const adModelItems = [];

    if (contractImpEvents && contractImpEvents.length > 0)
      processContractImpEvents(adModelItems, contractImpEvents);

    if (impEvents && impEvents.length > 0)
      processImpEvents(adModelItems, impEvents);

    // normal case: a > b > c > d (just order using prev/next tenant)
    //   if one entry, just use it and return
    //   (put the one with campaign id at the end of the trail)
    // "multipicity case": (2 hops path with same request id & adid)
    //   podfrontSSP>podfrontSSP>siriusxmpartnersSSP>communicorpieSSP>communicorpieSSP>siriusxmpartnersSSP>amnetAS>amnetAS
    //   -> siriusxmpartnersSSP > podfrontSSP > communicorpieSSP > amnetAS (PRE)
    //    & siriusxmpartnersSSP > podfrontSSP > communicorpieSSP > amnetAS (MID)
    // "garbage case":
    //   remaining entries are just regrouped together in a single entry (present in case of missing imp events for instance)

    adModelItems.forEach((adModelItem) => {
      // init contractImps & adImps arrays
      let rcis = [...adModelItem.contractImps]; // shallow copy! containing the remaining items that must be added to the ordered trail(s)
      let rais = [...adModelItem.adImps]; // shallow copy! containing the remaining items that must be added to the ordered trail(s)

      // isolate first tenant events
      let firstTenantGroup = [];
      firstTenantGroup.push(...rcis.filter(($) => !$.prevtenant));
      rcis = rcis.filter(($) => $.prevtenant);
      firstTenantGroup.push(...rais.filter(($) => !$.prevtenant)); // should always be empty
      rais = rais.filter(($) => $.prevtenant);

      adModelItem.adTrails = [];

      const clientCounts = firstTenantGroup.reduce(
        (map, $) => map.set($.client, (map.get($.client) || 0) + 1),
        new Map()
      );

      clientCounts.forEach((count, client) => {
        const multiplicity = clientCounts.get(client);

        for (let i = 0; i < multiplicity; i++) {
          const index1 = firstTenantGroup.findIndex(($) => $.client === client);
          const event = firstTenantGroup[index1];
          firstTenantGroup = removeItem(firstTenantGroup, index1);

          const adTrail = [];
          adModelItem.adTrails.push(adTrail);
          adTrail.push(event);

          let currentEvent = event;
          let notFound = false;
          while (!notFound) {
            const index = selectEventIndex(rcis, currentEvent);
            if (index !== -1) {
              const ci = rcis[index];
              adTrail.push(ci);
              currentEvent = ci;
              rcis = removeItem(rcis, index);
            } else {
              notFound = true;

              const index = selectEventIndex(rais, currentEvent);
              if (index !== -1) {
                const ai = rais[index];
                adTrail.push(ai);
                currentEvent = ai; // no real point
                rais = removeItem(rais, index);
              }
            }
          }
        }
      });

      // put any remaining entries in a dedicated array, any order...
      if (firstTenantGroup.length > 0 || rcis.length > 0 || rais.length > 0) {
        adModelItem.adTrails.push([...firstTenantGroup, ...rcis, ...rais]);
      }
    });

    return adModelItems;
  },
};

const ZONE_ROLL_TYPE_ORDER = ["PRE_ROLL", "MID_ROLL", "POST_ROLL", "UNDEFINED"];

const zoneRollTypeComp = (a, b) =>
  Comparator.listBasedComp(
    a.zonerolltype,
    b.zonerolltype,
    ZONE_ROLL_TYPE_ORDER
  );

export default AdModel;
