import React from "react";
import "./CommonButton.css";
import { Button } from "antd";
import { ButtonType } from "antd/es/button";

// Define prop types for the component
interface CommonButtonProps {
  onClick?: () => void;
  className?: string;
  type?: ButtonType; // Use Ant Design's ButtonType for proper typing
  children?: React.ReactNode;
  href?: string;
  title?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  danger?: boolean;
}

const CommonButton: React.FC<CommonButtonProps> = ({
  onClick,
  className = "",
  type = "link",
  children = "",
  href,
  title,
  style,
  loading,
  disabled,
  danger,
}) => (
  <Button
    type={type}
    onClick={onClick}
    className={className}
    href={href}
    target={href ? "_blank" : undefined} // Only set target if href exists
    title={title}
    style={style}
    loading={loading}
    disabled={disabled}
    danger={danger}
  >
    {children}
  </Button>
);

export default CommonButton;
