import React, { useState } from "react";
import { Col, Drawer, List, Row, Select, Typography } from "antd";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import CommonButton from "../../common/CommonButton";
import ReactDragListView from "react-drag-listview";

const { Paragraph, Text } = Typography;

const { Option } = Select;

const preSelectedFieldNames = [
  "listenerid",
  "continent",
  "country",
  "region",
  "city",
  "playername",
  "ip",
  "os",
  "browser",
  "useragent",
  "devicename",
];

const referenceFieldNameList = [
  ...preSelectedFieldNames,
  ...[
    "agesegment",
    "areacode",
    "devicetype",
    "devicemanufacturer",
    "dma",
    "gendersegment",
    "languagedevice",
    "latitude",
    "longitude",
    "msa",
    "playerdevicename",
    "postalcode",
    "subregion",
    "utcoffset",
    "gdpr",
    "dataprivacyconsent",
    "dataprivacysource",
    "identifiability",
  ],
];

const AdDataSummary = ({ adModel0 }) => {
  preSelectedFieldNames.sort();
  referenceFieldNameList.sort();

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const buildOptions = (fields) => {
    if (!fields) return;

    //TODO return normalizeFields(fields).map((field) => (
    return fields.map((field) => (
      <Option key={field} value={field}>
        {field}
      </Option>
    ));
  };

  // drawer + drag & drop
  const [items, setItems] = useState(preSelectedFieldNames);
  const onChange = (item) => {
    // add to item list if not present
    if (items.includes(item)) return;
    setItems((oldItems) => [...oldItems, item]);

    clearSelected();
  };
  const onRemoveItem = (item) => {
    // remove from item list if present
    if (!items.includes(item)) return;
    const newItems = items.filter((e) => e !== item);
    setItems((oldItems) => [...newItems]);
  };
  const [selected, setSelected] = useState();
  // handler
  const clearSelected = () => {
    setSelected(null);
  };
  const onDragEnd = (fromIndex, toIndex) => {
    // Ignores if outside designated area
    if (toIndex < 0) return;

    const newItems = [...items];
    const item = newItems.splice(fromIndex, 1)[0];
    newItems.splice(toIndex, 0, item);

    setItems((oldItems) => [...newItems]);
  };

  const data =
    (adModel0.contractImps &&
      adModel0.contractImps.length > 0 &&
      adModel0.contractImps[0]) ||
    (adModel0.adImps && adModel0.adImps.length > 0 && adModel0.adImps[0]) ||
    (adModel0.remainingContractImps &&
      adModel0.remainingContractImps.length > 0 &&
      adModel0.remainingContractImps[0]) ||
    []; // should not happen

  const resetList = () => {
    setItems((oldItems) => [...preSelectedFieldNames]);
  };

  const clearList = () => {
    setItems((oldItems) => []);
  };

  const showAll = () => {
    setItems((oldItems) => [...referenceFieldNameList]);
  };

  return (
    <Row>
      <Col span={1}>
        <CommonButton
          title="Configure Field List"
          type="text"
          onClick={showDrawer}
        >
          <SettingOutlined />
        </CommonButton>
        <Drawer
          title="Select your Fields"
          placement="right"
          onClose={onClose}
          visible={visible}
          width={450}
          footer={
            <span style={{ color: "lightgrey" }}>
              Common Ad Fields [{referenceFieldNameList.length}]
            </span>
          }
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a field"
            onChange={onChange}
            value={selected}
          >
            {buildOptions(referenceFieldNameList)}
          </Select>
          <CommonButton
            title="Select All Fields"
            type="text"
            onClick={showAll}
            size="small"
          >
            All
          </CommonButton>
          <CommonButton
            title="Clear Field List"
            type="text"
            onClick={clearList}
            size="small"
          >
            Clear
          </CommonButton>
          <CommonButton
            title="Reset Field List"
            type="text"
            onClick={resetList}
            size="small"
          >
            Reset
          </CommonButton>
          <ReactDragListView
            nodeSelector=".ant-list-item.draggble"
            onDragEnd={onDragEnd}
          >
            <div style={{ width: "90%" }}>
              <List
                size="small"
                dataSource={items}
                renderItem={(item) => {
                  return (
                    <List.Item
                      className={"draggble"}
                      extra={
                        <CommonButton
                          type="text"
                          size="small"
                          onClick={() => onRemoveItem(item)}
                        >
                          <CloseOutlined />
                        </CommonButton>
                      }
                    >
                      {item}
                    </List.Item>
                  );
                }}
              />
            </div>
          </ReactDragListView>
        </Drawer>
      </Col>
      <Col span={23}>
        <Row>
          {items.map(($, index) => {
            return (
              <Col span={8} key={index}>
                <Paragraph
                  ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                >
                  <Text strong>{$}</Text> : {data[$]}
                </Paragraph>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default AdDataSummary;
