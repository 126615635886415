import React from "react";
import { Space, Typography } from "antd";
import Impression from "./Impression";
import Report from "./Report";
import ThirdPartyTracking from "./ThirdPartyTracking";
import Click from "./Click";

const { Text } = Typography;

const AdDataDetailsAdImpression = ({ impEvent, index }) => {
  const client = impEvent.client;
  const agencyid = impEvent.demandagencyid;
  const campaignid = impEvent.campaignid;
  const adid = impEvent.adid;
  const zoneid = impEvent.zoneid;
  const zrt = impEvent.zonerolltype;

  return (
    <li key={index}>
      <Space>
        <Text strong>{client}</Text>
        <span style={{ fontSize: "10px" }}>
          (Agency ID:{agencyid}, Campaign ID:
          {campaignid}, zrt: {zrt})
        </span>
      </Space>
      <Impression client={client} adid={adid} eventType="AD_IMPRESSION" />
      <Report client={client} adid={adid} eventType="AD_REPORT" />
      <ThirdPartyTracking client={client} adid={adid} zoneid={zoneid} />
      <Click client={client} adid={adid} />
    </li>
  );
};

export default AdDataDetailsAdImpression;
