import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Input, Tooltip, Tree } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const fetchAudienceSegmentTypes = ({
  setAudienceSegmentTypesData,
  setAudienceSegmentTypesError,
}) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.audienceSegmentTypesEndpoint}`
    )
    .then((result) => result.data)
    .then(setAudienceSegmentTypesData)
    .catch(setAudienceSegmentTypesError);
};

const fetchAudienceSegmentFields = ({
  setAudienceSegmentFieldsData,
  setAudienceSegmentFieldsError,
}) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.audienceSegmentFieldsEndpoint}`
    )
    .then((result) => result.data)
    .then(setAudienceSegmentFieldsData)
    .catch(setAudienceSegmentFieldsError);
};

const fetchEventTypeList = ({
  setEventTypeListData,
  setEventTypeListError,
}) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.eventTypeListEndpoint}`
    )
    .then((result) => result.data)
    .then(setEventTypeListData)
    .catch(setEventTypeListError);
};

const fetchEventTypeFieldList = ({
  setEventTypeFieldData,
  setEventTypeFieldError,
}) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.eventTypeFieldListEndpoint}`
    )
    .then((result) => result.data)
    .then(setEventTypeFieldData)
    .catch(setEventTypeFieldError);
};

const nestedRowStructureToVirtualFields = (field) => {
  return (
    field &&
    field.type &&
    field.type
      .replace("array(row(", "")
      .replace("))", "")
      .split(",")
      .map(($) => {
        const s = $.trim().split(" ");
        return { name: s[0], type: s[1], nested: false, parent: field.name };
      })
  );
};

export const SearchListItem = ({ jobStarted, jobOver, onTreeDoubleClick }) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const [eventTypeFieldData, setEventTypeFieldData] = useState(undefined);
  const [eventTypeFieldError, setEventTypeFieldError] = useState(undefined);
  useEffect(() => {
    !(eventTypeFieldData || eventTypeFieldError) &&
      fetchEventTypeFieldList({
        setEventTypeFieldData,
        setEventTypeFieldError,
      });
  }, [eventTypeFieldData, eventTypeFieldError]);

  const [eventTypeListData, setEventTypeListData] = useState(undefined);
  const [eventTypeListError, setEventTypeListError] = useState(undefined);
  useEffect(() => {
    !(eventTypeListData || eventTypeListError) &&
      fetchEventTypeList({ setEventTypeListData, setEventTypeListError });
  }, [eventTypeListData, eventTypeListError]);

  const [audienceSegmentFieldsData, setAudienceSegmentFieldsData] =
    useState(undefined);
  const [audienceSegmentFieldsError, setAudienceSegmentFieldsError] =
    useState(undefined);
  useEffect(() => {
    !(audienceSegmentFieldsData || audienceSegmentFieldsError) &&
      fetchAudienceSegmentFields({
        setAudienceSegmentFieldsData,
        setAudienceSegmentFieldsError,
      });
  }, [audienceSegmentFieldsData, audienceSegmentFieldsError]);

  const [audienceSegmentTypesData, setAudienceSegmentTypesData] =
    useState(undefined);
  const [audienceSegmentTypesError, setAudienceSegmentTypesError] =
    useState(undefined);
  useEffect(() => {
    !(audienceSegmentTypesData || audienceSegmentTypesError) &&
      fetchAudienceSegmentTypes({
        setAudienceSegmentTypesData,
        setAudienceSegmentTypesError,
      });
  }, [audienceSegmentTypesData, audienceSegmentTypesError]);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const [keyword, setKeyword] = useState(undefined);

  const onChange = (event) => {
    return setKeyword(event.target.value);
  };

  const loop = (fields, types) => {
    return fields
      .map((item) => {
        if (!item) return {};

        const { type: typeConfig } = types.find(($) => $.id === item.id);

        return {
          title: null,
          type: typeConfig,
          key: item.id,
          children: item.fields ? loopFields(item.id, item.fields) : undefined,
        };
      })
      .sort(
        (a, b) =>
          (a.key && b.key && a.key.toLowerCase() > b.key.toLowerCase() && 1) ||
          -1
      );
  };

  const loopFields = (parentId, fields) => {
    return fields
      .filter((field) => field.type)
      .filter((field) => !field.nested)
      .filter(
        (field) =>
          !keyword ||
          field.name.includes(keyword) ||
          field.type.includes(keyword)
      )
      .map((field) => {
        const key = parentId + "+" + field.name;

        const isStruct = field.type.includes("array(row(");
        if (isStruct) {
          const virtualStructFields = nestedRowStructureToVirtualFields(field);
          return {
            title: null,
            field: field,
            key: key,
            children: loopFields(key, virtualStructFields),
          };
        } else {
          return { title: null, field: field, key: key };
        }
      });
  };

  return (
    <>
      <Input
        size="small"
        placeholder="Filter..."
        allowClear
        style={{ width: "80%", margin: "10px 10px" }}
        suffix={
          <Tooltip title="Enter a word to filter out data elements">
            <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        }
        onChange={onChange}
      />
      <Tree
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        treeData={
          eventTypeFieldData &&
          eventTypeListData &&
          audienceSegmentFieldsData &&
          audienceSegmentTypesData &&
          loop(
            [...eventTypeFieldData, ...audienceSegmentFieldsData],
            [...eventTypeListData, ...audienceSegmentTypesData]
          )
        }
        height={480}
        titleRender={(node) => {
          if (node.type) {
            const onDoubleClick = () => {
              onTreeDoubleClick(
                node.type.catalog +
                  "." +
                  node.type.database +
                  "." +
                  node.type.tableName,
                "table"
              );
            };

            const title = (
              <div>
                catalog: {node.type.catalog} <br /> db: {node.type.database}
              </div>
            );

            return (
              <div onDoubleClick={onDoubleClick}>
                <Tooltip placement="right" title={title}>
                  {node.type.tableName}
                </Tooltip>
              </div>
            );
          } else if (node.field) {
            const onDoubleClick = () => {
              onTreeDoubleClick(node.field.name, "field");
            };

            const title = "type: " + node.field.type;

            return (
              <div onDoubleClick={onDoubleClick}>
                <Tooltip placement="right" title={title}>
                  {node.field.name}
                </Tooltip>
              </div>
            );
          } else {
            return <div>{node.title}</div>;
          }
        }}
      />
    </>
  );
};
