import React from "react";
import { Divider, Empty, Spin } from "antd";
import AdModel from "../ads/AdModel";
import AdDataSummary from "./AdDataSummary";
import AdDataDetails from "../ads/AdDataDetails";
import { useSelector } from "react-redux";
import { selectJobs } from "../../../reducers/txTrackingReducer";
import CenteredEmpty from "../../common/CenteredEmpty";
import SpinContainer from "../../common/SpinContainer";

const AdDataTabPane = (props) => {
  const jobs = useSelector(selectJobs);

  const hasResult = (jobs) => jobs.some((j) => j.result && j.result.length > 0);

  return (
    <>
      {props.isLoading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        !hasResult(jobs) && (
          <CenteredEmpty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No Ads Data</span>}
          />
        )
      )}
      {hasResult(jobs) && <ImpTree />}
    </>
  );
};

// build imp tree + render
const ImpTree = () => {
  const jobs = useSelector(selectJobs);

  const contractImpJobData = jobs.find(
    ($) => $.eventType === "CONTRACT_IMPRESSION"
  );
  const contractImpEvents = contractImpJobData && contractImpJobData.result;

  const impJobData = jobs.find(($) => $.eventType === "AD_IMPRESSION");
  const impEvents = impJobData && impJobData.result;

  const adModel = AdModel.populateModel(contractImpEvents, impEvents);

  return (
    <>
      {adModel && adModel.length > 0 ? (
        <>
          <h2>Ads Context</h2>
          <AdDataSummary adModel0={adModel[0]} />
          <Divider />
          <AdDataDetails adModel={adModel} />
        </>
      ) : (
        <CenteredEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default AdDataTabPane;
