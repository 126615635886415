import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();

  const goBackHome = () => {
    history.push("/");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you are looking for does not exist."
        extra={
          <Button type="primary" onClick={goBackHome}>
            Back Home
          </Button>
        }
        style={{ transform: "scale(0.8)", width: "auto", padding: "20px" }}
      />
    </div>
  );
};

export default NotFound;
