import React from "react";
import { Alert, Card, Empty, Spin, Tag, Timeline, Tooltip } from "antd";
import { ClockCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

import "./TimelineTabPane.css";
import DateHelper from "../../../utils/DateHelper";
import { useSelector } from "react-redux";
import { selectJobs } from "../../../reducers/txTrackingReducer";
import CenteredEmpty from "../../common/CenteredEmpty";
import SpinContainer from "../../common/SpinContainer";

const durationThresholds = [1, 10 * 60, Number.POSITIVE_INFINITY];
const colorList = ["#808080", "#24aa05", "#ee752f"];
const paddingList = [15, 30, 60];

const hasResult = (jobs) => jobs.some((j) => j.result && j.result.length > 0);

const CustomTimelineItem = styled(Timeline.Item)`
  padding-bottom: ${({ spacing }) => spacing}px;
`;

const TimelineTabPane = (props) => {
  const jobs = useSelector(selectJobs);

  const normalizedJobs = [...jobs] // copy the array, to avoid mutating the original
    .filter((j) => j.result && j.result.length > 0)
    .sort((j1, j2) => {
      return (
        Number.parseInt(j1.result[0].timestamp) -
        Number.parseInt(j2.result[0].timestamp)
      );
    });

  const getJobColor = (millisecondsDelta) => {
    const secondsDelta = millisecondsDelta / 1000;
    return colorList.find((color, index) => {
      const colorMaxDelta = durationThresholds[index];
      return secondsDelta < colorMaxDelta;
    });
  };

  const getJobSpacing = (millisecondsDelta) => {
    const secondsDelta = millisecondsDelta / 1000;
    return paddingList.find((padding, index) => {
      const paddingMaxDelta = durationThresholds[index];
      return secondsDelta < paddingMaxDelta;
    });
  };

  const timelineItems = [];

  if (hasResult(normalizedJobs)) {
    normalizedJobs.forEach((currentJob, currentJobIndex) => {
      const nextJob = normalizedJobs[currentJobIndex + 1];
      const timestampDelta = nextJob
        ? nextJob.result[0].timestamp - currentJob.result[0].timestamp
        : 0;
      const hasArtificial = timestampDelta >= 1000;

      let itemSpacing = getJobSpacing(timestampDelta);
      itemSpacing = hasArtificial ? itemSpacing / 2 : itemSpacing;

      timelineItems.push(
        <CustomTimelineItem
          key={`${currentJob.data.uid}`}
          label={currentJob.result[0]["_datetime"]}
          spacing={itemSpacing}
        >
          {currentJob.title}
        </CustomTimelineItem>
      );

      if (hasArtificial) {
        timelineItems.push(
          <CustomTimelineItem
            key={`${currentJob.data.uid}-${nextJob.data.uid}`}
            spacing={itemSpacing}
            dot={
              <Tooltip title={DateHelper.readableDuration(timestampDelta)}>
                <ClockCircleOutlined
                  style={{
                    color: getJobColor(timestampDelta),
                  }}
                />
              </Tooltip>
            }
          >
            <div style={{ color: "gray" }}>
              {DateHelper.readableDuration(timestampDelta)}
            </div>
          </CustomTimelineItem>
        );
      }
    });
  }

  return (
    <>
      {props.isLoading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : !hasResult(normalizedJobs) ? (
        <CenteredEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <Alert
            message="Only the first occurrence of an event type is displayed"
            type="info"
            className={"timeline-info-text"}
            showIcon
          />
          <div className="timeline-container">
            <div className="timeline-inner">
              <Timeline mode="left">{timelineItems}</Timeline>
            </div>
          </div>
          <Card size="small" title="Legend" className={"timeline-legend"}>
            <div>
              <ClockCircleOutlined
                style={{
                  color: "#24aa05",
                }}
              />
              <span>
                Time difference between two subsequent events is between 1
                second and 10 minutes
              </span>
            </div>
            <div>
              <ClockCircleOutlined
                style={{
                  color: "#ee752f",
                }}
              />
              <span>
                Time difference between two subsequent events is more than 10
                minutes
              </span>
            </div>
          </Card>
          <div className={"not-found"}>
            {jobs.some((j) => j.result && j.result.length === 0) &&
              "No events found for: "}
            {jobs
              .filter((j) => j.result && j.result.length === 0)
              .map((j) =>
                j.eventType === "BIDS" ? (
                  <Tooltip
                    key={j.eventType}
                    title={
                      "The absence of bids could be due to the downsampling of the BIDS event"
                    }
                  >
                    <Tag>
                      {j.eventType}
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Tag>
                  </Tooltip>
                ) : (
                  <Tag key={j.eventType}>{j.eventType}</Tag>
                )
              )}
          </div>
        </>
      )}
    </>
  );
};

export default TimelineTabPane;
