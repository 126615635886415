import ReactJson from "react-json-view";
import React from "react";

const QueryDetails = ({ item, taskIdToName }) => {
  return (
    <div style={{ maxWidth: "600px", maxHeight: "400px", overflowY: "scroll" }}>
      {item.dataExportDto && (
        <>
          <div title={taskIdToName && taskIdToName[item.dataExportDto.taskId]}>
            <strong>Task ID: </strong>
            {item.dataExportDto.taskId}
          </div>
          <div>
            <strong>Dataset Type: </strong>
            {item.dataExportDto.datasetType || "-"}
          </div>
          {item.dataExportDto.datasetType === "RAW_EVENTS" && (
            <div>
              <strong>Event Type: </strong>
              {item.dataExportDto.eventType}
            </div>
          )}
          {item.dataExportDto.datasetType === "RAW_EVENTS" && (
            <div>
              <strong>Client: </strong>
              {item.dataExportDto.client}
            </div>
          )}
          <div>
            <strong>Date Range: </strong>
            {item.dataExportDto.dateRange &&
              item.dataExportDto.dateRange.length === 2 &&
              "[" +
                item.dataExportDto.dateRange[0].slice(0, 10) +
                ", " +
                item.dataExportDto.dateRange[1].slice(0, 10) +
                "]"}
          </div>
          <div>
            <strong>Query Mode: </strong>
            {item.dataExportDto.queryMode}
          </div>
          <div>
            <strong>Max. Results: </strong>
            {item.dataExportDto.limit}
          </div>
          {item.dataExportDto.fields && (
            <div>
              <strong>Fields: </strong>
              <ReactJson
                name={false}
                displayObjectSize={true}
                displayDataTypes={false}
                enableClipboard={false}
                src={item.dataExportDto.fields}
              />
            </div>
          )}
          {item.dataExportDto.filters && item.dataExportDto.filters.length > 0 && (
            <div>
              <strong>Filters:</strong>
              <ReactJson
                name={false}
                displayObjectSize={true}
                displayDataTypes={false}
                enableClipboard={false}
                src={item.dataExportDto.filters}
              />
            </div>
          )}
        </>
      )}
      {item.plaintextQueryDataExportDto && (
        <>
          <div
            title={
              taskIdToName &&
              taskIdToName[item.plaintextQueryDataExportDto.taskId]
            }
          >
            <strong>Task ID: </strong>
            {item.plaintextQueryDataExportDto.taskId}
          </div>
          {item.plaintextQueryDataExportDto.plaintextQuery && (
            <div>
              <strong>Query: </strong>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {item.plaintextQueryDataExportDto.plaintextQuery}
              </div>
            </div>
          )}
        </>
      )}
      {item.rawDataExportDto && (
        <>
          <div>
            <strong>Task ID: </strong>
            {item.rawDataExportDto.taskId}
          </div>
          <div>
            <strong>Date: </strong>
            {item.rawDataExportDto.date}
          </div>
          <div>
            <strong>Hour: </strong>
            {item.rawDataExportDto.hour}
          </div>
        </>
      )}
    </div>
  );
};

export default QueryDetails;
