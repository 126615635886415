import TreeItem from "./TreeItem";
import React from "react";

const ZoneRequestTreeItem = ({ event, job }) => {
  let allEvents = [];
  job.result.forEach((zoneRequest) => {
    allEvents = [...allEvents, ...(zoneRequest.detailsList ?? [])];
  });
  const isInZoneRequest = !!allEvents.find((_event) => {
    return _event.client === event.client && _event.zoneid === event.zoneid;
  });

  return (
    <TreeItem
      count={event.nodes ? event.nodes.length : 0}
      clientName={event.client}
      isClientNameDisabled={!isInZoneRequest}
      zoneId={event.zoneid}
    />
  );
};

export default ZoneRequestTreeItem;
