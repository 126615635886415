import { Empty } from "antd";
import styled from "styled-components";

const CenteredEmpty = styled(Empty)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default CenteredEmpty;
