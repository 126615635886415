import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import MonacoEditorWrapper from "./MonacoEditorWrapper";
import sqlFormatter from "sql-formatter";
import { Col, Form, Row } from "antd";
import CommonButton from "../../common/CommonButton";
import { FormatPainterOutlined, DeleteOutlined } from "@ant-design/icons";
import MySqlSuggestionsHelper from "../../../utils/MySqlSuggestionsHelper";
import MacroSuggestionsHelper from "../../../utils/MacroSuggestionsHelper";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const fetchEventTypeList = ({
  setEventTypeListData,
  setEventTypeListError,
}) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.eventTypeListEndpoint}`
    )
    .then((result) => result.data)
    .then(setEventTypeListData)
    .catch(setEventTypeListError);
};

const fetchEventTypeFieldList = ({
  setEventTypeFieldListData,
  setEventTypeFieldListError,
}) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.eventTypeFieldListEndpoint}`
    )
    .then((result) => result.data)
    .then(setEventTypeFieldListData)
    .catch(setEventTypeFieldListError);
};

export const InputTextAreaItem = ({
  form,
  onFormChange,
  onReset,
  jobStarted,
  jobOver,
  onEditorInit,
}) => {
  const eventTypeFieldListDataRef = React.useRef(null);

  const [eventTypeFieldListData, setEventTypeFieldListData] =
    useState(undefined);
  const [eventTypeFieldListError, setEventTypeFieldListError] =
    useState(undefined);
  useEffect(() => {
    eventTypeFieldListDataRef.current = eventTypeFieldListData;
    !(eventTypeFieldListData || eventTypeFieldListError) &&
      fetchEventTypeFieldList({
        setEventTypeFieldListData,
        setEventTypeFieldListError,
      });
  }, [eventTypeFieldListData, eventTypeFieldListError]);

  const eventTypeListDataRef = React.useRef(null);

  const [eventTypeListData, setEventTypeListData] = useState(undefined);
  const [eventTypeListError, setEventTypeListError] = useState(undefined);
  useEffect(() => {
    eventTypeListDataRef.current = eventTypeListData;
    !(eventTypeListData || eventTypeListError) &&
      fetchEventTypeList({ setEventTypeListData, setEventTypeListError });
  }, [eventTypeListData, eventTypeListError]);

  const [plaintextQuery, setPlaintextQuery] = useState(undefined);

  const handleEditorChange = (newValue, e) => {
    setPlaintextQuery(newValue);
  };

  const createSuggestions = (monaco) => {
    const eventTypeSuggestionArray = [];

    // table names suggestions
    const _eventTypeListData = eventTypeListDataRef.current; // using the ref hook!
    if (_eventTypeListData) {
      _eventTypeListData.forEach((item) => {
        const suggestion = {
          label: item.type.tableName,
          kind: monaco.languages.CompletionItemKind.Property,
          insertText: item.type.tableName,
        };
        eventTypeSuggestionArray.push(suggestion);
      });
    }

    // table field names suggestions
    const _eventTypeFieldListData = eventTypeFieldListDataRef.current; // using the ref hook!
    if (_eventTypeFieldListData) {
      const fieldNameSet = new Set(); // 2-pass algo to filter out duplicates

      // put all fields in the set
      _eventTypeFieldListData.forEach(
        (item) =>
          item.fields &&
          item.fields.forEach((field) => fieldNameSet.add(field.name))
      );

      fieldNameSet.forEach((fieldName) => {
        const suggestion = {
          label: fieldName,
          kind: monaco.languages.CompletionItemKind.Property,
          insertText: fieldName,
        };
        eventTypeSuggestionArray.push(suggestion);
      });
    }

    return [
      // {
      //     label: 'test',
      //     kind: monaco.languages.CompletionItemKind.Property,
      //     insertText: 'test',
      // },
      ...eventTypeSuggestionArray,
      ...MySqlSuggestionsHelper.build(monaco),
      ...MacroSuggestionsHelper.build(monaco),
    ];
  };

  // avoid duplicates in the suggestions, https://github.com/react-monaco-editor/react-monaco-editor/issues/88
  const [completionDisposable, setCompletionDisposable] = useState({});

  const editorDidMount = (editor, monaco) => {
    // create suggestions with the disposable trick to avoid duplicates
    const disposable = monaco.languages.registerCompletionItemProvider(
      "mysql",
      {
        provideCompletionItems: function (model, position) {
          return {
            suggestions: createSuggestions(monaco),
          };
        },
      }
    );
    setCompletionDisposable(disposable);

    onEditorInit(editor);

    // hack to force an 'unselect all' that is triggered by default when mounting the editor component?!
    //editor.focus();
    editor.onDidChangeModelContent((e) => {
      //console.log("cursor position", editor.getPosition());
      editor.setPosition(editor.getPosition());
      editor.focus();
    });
  };

  // dispose registerCompletionItemProvider
  useEffect(() => completionDisposable.dispose, [completionDisposable]);

  useEffect(() => {
    if (!plaintextQuery) return;

    const textAreaFieldsObject = {
      plaintextQuery: plaintextQuery,
    };
    form.setFieldsValue({ ...textAreaFieldsObject });
    onFormChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plaintextQuery]);

  const onFormat = () => {
    const ptq = form.getFieldValue("plaintextQuery");

    if (ptq) {
      const formattedQuery = sqlFormatter.format(ptq);
      setPlaintextQuery(formattedQuery);
    }
  };

  const onClear = () => {
    onReset();
  };

  return (
    <>
      <Row>
        <Col span={22}></Col>
        <Col span={2}>
          <CommonButton
            title="Format Query"
            type="link"
            onClick={onFormat}
            style={{ padding: "2px 5px" }}
            disabled={jobStarted && !jobOver}
          >
            <FormatPainterOutlined />
          </CommonButton>
          <CommonButton
            title="Clear"
            type="link"
            onClick={onClear}
            style={{ padding: "2px 10px" }}
            disabled={jobStarted && !jobOver}
          >
            <DeleteOutlined />
          </CommonButton>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="plaintextQuery"
            rules={[
              {
                required: false,
              },
            ]}
            style={{
              border: "1px solid #ddd",
              borderRadius: "3px",
              resize: "none",
              overflow: "hidden",
            }}
          >
            <MonacoEditorWrapper
              value={plaintextQuery}
              options={{ readOnly: jobStarted && !jobOver }}
              editorDidMount={editorDidMount}
              onChange={handleEditorChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
