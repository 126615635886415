import moment from "moment";

const MacroSuggestionsHelper = {
  build: (monaco) => {
    const now = new Date();
    const fullYear = now.getUTCFullYear();
    const paddedMonth = String(now.getUTCMonth() + 1).padStart(2, "0");
    const paddedDay = String(now.getUTCDate()).padStart(2, "0");

    const yesterday = moment().subtract(1, "days").toDate();
    const yFullYear = yesterday.getUTCFullYear();
    const yPaddedMonth = String(yesterday.getUTCMonth() + 1).padStart(2, "0");
    const yPaddedDay = String(yesterday.getUTCDate()).padStart(2, "0");

    return [
      {
        label: "m?utcToday",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "AND year='" +
          fullYear +
          "' AND month='" +
          paddedMonth +
          "' AND day='" +
          paddedDay +
          "' ",
      },
      {
        label: "m?selectStar",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText: "SELECT *\nFROM ",
      },
      {
        label: "m?countPerDayOppV3Example",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobtser test - countPerDayOppV3Example \n" +
          "SELECT year, month, day, hour(date_trunc('hour', from_unixtime(\"timestamp\" / 1000))) AS hr, COUNT(requestid) AS count\n" +
          "FROM v3_opportunity_fymd\n" +
          "CROSS JOIN UNNEST(trail) AS nested_data(T)\n" +
          "WHERE trailclients like '%:pandoratest:%'\n" +
          "AND T.client='pandoratest'\n" +
          "AND year='" +
          fullYear +
          "'\n" +
          "AND month='" +
          paddedMonth +
          "'\n" +
          "AND day='" +
          paddedDay +
          "'\n" +
          "GROUP BY 1,2,3,4\n" +
          "ORDER BY 4\n",
      },
      {
        label: "m?countPerDayBronzeOppV3Example",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobtser test - countPerDayBronzeOppV3Example \n" +
          "SELECT year, month, day, hour, COUNT(requestid) AS count\n" +
          "FROM bronze.v3_opportunity\n" +
          "CROSS JOIN UNNEST(trail) AS nested_data(T)\n" +
          "WHERE T.client='pandoratest'\n" +
          "AND year='" +
          fullYear +
          "'\n" +
          "AND month='" +
          paddedMonth +
          "'\n" +
          "AND day='" +
          paddedDay +
          "'\n" +
          "GROUP BY 1,2,3,4\n" +
          "ORDER BY 4\n",
      },
      {
        label: "m?crossjoingImpOppV3Example",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobtser test - crossjoingImpOppV3Example \n" +
          "SELECT imp.listenerid, opp.userlistenerid\n" +
          "FROM v3_contract_impression_cymd imp\n" +
          "JOIN v3_opportunity_fymd opp on opp.transactionid = imp.transactionid \n" +
          "WHERE imp.client = 'pandora'\n" +
          "AND imp.year='" +
          fullYear +
          "' AND imp.month='" +
          paddedMonth +
          "' AND imp.day='" +
          paddedDay +
          "'\n" +
          "AND opp.year='" +
          fullYear +
          "' AND opp.month='" +
          paddedMonth +
          "' AND opp.day='" +
          paddedDay +
          "'\n" +
          "AND imp.traceid = '2cd29944-6aeb-11eb-a751-02a8b9598269'\n" +
          "AND opp.trailclients like '%:pandora:%'\n",
      },
      {
        label: "m?countPerDayImpV3Example",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster test - countPerDayImpV3Example \n" +
          "SELECT year, month, day, hour(date_trunc('hour', FROM_UNIXTIME(\"timestamp\" / 1000))) AS hr, COUNT(requestid) AS count\n" +
          "FROM v3_ad_impression_cymd \n" +
          "WHERE client='pandoratest'\n" +
          "AND year='" +
          fullYear +
          "'\n" +
          "AND month='" +
          paddedMonth +
          "'\n" +
          "AND day='" +
          paddedDay +
          "'\n" +
          "GROUP BY 1,2,3,4\n" +
          "ORDER BY 4\n",
      },
      {
        label: "m?conversionUsageExample",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster test - conversionUsageExample \n" +
          "WITH tmp AS (\n" +
          "    SELECT client, orderid, campaignid, atmonth, atday, atHour\n" +
          "    FROM alchemy.v3_conversion \n" +
          "    WHERE atyear='" +
          fullYear +
          "'\n" +
          "    AND atmonth='" +
          paddedMonth +
          "'\n" +
          "    AND atday='" +
          paddedDay +
          "'\n" +
          "    AND (atHour='06' OR atHour='07' OR atHour='08' OR atHour='09') \n" +
          "    GROUP BY client, orderid, campaignid, atmonth, atday, atHour \n" +
          ")\n" +
          "SELECT *, count(*) AS count \n" +
          "FROM tmp \n" +
          "GROUP BY client, orderid, campaignid, atmonth, atday, atHour \n" +
          "ORDER BY count DESC\n",
      },
      {
        label: "m?activeCampaignsForAllClientsQueryFederationUsageExample",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster test - FQ example\n" +
          "SELECT client, c.campaignid, c.campaignname \n" +
          'FROM "lambda:fedq-domain".v_all.campaigns c \n' +
          "WHERE c.activate > now() \n" +
          "GROUP BY client, c.campaignid, c.campaignname \n" +
          "ORDER BY client, c.campaignid DESC\n",
      },
      {
        label: "m?activeCampaignsForSingleClientQueryFederationUsageExample",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster test - FQ example\n" +
          "SELECT c.campaignid, c.campaignname \n" +
          'FROM "lambda:fedq-domain".pandora.campaigns c \n' +
          "WHERE c.activate > now() \n" +
          "GROUP BY c.campaignid, c.campaignname \n" +
          "ORDER BY c.campaignid DESC\n",
      },
      {
        label: "m?podscribedInventoryOppExample",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "SELECT \n" +
          "  T.client AS client,\n" +
          "  CAST(sum(if(requestvariables['aw_0_pod.podscribed'] is not null, T.inventory30sec,0)) AS BIGINT) AS \"podscribed inventory\",\n" +
          '  CAST(sum(T.inventory30sec) AS BIGINT) AS "total inventory"\n' +
          "FROM meru.v3_opportunity_fymd \n" +
          "CROSS JOIN UNNEST(trail) AS nested_data(T) \n" +
          "WHERE true\n" +
          "AND trailclients like '%:julepde:%'\n" +
          "AND T.client='julepde'\n" +
          "AND year='" +
          fullYear +
          "'\n" +
          "AND month='" +
          paddedMonth +
          "'\n" +
          "AND day='" +
          paddedDay +
          "'\n" +
          "GROUP BY 1\n" +
          "ORDER BY 1 DESC\n",
      },
      {
        label: "m?podscribedInventoryImpExample",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "SELECT \n" +
          "  client,\n" +
          "  sum(if(requestvariables['aw_0_pod.podscribed'] is not null, 1, 0)) AS \"podscribed imp\",\n" +
          '  sum(1) AS "total imp"\n' +
          "FROM meru.v3_contract_impression_cymd \n" +
          "WHERE true\n" +
          "AND client = 'julepde'\n" +
          "AND year='" +
          fullYear +
          "'\n" +
          "AND month='" +
          paddedMonth +
          "'\n" +
          "AND day='" +
          paddedDay +
          "'\n" +
          "GROUP BY 1\n" +
          "ORDER BY 1 DESC\n",
      },
      {
        label: "m?redirectAdsPointingToRubicon/Magnite",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster FQ example - Redirect ads pointing to Rubicon/Magnite\n" +
          "SELECT \n" +
          "  c.client,\n" +
          "  c.campaignid,\n" +
          "  c.activate,\n" +
          "  c.expire,\n" +
          "  c.status AS campaign_status,\n" +
          "  b.bannerid,\n" +
          "  b.description,\n" +
          "  b.status AS ad_status,\n" +
          "  b.redirect_url\n" +
          'FROM "lambda:fedq-domain".v_all.banners b\n' +
          'JOIN "lambda:fedq-domain".v_all.campaigns c ON (c.campaignid = b.campaignid AND c.client = b.client)\n' +
          "WHERE b.storagetype = 'redirect'\n" +
          "AND b.redirect_url LIKE '%rubiconproject%'\n" +
          "ORDER BY c.client, c.activate ASC\n",
      },
      {
        label: "m?campaignDataPointsForRecentImpEvents",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster FQ example - Campaign Data Points For Recent Impression Events (WIP)\n" +
          "WITH t AS (\n" +
          "SELECT \n" +
          "ev.campaignid AS campaignid, count(*) as tot\n" +
          "FROM v3_ad_impression_cymd ev\n" +
          "WHERE client='pandora'\n" +
          "AND ev.year = CAST(year(date_trunc('year', current_date - interval '1' month)) AS VARCHAR)\n" +
          "AND ev.month in (\n" +
          "    lpad(CAST(month(date_trunc('month', current_date - interval '1' month)) AS VARCHAR), 2, '0'),\n" +
          "    lpad(CAST(month(date_trunc('month', current_date)) AS VARCHAR), 2, '0')\n" +
          "    )\n" +
          "-- AND ev.day = lpad(CAST(day(date_trunc('day', current_date - interval '1' month)) AS VARCHAR), 2, '0')\n" +
          "-- AND ev.objectivecountable = true\n" +
          "GROUP BY ev.campaignid\n" +
          ")\n" +
          "SELECT t.campaignid, \n" +
          "    t.tot,\n" +
          "    c.views AS total_impressions,\n" +
          "    c.last_seen_total_impressions,\n" +
          "    c.clicks AS total_clicks,\n" +
          "    c.conversions AS total_conversions,\n" +
          "    c.last_seen_total_clicks,\n" +
          "    c.target_impression,\n" +
          "    c.objective_type,\n" +
          "    c.total_objective,\n" +
          "    c.revenue,\n" +
          "    c.campaignname\n" +
          "FROM t\n" +
          'INNER JOIN "lambda:fedq-domain".pandora.campaigns c ON (c.campaignid = t.campaignid)\n' +
          "ORDER BY t.tot DESC",
      },
      {
        label: "m?activeDeals",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "SELECT \n" +
          "    cl.client,\n" +
          "    cl.contract_id,\n" +
          "    cl.id,\n" +
          "    cl.name,\n" +
          "    cl.is_active,\n" +
          "    cl.deal_id,\n" +
          "    count(*) AS total\n" +
          'FROM "lambda:fedq-domain".v_all.contract_line cl\n' +
          'JOIN "lambda:fedq-domain".v_all.connectors con ON (cl.connector_id = con.id AND cl.client = con.client)\n' +
          "JOIN v3_contract_impression_cymd cimps ON (cimps.contractlineid = cl.id AND cimps.client = cl.client)\n" +
          "WHERE cl.is_active = true\n" +
          "AND cl.deal_id IS NOT NULL AND cl.deal_id != 'RESERVED'\n" +
          "AND con.connector_type = 'ADSWIZZ'\n" +
          "-- AND cl.client = 'acast'\n" +
          "AND year='" +
          fullYear +
          "'\n" +
          "AND month='" +
          paddedMonth +
          "'\n" +
          "AND day='" +
          paddedDay +
          "'\n" +
          "GROUP BY cl.client, cl.contract_id, cl.id, cl.name, cl.is_active, cl.deal_id\n" +
          "ORDER BY cl.client, cl.contract_id",
      },
      {
        label: "m?activeDealsAndRevenues",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "WITH deals AS (\n" +
          "    SELECT cl.id AS deal_id, cl.name AS deal_name, cl.contract_id AS saleschannel_id, sc.name AS sales_channel_name\n" +
          '    FROM "lambda:fedq-domain".exchange.contract_line cl\n' +
          '    JOIN "lambda:fedq-domain".exchange.sales_channel sc ON cl.contract_id = sc.id\n' +
          "    WHERE cl.name LIKE '%EPGI%'\n" +
          "),\n" +
          "impression_data AS (\n" +
          "    SELECT YEAR||'-'||MONTH||'-'||DAY AS \"date\", transactionid, adid, client, country, contractlineid AS dealid, saleschannelid, currency, sum(cast(netclearingprice AS DOUBLE)) AS revenue, cast(count(*) AS DOUBLE) AS impressions\n" +
          "    FROM meru.v3_contract_impression_cymd\n" +
          "    WHERE client IN ('exchange')\n" +
          "    AND parse_datetime(YEAR||MONTH||DAY, 'yyyyMMdd') >= CURRENT_TIMESTAMP - interval '60' DAY\n" +
          "    GROUP BY 1,2,3,4,5,6,7,8\n" +
          "),\n" +
          "report_data as (\n" +
          "    SELECT YEAR||'-'||MONTH||'-'||DAY AS \"date\", transactionid, adid, client, cast(sum(if(playedpercentage=100, 1, 0)) as double) AS perc_100\n" +
          "    FROM meru.v3_contract_report_cymd \n" +
          "    WHERE client IN ('exchange')\n" +
          "    AND parse_datetime(YEAR||MONTH||DAY, 'yyyyMMdd') >= CURRENT_TIMESTAMP - interval '60' DAY\n" +
          "    GROUP BY 1,2,3,4\n" +
          "),\n" +
          "ltr_data AS (\n" +
          '    SELECT "date", dealid, saleschannelid, country, currency,\n' +
          "        sum(impressions) AS impressions, \n" +
          "        sum(revenue) AS revenue,\n" +
          "        sum(perc_100) AS perc_100,\n" +
          "        sum(perc_100) / sum(impressions) AS ltr\n" +
          "    FROM impression_data \n" +
          '    JOIN report_data USING ("date", transactionid, adid, client)\n' +
          "    GROUP BY 1,2,3,4,5\n" +
          ")\n" +
          'SELECT coalesce("date", cast(CURRENT_DATE AS VARCHAR)) AS "date", \n' +
          "    ltr_data.country AS country,\n" +
          "    currency,\n" +
          "    '(' || cast(saleschannel_id AS VARCHAR) || ') ' || sales_channel_name AS saleschannel_name,\n" +
          "    '(' || cast(deal_id AS VARCHAR) || ') ' || deal_name AS deal_name,\n" +
          "    coalesce(revenue, 0) AS revenue,\n" +
          "    coalesce(impressions, 0) AS impressions,\n" +
          "    coalesce(perc_100, 0) AS perc_100,\n" +
          "    coalesce(ltr, 0) AS ltr\n" +
          "FROM ltr_data\n" +
          "RIGHT JOIN deals ON (ltr_data.dealid = deals.deal_id AND ltr_data.saleschannelid = deals.saleschannel_id)\n",
      },
      {
        label: "m?audienceExample",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster test - Audience Data example\n" +
          "SELECT year, month, day, provider, listenerid, segmentkey, segmentvalues\n" +
          'FROM "adserverprod"."dmp_etl_pipeline"."v1_audience"\n' +
          "WHERE year='" +
          fullYear +
          "'\n" +
          "AND month='" +
          paddedMonth +
          "'\n" +
          "AND day='" +
          paddedDay +
          "'\n" +
          "LIMIT 10\n",
      },
      {
        label: "m?audienceDistributionBySegmentValueExample",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster test - Audience distribution by segment value example\n" +
          "SELECT provider, R.segmentvalue AS segmentvalue, count(*) AS count\n" +
          "FROM adserverprod.dmp_etl_pipeline.v1_audience\n" +
          "CROSS JOIN UNNEST(segmentvalues) AS R(segmentvalue)\n" +
          "WHERE year='" +
          yFullYear +
          "' AND month='" +
          yPaddedMonth +
          "' AND day='" +
          yPaddedDay +
          "'\n" +
          "AND provider = 'theadex'\n" +
          "GROUP BY provider, R.segmentvalue\n" +
          "ORDER BY count DESC\n",
      },
      {
        label: "m?podscribeExample",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster test - Podscribe DB example\n" +
          "SELECT o.instance_name AS instance, \n" +
          "p.id AS publisherId, p.name AS publisherName, \n" +
          "s.name AS showName, s.cover_url AS coverUrl, s.rss_url AS rssUrl, s.description, s.repo_id\n" +
          "FROM\n" +
          '  "lambda:fedq-podscribe".podscribe_api.shows s,\n' +
          '  "lambda:fedq-podscribe".podscribe_api.organizations o,\n' +
          '  "lambda:fedq-podscribe".podscribe_api.publishers p\n' +
          "WHERE o.id = s.organization_id AND s.publisher_id = p.id\n" +
          "AND o.instance_name IN ('sme')\n" +
          "AND s.name IS NOT NULL\n",
      },
      {
        label: "m?podscribeJoinExample",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobster test - joining Podscribe DB with Imp & Opp Raw Events\n" +
          "\n" +
          "WITH podscribe_data AS (\n" +
          "\n" +
          "SELECT \n" +
          '  o.instance_name AS "instance_name", \n' +
          '  sh.name AS "show_name", \n' +
          '  sh.repo_id AS "repo_id", \n' +
          '  sh.description AS "description", \n' +
          '  sh.cover_url AS "logo", \n' +
          '  sh.rss_url AS "rss", \n' +
          '  rss_s_lang.name AS "rss_s_lang", \n' +
          '  rss_s_iExplici.name AS "rss_s_iExplici", \n' +
          '  rss_s_iType.name AS "rss_s_iType"\n' +
          'FROM "lambda:fedq-podscribe".podscribe_api.shows sh\n' +
          'INNER JOIN "lambda:fedq-podscribe".podscribe_api.organizations o ON o.id = sh.organization_id\n' +
          "LEFT JOIN (\n" +
          "  SELECT sh2.id, s2.name\n" +
          '  FROM "lambda:fedq-podscribe".podscribe_api.shows sh2\n' +
          '  INNER JOIN "lambda:fedq-podscribe".podscribe_api.show_segments ss2 ON ss2.show_id = sh2.id\n' +
          "  INNER JOIN \"lambda:fedq-podscribe\".podscribe_api.segments s2 ON s2.id = ss2.segment_id AND s2.type = 'rss_s_lang'\n" +
          "  ) as rss_s_lang ON rss_s_lang.id = sh.id\n" +
          "LEFT JOIN (\n" +
          "  SELECT sh2.id, s2.name\n" +
          '  FROM "lambda:fedq-podscribe".podscribe_api.shows sh2\n' +
          '  INNER JOIN "lambda:fedq-podscribe".podscribe_api.show_segments ss2 ON ss2.show_id = sh2.id\n' +
          "  INNER JOIN \"lambda:fedq-podscribe\".podscribe_api.segments s2 ON s2.id = ss2.segment_id AND s2.type = 'rss_s_iExplici'\n" +
          "  ) as rss_s_iExplici ON rss_s_iExplici.id = sh.id\n" +
          "LEFT JOIN (\n" +
          "  SELECT sh2.id, s2.name\n" +
          '  FROM "lambda:fedq-podscribe".podscribe_api.shows sh2\n' +
          '  INNER JOIN "lambda:fedq-podscribe".podscribe_api.show_segments ss2 ON ss2.show_id = sh2.id\n' +
          "  INNER JOIN \"lambda:fedq-podscribe\".podscribe_api.segments s2 ON s2.id = ss2.segment_id AND s2.type = 'rss_s_iType'\n" +
          "  ) AS rss_s_iType ON rss_s_iType.id = sh.id\n" +
          "WHERE o.instance_name IN ('sme','audioboom','podcastone','marketenginuity','kastmedia','starburnaudio','bentkey','bleav','podglomerate','studio71','barstool','lockedon','redcircle','washingtonpost','latimes','wnyc','univision')\n" +
          "AND sh.name IS NOT NULL\n" +
          "),\n" +
          "opp_data AS (\n" +
          "SELECT \n" +
          "  if(opp.reqvaraw0cntinternalseriesid LIKE '[%]', substring(opp.reqvaraw0cntinternalseriesid, 2, length(opp.reqvaraw0cntinternalseriesid)-2), opp.reqvaraw0cntinternalseriesid) AS \"internal_series_id\", \n" +
          "  sum(T.inventory30sec) AS inv\n" +
          "FROM v3_opportunity_fymd opp\n" +
          "CROSS JOIN UNNEST(trail) AS nested_data(T)\n" +
          "WHERE trailclients LIKE '%:podwave:%'\n" +
          "AND T.client='podwave'\n" +
          "AND year='" +
          yFullYear +
          "' AND month='" +
          yPaddedMonth +
          "' AND day='" +
          yPaddedDay +
          "'\n" +
          "GROUP BY 1\n" +
          "),\n" +
          "cimp_data AS (\n" +
          "SELECT \n" +
          "  if(cimp.reqvaraw0cntinternalseriesid LIKE '[%]', substring(cimp.reqvaraw0cntinternalseriesid, 2, length(cimp.reqvaraw0cntinternalseriesid)-2), cimp.reqvaraw0cntinternalseriesid) AS \"internal_series_id\",\n" +
          "  count(requestid) AS imp\n" +
          "FROM v3_contract_impression_cymd cimp\n" +
          "WHERE client='podwave'\n" +
          "AND year='" +
          yFullYear +
          "' AND month='" +
          yPaddedMonth +
          "' AND day='" +
          yPaddedDay +
          "'\n" +
          "GROUP BY 1\n" +
          ")\n" +
          "\n" +
          "SELECT \n" +
          "  podscribe_data.instance_name, \n" +
          "  podscribe_data.show_name, \n" +
          "  podscribe_data.description, \n" +
          "  podscribe_data.logo, \n" +
          "  podscribe_data.rss, \n" +
          "  podscribe_data.rss_s_lang, \n" +
          "  podscribe_data.rss_s_iExplici, \n" +
          "  podscribe_data.rss_s_iType,\n" +
          "  cast(round(opp_data.inv) AS int) AS inv,\n" +
          "  cimp_data.imp AS imp\n" +
          "FROM podscribe_data\n" +
          "LEFT JOIN opp_data ON (podscribe_data.repo_id = opp_data.internal_series_id)\n" +
          "LEFT JOIN cimp_data ON (podscribe_data.repo_id = cimp_data.internal_series_id)\n" +
          "WHERE opp_data.inv IS NOT NULL\n" +
          "ORDER BY opp_data.inv DESC\n",
      },
      {
        label: "m?trickToGetDevicetypeMapping",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobtser test - devicetype mapping :-)\n" +
          "SELECT imp.devicetype AS imp_devicetype, opp.devicetype AS opp_devicetype\n" +
          "FROM v3_contract_impression_cymd imp\n" +
          "JOIN v3_opportunity_fymd opp ON opp.transactionid = imp.transactionid \n" +
          "WHERE imp.client = 'pandora'\n" +
          "AND imp.year='" +
          yFullYear +
          "' AND imp.month='" +
          yPaddedMonth +
          "' AND imp.day='" +
          yPaddedDay +
          "'\n" +
          "AND opp.year='" +
          yFullYear +
          "' AND opp.month='" +
          yPaddedMonth +
          "' AND opp.day='" +
          yPaddedDay +
          "'\n" +
          "AND opp.trailclients LIKE '%:pandora:%'\n" +
          "GROUP BY 1,2\n" +
          "ORDER BY 2",
      },
      {
        label: "m?allActiveSalesChannelIds",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobtser test - all active scIds\n" +
          "SELECT \n" +
          "sc.client,\n" +
          "sc.id\n" +
          'FROM "lambda:fedq-domain".v_all.sales_channel sc\n' +
          'LEFT JOIN "lambda:fedq-domain".v_all.contract c ON c.client = sc.client AND c.sales_channel_id = sc.id\n' +
          "WHERE sc.client = 'pandora'\n" +
          "AND sc.is_active = true\n" +
          "AND c.is_active = true\n" +
          "AND (c.end_Date is NULL OR CAST(c.end_Date AS timestamp(3)) >= now())\n" +
          "ORDER BY sc.client, sc.id, c.id ASC",
      },
      {
        label: "m?allPublisherIds",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobtser test - all pubIds\n" +
          "SELECT \n" +
          "a.client,\n" +
          "a.affiliateid AS publisherId\n" +
          'FROM "lambda:fedq-domain".v_all.affiliates a\n' +
          "ORDER BY a.client, a.affiliateid ASC",
      },
      {
        label: "m?allActiveSalesChannelXPublisherWithSSpFeePerPublisher",
        kind: monaco.languages.CompletionItemKind.Property,
        insertText:
          "-- Lobtser test - all active scIds crossed with the pubIds and appended with the sspFeePerPublisher\n" +
          "SELECT \n" +
          "sc.client AS clientValueFromSc, \n" +
          "sc.id AS scId,\n" +
          "a.affiliateid AS pubId,\n" +
          "if(fpsca.price_value IS NOT NULL, true, false) AS feeValuePresent,\n" +
          "fpsca.price_value AS sspFeePerPublisher\n" +
          'FROM "lambda:fedq-domain".v_all.sales_channel sc\n' +
          'LEFT JOIN "lambda:fedq-domain".v_all.contract c ON c.client = sc.client AND c.sales_channel_id = sc.id\n' +
          'LEFT JOIN "lambda:fedq-domain".v_all.affiliates a ON a.client = sc.client -- on client so to force the cardinal product between sc and pub ids\n' +
          'LEFT JOIN "lambda:fedq-domain".v_all.fee_publisher_sales_channel_assoc fpsca ON (fpsca.client = sc.client AND fpsca.sales_channel_id = sc.id AND fpsca.publisher_id = a.affiliateid)\n' +
          "WHERE sc.is_active = true\n" +
          "AND c.is_active = true\n" +
          "AND (c.end_Date is NULL OR CAST(c.end_Date AS timestamp(3)) >= now())\n" +
          "ORDER BY sc.client, sc.id, a.affiliateid ASC",
      },
    ];
  },
};

export default MacroSuggestionsHelper;
