import { notification } from "antd";

const Toaster = {
  // Setting duration 0 means the notification box will never close automatically. 5 seconds is the default.
  notify: (type, message, duration) => {
    notification[type]({
      message: message,
      placement: "topRight",
      duration: duration || 5,
    });
  },
};

export default Toaster;
