import { Avatar, Col, Popover, Row, Space, Tag, Typography } from "antd";
import React from "react";
import DateFormatHelper from "../../../utils/DateFormatHelper";
import DateHelper from "../../../utils/DateHelper";
import EventTypeFormatHelper from "../../../utils/EventTypeFormatHelper";
import AthenaCostHelper from "../../../utils/AthenaCostHelper";
import JobDetails from "./JobDetails";
import CommonButton from "../../common/CommonButton";
import {
  CodeOutlined,
  DownloadOutlined,
  EditOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import QueryDetails from "./QueryDetails";
import "./JobsTable.css";

const { Paragraph } = Typography;

//TODO fetch it from API
const jobStates = ["RUNNING", "FAILED", "SUCCEEDED", "TERMINATED"];
const colorList = ["#ffb83d", "#f54a04", "#24aa05", "#1e1e1e"];

const shortenName = (value) => {
  if (!value && typeof value !== "string") return "";

  if (value.includes("@")) return value.split("@")[0];

  const arr = value.match(/\b(\w{0,10})/g);

  let shortname;
  try {
    if (value.includes(","))
      shortname = `${arr[2].substr(0, 3)} ${arr[0].substr(0, 1)}`;
    else if (value.includes(" "))
      shortname = `${arr[0].substr(0, 3)} ${arr[2].substr(0, 1)}`;
    else shortname = arr[0].substr(0, 3);
  } catch (e) {
    shortname = arr[0].substr(0, 3);
  }

  return shortname;
};

const JobsTable = ({
  list,
  onEditQuery,
  fileLink,
  taskIdToName,
  hasAdditionalInfo,
}) => (
  <div className="job-table">
    <Row className="job-table-header" gutter={[16, 16]} justify="center">
      <Col span={2}>Job Status</Col>
      <Col span={4}>Job Date [UTC]</Col>
      <Col span={2}>Job Duration</Col>
      {hasAdditionalInfo ? (
        <React.Fragment>
          <Col span={3}>Summary</Col>
          <Col span={5}>Task</Col>
          <Col span={1}>Req.</Col>
          <Col span={2}>Cost [$]</Col>
        </React.Fragment>
      ) : (
        <Col span={8}>Summary</Col>
      )}
      <Col span={2} />
      <Col span={2} />
    </Row>
    {list.map((item, index) => (
      <Row
        className="job-table-row"
        key={index}
        gutter={[16, 16]}
        justify="center"
      >
        <Col span={2} title={item.state}>
          <Avatar
            style={{
              backgroundColor: colorList[jobStates.indexOf(item.state) || 0],
              verticalAlign: "middle",
            }}
            size="small"
          >
            {item.state.substring(0, 1)}
          </Avatar>
        </Col>
        <Col span={4}>
          {DateFormatHelper.toPrettyDatetime(item.submittedDate)}
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          {item.stoppedDate &&
            DateHelper.readableDuration(
              DateHelper.parseDateTime(item.stoppedDate) -
                DateHelper.parseDateTime(item.submittedDate),
              true
            )}
        </Col>
        {hasAdditionalInfo ? (
          <React.Fragment>
            <Col span={5}>
              <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: ">" }}>
                {taskIdToName &&
                  ((item.rawDataExportDto &&
                    taskIdToName[item.rawDataExportDto.taskId]) ||
                    (item.dataExportDto &&
                      taskIdToName[item.dataExportDto.taskId]) ||
                    (item.plaintextQueryDataExportDto &&
                      taskIdToName[item.plaintextQueryDataExportDto.taskId]))}
              </Paragraph>
            </Col>
            <Col span={3}>
              {item.rawDataExportDto &&
                item.rawDataExportDto.date + " H" + item.rawDataExportDto.hour}
              {item.dataExportDto &&
                item.dataExportDto.datasetType === "RAW_EVENTS" &&
                EventTypeFormatHelper.toPrettyEventType(
                  item.dataExportDto.eventType
                )}
              {item.dataExportDto &&
                item.dataExportDto.datasetType === "AUDIENCE_SEGMENTS" &&
                "Audience Segments"}
              {item.plaintextQueryDataExportDto &&
                item.plaintextQueryDataExportDto.plaintextQuery &&
                (item.plaintextQueryDataExportDto.plaintextQuery.length > 20
                  ? item.plaintextQueryDataExportDto.plaintextQuery.substring(
                      0,
                      20
                    )
                  : item.plaintextQueryDataExportDto.plaintextQuery)}
            </Col>
            <Col span={1}>{shortenName(item.requesterUsername)}</Col>
            <Col span={2}>
              {item.dataExportResponse &&
                item.dataExportResponse.map &&
                item.dataExportResponse.map.queryExecutionResult &&
                item.dataExportResponse.map.queryExecutionResult.statistics &&
                AthenaCostHelper.formatCost(
                  item.dataExportResponse.map.queryExecutionResult.statistics
                    .dataScannedInBytes,
                  2
                )}
            </Col>
          </React.Fragment>
        ) : (
          <Col span={8}>
            {item.dataExportDto &&
              item.dataExportDto.datasetType === "RAW_EVENTS" && (
                <Space>
                  {item.dataExportDto.client || "-"}
                  {EventTypeFormatHelper.toPrettyEventType(
                    item.dataExportDto.eventType
                  )}
                  {DateFormatHelper.toPrettyDate(item.dataExportDto.dateRange)}
                  <Tag color="default" title={item.dataExportDto.queryMode}>
                    {item.dataExportDto.queryMode.substring(0, 1).toUpperCase()}
                  </Tag>
                </Space>
              )}
            {item.dataExportDto &&
              item.dataExportDto.datasetType === "AUDIENCE_SEGMENTS" && (
                <Space>
                  {"Audience Segments"}
                  {DateFormatHelper.toPrettyDate(item.dataExportDto.dateRange)}
                  <Tag color="default" title={item.dataExportDto.queryMode}>
                    {item.dataExportDto.queryMode.substring(0, 1).toUpperCase()}
                  </Tag>
                </Space>
              )}
            {item.plaintextQueryDataExportDto &&
              item.plaintextQueryDataExportDto.plaintextQuery &&
              (item.plaintextQueryDataExportDto.plaintextQuery.length > 50
                ? item.plaintextQueryDataExportDto.plaintextQuery.substring(
                    0,
                    50
                  )
                : item.plaintextQueryDataExportDto.plaintextQuery)}
          </Col>
        )}

        <Col span={2}>
          <Popover
            content={<JobDetails item={item} />}
            title="Job Details"
            placement="left"
            trigger="click"
          >
            <CommonButton title="Job Details" style={{ padding: "4px 4px" }}>
              <ProfileOutlined />
            </CommonButton>
          </Popover>
          <Popover
            content={<QueryDetails item={item} />}
            title="Query Details"
            placement="left"
            trigger="click"
          >
            <CommonButton title="Query Details" style={{ padding: "4px 4px" }}>
              <CodeOutlined />
            </CommonButton>
          </Popover>
          <CommonButton
            title="Edit Query"
            onClick={() => onEditQuery(item)}
            style={{ padding: "4px 4px" }}
            disabled={
              item.type !== "BuiltQueryDataExportJob" &&
              item.type !== "PlaintextQueryDataExportJob"
            }
          >
            <EditOutlined />
          </CommonButton>
        </Col>
        <Col span={2} title="Download">
          <CommonButton
            disabled={
              !(
                item.state === "SUCCEEDED" &&
                item.dataExportResponse &&
                item.dataExportResponse.map &&
                item.dataExportResponse.map.totalFileSize > 0
              )
            }
            href={fileLink(item)}
          >
            <DownloadOutlined />
          </CommonButton>
        </Col>
      </Row>
    ))}
  </div>
);
export default JobsTable;
