const AthenaCostHelper = {
  // Queries are charged for the number of bytes scanned by Amazon Athena, rounded up to the nearest megabyte, with a 10MB minimum per query.
  // (There are no charges for failed queries. Cancelled queries are charged based on the amount of data scanned (The latter is not taken into account by Lobster).)
  // $5.00 per TB of data scanned
  athenaQueryCost: 5 / (1000 * 1000 * 1000 * 1000), // $5.00 per TB of data scanned

  minimumQuerySizeFee: 10 * 1000 * 1000, // 10MB

  cost: (scannedDataInBytes = 0) => {
    return (
      Math.max(
        AthenaCostHelper.minimumQuerySizeFee,
        Math.round(scannedDataInBytes / (1000 * 1000)) * 1000 * 1000
      ) * AthenaCostHelper.athenaQueryCost
    );
  },

  /**
   * precision is between 0 to 6 fractional digits
   * @param scannedDataInBytes
   * @param precision
   * @returns {number}
   */
  formatCost: (scannedDataInBytes = 0, precision = 2) => {
    const fractionalDigits = Math.min(Math.max(precision, 0), 6);

    const amount = AthenaCostHelper.cost(scannedDataInBytes);

    return (
      Math.round(amount * Math.pow(10, fractionalDigits)) /
      Math.pow(10, fractionalDigits)
    );
  },

  formatCostForDisplay: (scannedDataInBytes = 0) => {
    const amount = AthenaCostHelper.cost(scannedDataInBytes);

    if (amount < 0.01) return "less than 1 cent";
    else return "about $" + Math.round(amount * 100) / 100;
  },
};

export default AthenaCostHelper;
