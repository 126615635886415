import React from "react";
import { Popover, Table, Tag } from "antd";
import Comparator from "./helper/Comparator";
import ReactJson from "react-json-view";
import ObjectHelper from "../../../utils/ObjectHelper";
import ZoneRollTypeTag from "./helper/ZoneRollTypeTag";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const BID_SUMMARY_PROTOTYPE = {
  client: undefined,
  supplyagencyid: undefined,
  supplypublisherid: undefined,
  zoneid: undefined,
  zonerolltype: undefined,
  status: undefined,
  biddername: undefined,
  bidderid: undefined,
  pricetype: undefined,
  agencyid: undefined,
  campaignid: undefined,
  adid: undefined,
  dealid: undefined,
  saleschannelid: undefined,
  contractid: undefined,
  contractlineid: undefined,
};

const TABLE_COLUMN_PROTOTYPE = {
  title: undefined,
  dataIndex: undefined,
  sorter: undefined,
  align: undefined,
  width: undefined,
  render: undefined,
};

// cf bid status list from https://adswizz.jira.com/wiki/spaces/UBERMEGAGUILD/pages/464715912/Kinesis+Bid+Event+Status+Page
const BID_STATUS_CONFIG = {
  // TODO check others
  WON: { color: "success" },
  NO_BID: { color: "default" },
  BELOW_FLOOR_PRICE: { color: "warning" },
  INVALID_CONFIGURATION: { color: "error" },
  _DEFAULT: { color: "geekblue" },
};

const toStatusColorType = (status) =>
  (BID_STATUS_CONFIG[status] || BID_STATUS_CONFIG._DEFAULT).color;

const BuyerBidRejectInformation = ({ buyerbidrejectinformation }) => {
  try {
    const jsonValue = JSON.parse(
      ObjectHelper.convertPseudoObjectType2ToJsonText(buyerbidrejectinformation)
    );

    return (
      <ReactJson
        name={false}
        displayObjectSize={true}
        displayDataTypes={false}
        enableClipboard={true}
        collapseStringsAfterLength={40}
        collapsed={1}
        style={{ fontSize: "10px" }}
        src={jsonValue}
      />
    );
  } catch (e) {
    return "raw bid rejection info: " + buyerbidrejectinformation;
  }
};

const setupStatusColumnType = ($) => {
  return {
    ...TABLE_COLUMN_PROTOTYPE,
    title: $,
    dataIndex: $,
    sorter: {
      compare: (a, b) => Comparator.propertyBasedComp(a, b, "status"),
      multiple: 1,
    },
    align: "left",
    render: (status, bid) => {
      return (
        <>
          <Tag color={toStatusColorType(status)} key={status}>
            {status}
          </Tag>
          {bid.buyerbidrejectinformation &&
            bid.buyerbidrejectinformation.length > 2 && (
              <Popover
                title="Buyer Bid Rejection Information"
                content={
                  <BuyerBidRejectInformation
                    buyerbidrejectinformation={bid.buyerbidrejectinformation}
                  />
                }
                trigger="click"
                overlayInnerStyle={{
                  width: "500px",
                  height: "300px",
                  overflowY: "auto",
                }}
              >
                <span style={{ color: "#bbb" }}>*</span>
              </Popover>
            )}
        </>
      );
    },
  };
};

const setupPricetypeColumnType = ($) => {
  return {
    ...TABLE_COLUMN_PROTOTYPE,
    title: $,
    dataIndex: $,
    sorter: {
      compare: (a, b) => Comparator.propertyBasedComp(a, b, "pricetype"),
      multiple: 2,
    },
    align: "center",
  };
};

const setupAudioServeLinkColumnType = (name) => {
  return {
    ...TABLE_COLUMN_PROTOTYPE,
    title: name,
    dataIndex: name,
    align: "right",
    render: (value, bid) => {
      // reminder - "noopener noreferrer" is to prevent tabnabbing (phishing attack) from the pages you link to...

      const asUrlValid =
        bid.pricetype === "AS" &&
        bid.campaignid &&
        Number.parseInt(bid.campaignid) > 0 &&
        bid.adid &&
        Number.parseInt(bid.adid) > 0;

      if (asUrlValid) {
        const globalConfiguration = serviceLocator.getGlobalConfiguration();

        const audioserveUrl =
          globalConfiguration.templateConfig.audioserveCaAdUrlTemplate
            .replaceAll("%client%", bid.client)
            .replaceAll("%campaignid%", bid.campaignid)
            .replaceAll("%adid%", bid.adid);

        return (
          <a
            href={audioserveUrl}
            target="_blank"
            rel="noopener noreferrer"
            title="to AudioServe"
            style={{ color: "#8a489c" }}
          >
            {value}
          </a>
        );
      } else return <span style={{ color: "#bbb" }}>{value}</span>;
    },
  };
};

const setupAudioMaxLinkColumnType = (name) => {
  return {
    ...TABLE_COLUMN_PROTOTYPE,
    title: name,
    dataIndex: name,
    align: "right",
    render: (value, bid) => {
      // reminder - "noopener noreferrer" is to prevent tabnabbing (phishing attack) from the pages you link to...

      const sspUrlValid =
        bid.pricetype === "SSP" &&
        bid.saleschannelid &&
        Number.parseInt(bid.saleschannelid) > 0 &&
        bid.contractlineid &&
        Number.parseInt(bid.contractlineid) > 0;

      if (sspUrlValid) {
        const globalConfiguration = serviceLocator.getGlobalConfiguration();

        const audiomaxUrl =
          globalConfiguration.templateConfig.audiomaxScClUrlTemplate
            .replaceAll("%client%", bid.client)
            .replaceAll("%saleschannelid%", bid.saleschannelid)
            .replaceAll("%contractlineid%", bid.contractlineid);

        return (
          <a
            href={audiomaxUrl}
            target="_blank"
            rel="noopener noreferrer"
            title="to AudioMax"
            style={{ color: "#de8283" }}
          >
            {value}
          </a>
        );
      } else return <span style={{ color: "#bbb" }}>{value}</span>;
    },
  };
};

//TODO add a link to its zone: https://audioserve.adswizz.com/podfront/#/publishers/8/zones/27/settings
const setupAgencyColumnType = ($) => ({
  ...TABLE_COLUMN_PROTOTYPE,
  title: "supply agencyid",
  dataIndex: $,
  align: "right",
});

const setupPublisherColumnType = ($) => ({
  ...TABLE_COLUMN_PROTOTYPE,
  title: "supply publisherid",
  dataIndex: $,
  align: "right",
});

const setupZoneIdColumnType = ($) => ({
  ...TABLE_COLUMN_PROTOTYPE,
  title: "zoneid",
  dataIndex: $,
  align: "right",
  sorter: {
    compare: (a, b) => Comparator.propertyBasedComp(a, b, "zoneid"),
    multiple: 1,
  },
});

const setupZoneRollTypeColumnType = ($) => ({
  ...TABLE_COLUMN_PROTOTYPE,
  title: "zrt",
  dataIndex: $,
  align: "center",
  sorter: {
    compare: (a, b) => Comparator.propertyBasedComp(a, b, "zonerolltype"),
    multiple: 1,
  },
  render: (zrt, bid) => {
    return <ZoneRollTypeTag type={zrt} />;
  },
});

const setupDefaultColumnType = ($) => ({
  ...TABLE_COLUMN_PROTOTYPE,
  title: $,
  dataIndex: $,
  align: "right",
});

const UnmappedBidResponsesTable = ({ bidResponses }) => {
  if (!bidResponses) return null;

  const columns = Object.getOwnPropertyNames(BID_SUMMARY_PROTOTYPE).map(
    ($, index) => {
      switch ($) {
        case "status":
          return setupStatusColumnType($);
        case "pricetype":
          return setupPricetypeColumnType($);
        case "agencyid":
        case "campaignid":
        case "adid":
          return setupAudioServeLinkColumnType($);
        case "saleschannelid":
        case "contractid":
        case "contractlineid":
          return setupAudioMaxLinkColumnType($);
        case "supplyagencyid":
          return setupAgencyColumnType($);
        case "supplypublisherid":
          return setupPublisherColumnType($);
        case "zoneid":
          return setupZoneIdColumnType($);
        case "zonerolltype":
          return setupZoneRollTypeColumnType($);
        default:
          return setupDefaultColumnType($);
      }
    }
  );

  const items = bidResponses.map(($, index) => {
    return { ...$, key: index };
  });

  return items.length > 0 ? (
    <Table
      dataSource={items}
      columns={columns}
      pagination={items.length > 10}
      size="small"
      style={{ fontSize: "8px", margin: "15px 0 0 0" }}
    />
  ) : null;
};

export default UnmappedBidResponsesTable;
