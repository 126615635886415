import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import AppWithSecurity from "./app/AppWithSecurity";

// Define the type for service worker registration options
interface ServiceWorkerRegistrationOptions {
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
}

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <AppWithSecurity />
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );

  const serviceWorkerOptions: ServiceWorkerRegistrationOptions = {
    onUpdate: (registration) => {
      if (window.confirm("New version available. Do you want to update?")) {
        if (registration.waiting) {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
      }
    },
    onSuccess: (registration) => {
      console.log(
        "Service Worker registered successfully with scope:",
        registration.scope
      );
    },
  };

  serviceWorker.register(serviceWorkerOptions);
} else {
  console.error("Root element not found. Application failed to start.");
}
