import React from "react";
import "./Search.css";

/*
// version 0
class Search0 extends React.Component {
    render() {
        const { value, onChange, onSubmit, children } = this.props;
        return (
            <form onSubmit={onSubmit}>
                <input
                    type="text"
                    value={value}
                    onChange={onChange}
                />
                <button type="submit">
                    {children}
                </button>
            </form>
        );
    }
}

// version 1
function Search1(props) {
    const { value, onChange, onSubmit, children } = props;
    return (
        <form onSubmit={onSubmit}>
            <input
                type="text"
                value={value}
                onChange={onChange}
            />
            <button type="submit">
                {children}
            </button>
        </form>
    );
}

// version 2
function Search2({ value, onChange, onSubmit, children }) {
    return (
        <form onSubmit={onSubmit}>
            <input
                type="text"
                value={value}
                onChange={onChange}
            />
            <button type="submit">
                {children}
            </button>
        </form>
    );
}

// version 3
const Search3 = ({ value, onChange, onSubmit, children }) =>
    <form onSubmit={onSubmit}>
        <input
            type="text"
            value={value}
            onChange={onChange}
        />
        <button type="submit">
            {children}
        </button>
    </form>

const Search4 = ({
                    value,
                    onChange,
                    onSubmit,
                    children
                }) => {

    // do something

    return (
        <form onSubmit={onSubmit}>
            <input
                type="text"
                value={value}
                onChange={onChange}
            />
            <button type="submit">
                {children}
            </button>
        </form>
    );
}
*/

const Search = ({ value, onChange, onSubmit, children }) => (
  <form onSubmit={onSubmit}>
    <input type="text" value={value} onChange={onChange} />
    <button type="submit">{children}</button>
  </form>
);

export default Search;
