import React from "react";
import { Divider, Empty, Spin, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import OpportunityModel from "./model/OpportunityModel";
import ZoneRollTypeTag from "./helper/ZoneRollTypeTag";
import BidsTable from "./BidsTable";
import UnmappedBidResponsesTable from "./UnmappedBidResponsesTable";
import { useSelector } from "react-redux";
import { selectJobs } from "../../../reducers/txTrackingReducer";
import CenteredEmpty from "../../common/CenteredEmpty";
import SpinContainer from "../../common/SpinContainer";

const hasResult = (jobs) => jobs.some((j) => j.result && j.result.length > 0);

// Main entry point
const BidsDataTabPane = (props) => {
  const jobs = useSelector(selectJobs);
  return (
    <>
      {props.isLoading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        !hasResult(jobs) && (
          <CenteredEmpty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No Bids Data</span>}
          />
        )
      )}
      {hasResult(jobs) && <OppTree />}
    </>
  );
};

// build opp-bid tree + render
const OppTree = () => {
  const jobs = useSelector(selectJobs);
  const oppJobData = jobs.find(($) => $.eventType === "OPPORTUNITY");
  const oppEvents = oppJobData && oppJobData.result;

  if (!oppEvents) return <CenteredEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  const bidJobData = jobs.find(($) => $.eventType === "BIDS");
  const bidEvents = bidJobData && bidJobData.result;

  let [opportunityModel, unmappedBidResponses] = OpportunityModel.populateModel(
    oppEvents,
    bidEvents
  );

  opportunityModel = opportunityModel.filter((opp) => {
    return opp.nodes.every((node) => node.bids && node.bids.length > 0);
  });

  if (!opportunityModel || opportunityModel.length === 0) {
    return <CenteredEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <>
      <ul
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        {opportunityModel.map(($, index) => (
          <li
            style={{ listStyleType: "none", margin: 0, padding: 0 }}
            key={index}
          >
            <OppLevel nodes={$.nodes} />
          </li>
        ))}
      </ul>
      <Divider />
      {unmappedBidResponses && unmappedBidResponses.length > 0 ? (
        <div style={{ margin: "15px 0 0 0" }}>
          <span>
            The following bid responses cannot match any of the Opportunity
            nodes:
          </span>
          <UnmappedBidResponsesTable
            bidResponses={unmappedBidResponses}
            style={{ margin: "15px 0 0 0" }}
          />
        </div>
      ) : null}
    </>
  );
};

const OppLevel = ({ nodes, recIndex = 0 }) => (
  <ul
    style={{
      margin: 0,
      padding: 0,
    }}
  >
    {nodes
      .filter((node) => {
        return node.bids && node.bids.length > 0;
      })
      .map((opp, liIndex) => (
        <li
          style={{
            listStyleType: "none",
            margin:
              Math.max(5 - recIndex, 0) * 3 +
              "px 0px 0px " +
              recIndex * 20 +
              "px",
            padding: 0,
          }}
          key={opp.client + "-" + opp.zoneid + "-" + liIndex}
        >
          <div>
            <Tag
              color="blue"
              key={liIndex}
              style={{
                fontSize: "10px",
                margin: "0 5px",
                padding: "0 1px",
              }}
            >
              {Array.from(Array(recIndex + 1), (e, i) => `>`)}
            </Tag>

            <Text strong title={"metanodepath: " + opp.metanodepath}>
              {opp.client}
            </Text>
            <span title={opp.zonerolltype}>
              <ZoneRollTypeTag type={opp.zonerolltype} />
            </span>
            <span style={{ fontSize: "10px" }}>
              (
              {`agency ${opp.agencyid}, publisher ${opp.publisherid}, zone ${opp.zoneid}, zt ${opp.zonetype}`}
              )
            </span>
            <div>
              <BidsTable bids={opp.bids} client={opp.client} />
            </div>
          </div>
          {opp.nodes && opp.nodes.length > 0 && (
            <OppLevel nodes={opp.nodes} recIndex={recIndex + 1} />
          )}
        </li>
      ))}
  </ul>
);

export default BidsDataTabPane;
