import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Toaster from "../../utils/Toaster";
import {
  Empty,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import ReactJson from "react-json-view";
import DateHelper from "../../utils/DateHelper";
import CommonButton from "../common/CommonButton";
import { ReloadOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { serviceLocator } from "../../services/ServiceLocatorImpl";

//TODO fetch it from API
const jobStates = ["RUNNING", "FAILED", "SUCCEEDED", "TERMINATED"];
const colorList = ["#ffb83d", "#f54a04", "#24aa05", "#1e1e1e"];

const formatter = new Intl.NumberFormat("en", {
  style: "decimal",
  unit: "byte",
});

const EXPORT_DIGEST_DAYS = 3;

const fetchJobExportDigest = (days = EXPORT_DIGEST_DAYS) => {
  return serviceLocator
    .getApiService()
    .get(
      `${serviceLocator.getGlobalConfiguration().pathBase}${
        serviceLocator.getGlobalConfiguration().dataExportConfig
          .dataExportDigestEndpoint
      }?days=${days}`
    );
};

const submitJob = (rawDataExportDto) => {
  return serviceLocator
    .getApiService()
    .post(
      `${serviceLocator.getGlobalConfiguration().pathBase}${
        serviceLocator.getGlobalConfiguration().dataExportConfig
          .rawDataExportSubmitEndpoint
      }`,
      rawDataExportDto
    );
};

const terminateJob = (jobId) => {
  return serviceLocator
    .getApiService()
    .delete(
      `${serviceLocator.getGlobalConfiguration().pathBase}${
        serviceLocator.getGlobalConfiguration().dataExportConfig
          .dataExportCancelEndpoint
      }/${jobId}`
    );
};

const COLUMN_PROTOTYPE = {
  title: undefined,
  dataIndex: undefined,
  key: undefined,
};

const DATA_PROTOTYPE = {
  key: undefined,
  labelId: undefined,
  // custom elements, the list of the different type of exports s.a. "pandora-opportunity"...
};

const onJobSummaryContent = (item) => {
  if (item && item.dataExportResponse && item.dataExportResponse.map) {
    return (
      <>
        <p>
          {formatter.format(item.dataExportResponse.map.totalFileSize)} bytes
        </p>
        <p>
          {item.stoppedDate
            ? DateHelper.readableDuration(
                DateHelper.parseDateTime(item.stoppedDate) -
                  DateHelper.parseDateTime(item.submittedDate),
                true
              )
            : "-"}
        </p>
      </>
    );
  }
};

const onJobSummaryTitle = (item) =>
  item.rawDataExportDto
    ? (item.rawDataExportDto.date || "NA") +
      " H" +
      (item.rawDataExportDto.hour || "NA")
    : "NA";

// const computeTagSizeWithLogLinearAlgo = (size) => {
//   const index = Math.log10(size);
//
//   // cut-off
//   if (index <= 6) return 8;
//
//   // empirical linear rule
//   return 5 * index - 28;
// };

const xa = 1 * 1024 * 1024; // 1MB
const xb = 40 * 1024 * 1024 * 1024; // 40GB
const ya = 8; // 8px
const yb = 22; // 22px
const m = (yb - ya) / (xb - xa);
const p = yb;

const computeTagSizeWithLinearAlgo = (size) => {
  // cut-off
  if (size <= xa) return ya;
  if (size >= xb) return yb;

  // linear rule
  return m * (size - xb) + p;
};

const getTagSize = (item) => {
  if (
    item &&
    item.dataExportResponse &&
    item.dataExportResponse.map &&
    item.state === "SUCCEEDED"
  ) {
    const totalFileSize = item.dataExportResponse.map.totalFileSize;

    //    return computeTagSizeWithLogLinearAlgo(totalFileSize) + "px";
    return computeTagSizeWithLinearAlgo(totalFileSize) + "px";
  } else return "12px";
};

const getData = (outcome) => {
  const data = outcome.map((item, index) => ({
    ...DATA_PROTOTYPE,
    ...item,
    key: index,
  }));

  return data;
};

const getNow = () => {
  return new Intl.DateTimeFormat("en-GB", {
    dateStyle: "long",
    timeStyle: "long",
    timeZone: "UTC",
  }).format(new Date());
};

const LastUpdated = () => (
  <div style={{ fontSize: "10px" }}>(Last updated: {getNow()})</div>
);

const toTinyName = (name) => {
  return (name || "?").substring(0, 1);
};

const ExportDigest = () => {
  const [exportDigest, setExportDigest] = useState(undefined);
  const [reload, setReload] = useState(true);
  const [taskId, setTaskId] = useState(undefined);
  const [dateValue, setDateValue] = useState(undefined);
  const [hourValue, setHourValue] = useState(undefined);
  const [jobId, setJobId] = useState(undefined);

  const onTaskTitleContent = (summary) => {
    const onStartJob = () => {
      const rawDataExportDto = {
        taskId: summary && summary.taskId,
        date: dateValue,
        hour: hourValue,
      };

      Toaster.notify("warning", "Job submitted MANUALLY");
      submitJob(rawDataExportDto);

      onReload();
    };

    const onConfigureJob = (taskId) => {
      return (
        <>
          <Input
            size="small"
            placeholder="enter your task ID"
            style={{ width: "80%", margin: "10px 10px" }}
            value={taskId}
            onChange={(e) => setTaskId(e.target.value)}
          ></Input>
          <Space direction={"vertical"}>
            <Space direction={"horizontal"}>
              <Input
                size="small"
                placeholder="Date [yyyyMMdd]"
                style={{ width: "80%", margin: "10px 10px" }}
                value={dateValue}
                onChange={(e) => setDateValue(e.target.value)}
              ></Input>
              <InputNumber
                size="small"
                placeholder="Hour [0-23]"
                min="0"
                max="23"
                style={{ width: "80%", margin: "10px 10px" }}
                value={hourValue}
                onChange={setHourValue}
              ></InputNumber>
            </Space>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={onStartJob}
            >
              <CommonButton danger type="primary">
                Start the job
              </CommonButton>
            </Popconfirm>
          </Space>
        </>
      );
    };

    return (
      <div>
        <p>{"task ID: " + summary.taskId}</p>
        <Popover
          content={onConfigureJob(summary.taskId)}
          title="Configure a job"
          trigger="click"
        >
          <CommonButton type="primary">Configure a job</CommonButton>
        </Popover>
      </div>
    );
  };

  const getColumns = (taskSummary) => {
    //{
    //  "instanceName": "pandora",
    //  "name": "pandora Click Export",
    //  "eventType": "click",
    //  "taskId": "60268d9fe73673643eb8e397"
    //}
    const columns = taskSummary.map((s) => ({
      ...COLUMN_PROTOTYPE,
      title: () => {
        return (
          <Popover
            title={s.name}
            content={onTaskTitleContent(s)}
            placement="top"
            overlayStyle={{
              width: "20vw",
            }}
            trigger="click"
          >
            <Paragraph ellipsis={{ rows: 3, expandable: false, symbol: ">" }}>
              {s.name}
            </Paragraph>
          </Popover>
        );
      },
      dataIndex: s.taskId,
      key: s.taskId,
      align: "center",
      render: (item, record, index) => {
        const onStartJob = () => {
          const rawDataExportDto = {
            taskId: s && s.taskId,
            date:
              record &&
              record.labelId &&
              record.labelId.length === 10 &&
              record.labelId.substring(0, 8),
            hour:
              record &&
              record.labelId &&
              record.labelId.length === 10 &&
              Number.parseInt(record.labelId.substring(8)),
          };

          Toaster.notify("warning", "Job submitted MANUALLY");
          submitJob(rawDataExportDto);

          onReload();
        };

        return item ? (
          item.map((it, index) => (
            <Popover
              content={onJobSummaryContent(it)}
              title={onJobSummaryTitle(it)}
              placement="left"
            >
              <Popover
                content={onJobDetails(it)}
                title="Job Details"
                trigger="click"
              >
                {index === item.length - 1 ? (
                  <Tag
                    color={colorList[jobStates.indexOf(it.state) || 0]}
                    key={toTinyName(it.state)}
                    style={{ fontSize: getTagSize(it) }}
                  >
                    {toTinyName(it.state)}
                  </Tag>
                ) : (
                  <Tag
                    color={colorList[jobStates.indexOf(it.state) || 0]}
                    key={toTinyName(it.state)}
                    style={{
                      fontSize: getTagSize(it),
                      opacity: 0.3,
                    }}
                  >
                    {toTinyName(it.state)}
                  </Tag>
                )}
              </Popover>
            </Popover>
          ))
        ) : (
          <Tag color="#eaeafa" key="-" style={{ fontSize: "14px" }}>
            <Popover
              content={() => (
                <div>
                  <p>{"task ID: " + s.taskId}</p>
                  <p>{"label ID: " + record.labelId}</p>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={onStartJob}
                  >
                    <CommonButton danger type="primary">
                      Start this job manually
                    </CommonButton>
                  </Popconfirm>
                </div>
              )}
              title="Job Context"
              trigger="click"
            >
              -
            </Popover>
          </Tag>
        );
      },
    }));

    columns.unshift({
      ...COLUMN_PROTOTYPE,
      title: "Label",
      dataIndex: "labelId",
      key: "labelId",
      align: "center",
      width: 100,
    });

    return columns;
  };

  const onJobDetails = (item) => {
    const onTerminateJob = () => {
      const jobUid = item.uid;

      Toaster.notify("warning", "Job being terminated MANUALLY");
      terminateJob(jobUid);

      onReload();
    };

    const onSubmitJob = () => {
      const rawDataExportDto = item.rawDataExportDto;

      Toaster.notify("warning", "Job submitted MANUALLY");
      submitJob(rawDataExportDto);

      onReload();
    };

    return (
      <Space direction="vertical">
        <ReactJson
          name={false}
          displayObjectSize={false}
          displayDataTypes={false}
          enableClipboard={true}
          collapseStringsAfterLength={40}
          collapsed={2}
          style={{ fontSize: "12px" }}
          src={item}
        />
        {(item.state === "RUNNING" || item.state === "QUEUED") && (
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={onTerminateJob}
          >
            <CommonButton danger type="primary">
              Terminate this job manually
            </CommonButton>
          </Popconfirm>
        )}
        {(item.state === "SUCCEEDED" ||
          item.state === "TERMINATED" ||
          item.state === "FAILED" ||
          item.state === "ERROR") && (
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={onSubmitJob}
          >
            <CommonButton danger type="primary">
              Submit this job manually
            </CommonButton>
          </Popconfirm>
        )}
      </Space>
    );
  };

  useEffect(
    () =>
      reload &&
      fetchJobExportDigest()
        .then((result) => result.data)
        .then((data) => setExportDigest(data))
        .catch((error) => {
          Toaster.notify("error", error.message);
          return false;
        })
        .finally(() => setReload(false)),
    [reload]
  );

  const onReload = () => {
    setReload(true);
  };

  const REFRESH_RATE_IN_MS = 300 * 1000; // 5min

  useEffect(() => {
    const interval = setInterval(() => {
      setReload(true);
      Toaster.notify("info", "Refreshing...");
    }, REFRESH_RATE_IN_MS);

    return () => {
      clearInterval(interval);
    };
  }, [REFRESH_RATE_IN_MS]);

  const onStartJob = () => {
    const rawDataExportDto = {
      taskId: taskId,
      date: dateValue,
      hour: hourValue,
    };

    Toaster.notify("warning", "Job submitted MANUALLY");
    submitJob(rawDataExportDto);

    onReload();
  };

  const onConfigureJob = () => {
    return (
      <>
        <Input
          size="small"
          placeholder="enter your task ID"
          style={{ width: "80%", margin: "10px 10px" }}
          value={taskId}
          onChange={(e) => setTaskId(e.target.value)}
        ></Input>
        <Space direction={"vertical"}>
          <Space direction={"horizontal"}>
            <Input
              size="small"
              placeholder="Date [yyyyMMdd]"
              style={{ width: "80%", margin: "10px 10px" }}
              value={dateValue}
              onChange={(e) => setDateValue(e.target.value)}
            ></Input>
            <InputNumber
              size="small"
              placeholder="Hour [0-23]"
              min="0"
              max="23"
              style={{ width: "80%", margin: "10px 10px" }}
              value={hourValue}
              onChange={setHourValue}
            ></InputNumber>
          </Space>
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={onStartJob}
          >
            <CommonButton danger type="primary">
              Start the job
            </CommonButton>
          </Popconfirm>
        </Space>
      </>
    );
  };

  const onTerminateJob = () => {
    Toaster.notify("warning", "Job being terminated MANUALLY");
    terminateJob(jobId);

    onReload();
  };

  const onCancelJob = () => {
    return (
      <>
        <Input
          size="small"
          placeholder="enter your job ID"
          style={{ width: "80%", margin: "10px 10px" }}
          value={jobId}
          onChange={(e) => setJobId(e.target.value)}
        ></Input>
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={onTerminateJob}
        >
          <CommonButton danger type="primary">
            Terminate the job
          </CommonButton>
        </Popconfirm>
      </>
    );
  };

  const onActionSelection = () => (
    <Space direction="vertical">
      <Popover content={onConfigureJob} title="Configure a job" trigger="click">
        <CommonButton type="primary">Configure a job</CommonButton>
      </Popover>
      <Popover
        content={onCancelJob}
        title="Cancel a running job manually"
        trigger="click"
      >
        <CommonButton danger type="primary">
          Cancel a job
        </CommonButton>
      </Popover>
    </Space>
  );

  return (
    <>
      <Space>
        <Popover
          title="Select an action"
          trigger="click"
          content={onActionSelection}
        >
          <h1>Export Digest</h1>
        </Popover>
        <CommonButton
          onClick={onReload}
          disabled={reload}
          style={{ bottom: "2px" }}
        >
          <ReloadOutlined
            title={
              "Refresh rate: " +
              DateHelper.readableDuration(REFRESH_RATE_IN_MS, true)
            }
          />
        </CommonButton>
        <LastUpdated />
        {reload && (
          <div style={{ margin: "0 10px" }}>
            <Spin />
          </div>
        )}
      </Space>
      {!exportDigest && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {exportDigest && (
        <Table
          loading={reload}
          columns={getColumns(exportDigest.taskSummary)}
          dataSource={getData(exportDigest.outcome)}
          size="small"
          pagination={{ pageSize: 20, showSizeChanger: false }}
        />
      )}
    </>
  );
};

export default withRouter(ExportDigest);
