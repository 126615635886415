import React from "react";
import "antd/dist/antd.css";
import { Form, Radio } from "antd";

const options = [
  { label: "Select", value: "select" },
  { label: "Count", value: "count" },
];

export const SelectQueryModeItem = ({ onFormChange, jobStarted, jobOver }) => {
  const onChange = (value) => {
    onFormChange();
  };

  return (
    <Form.Item
      name="queryMode"
      label="Query Mode"
      rules={[
        {
          required: true,
          message: "The 'Query Mode' is required",
        },
      ]}
    >
      <Radio.Group
        defaultValue={options[0].value}
        disabled={jobStarted && !jobOver}
        style={{
          width: 200,
        }}
        options={options}
        onChange={onChange}
      />
    </Form.Item>
  );
};
