import { serviceLocator } from "../services/ServiceLocatorImpl";

export const initializeJob = (payload) => {
  return serviceLocator
    .getApiService()
    .post(
      `${serviceLocator.getGlobalConfiguration().pathBase}${
        serviceLocator.getGlobalConfiguration().dataExportConfig
          .dataExportSubmitEndpoint
      }`,
      payload
    );
};

export const fetchJob = (jobUid) => {
  return serviceLocator
    .getApiService()
    .get(
      `${serviceLocator.getGlobalConfiguration().pathBase}${
        serviceLocator.getGlobalConfiguration().jobConfig.jobListEndpoint
      }/${jobUid}`
    );
};

export const fetchJobResult = (
  queryExecutionId,
  page = serviceLocator.getGlobalConfiguration().dataExportConfig
    .dataExportPreviewResultDefaultPage
) => {
  const { pathBase, dataExportConfig } =
    serviceLocator.getGlobalConfiguration();

  const pageParam = `${dataExportConfig.dataExportPreviewResultParamPage}=${page}`;
  const sizeParam = `${dataExportConfig.dataExportPreviewResultParamSize}=${dataExportConfig.dataExportPreviewResultDefaultSize}`;

  const url = `${pathBase}${dataExportConfig.dataExportPreviewResultEndpoint}/${queryExecutionId}?${pageParam}&${sizeParam}`;

  return serviceLocator.getApiService().get(url);
};
