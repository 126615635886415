import React from "react";
import { Select, Space } from "antd";
import "./CommonFilter.css";

const { Option } = Select;

const CommonFilter = ({ value, options, onChange, children, filterLabel }) => (
  <div className="common-filter">
    <Space>
      <span>{children}</span>
      <span>{filterLabel}</span>
      <Select
        placeholder={`Select a ${filterLabel}`}
        title={`Select a ${filterLabel}`}
        style={{ width: 160 }}
        onChange={onChange}
        value={value}
        allowClear
      >
        <Option key={""} value={""}>
          NONE
        </Option>
        {options.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    </Space>
  </div>
);

export default CommonFilter;
