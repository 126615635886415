import React from "react";
import { Route, Switch } from "react-router-dom";
import ReduxProviderWrapper from "../../app/ReduxProviderWrapper";
import ErrorLoginView from "../login/ErrorLoginView";
import CustomLoginCallback from "../login/CustomLoginCallback";
import { serviceLocator } from "../../services/ServiceLocatorImpl";
import { RoutesConfig } from "../../services/config/GlobalConfiguration";

const routesConfig: RoutesConfig =
  serviceLocator.getGlobalConfiguration().routesConfig;

const Routes: React.FC = () => (
  <Switch>
    <Route path={routesConfig.loginCallback} component={CustomLoginCallback} />
    <Route path={routesConfig.home} component={ReduxProviderWrapper} />
    <Route component={ErrorLoginView} />
  </Switch>
);

export default Routes;
