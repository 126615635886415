const DateHelper = {
  /*
   * Simple Date parser with specific support for Safari that sucks at parsing dates in 2020.
   * ISO 8601 parser which does not handle week format, but it should handle all other valid ISO 8601 dates consistently across all browsers.
   */
  parseDateTime: (value) => {
    const parsed = Date.parse(value);

    // Safari hack for parsing ISO dates
    if (isNaN(parsed)) {
      const field =
        value.match(
          /^([+-]?\d{4}(?!\d\d\b))(?:-?(?:(0[1-9]|1[0-2])(?:-?([12]\d|0[1-9]|3[01]))?)(?:[T\s](?:(?:([01]\d|2[0-3])(?::?([0-5]\d))?|24:?00)([.,]\d+(?!:))?)?(?::?([0-5]\d)(?:[.,](\d+))?)?([zZ]|([+-](?:[01]\d|2[0-3])):?([0-5]\d)?)?)?)?$/
        ) || [];

      const result = new Date(
        field[1],
        (field[2] - 1) | 0,
        field[3] || 1,
        field[4] | 0,
        field[5] | 0,
        field[7] | 0,
        field[8] | 0
      );
      if (field[9])
        result.setUTCMinutes(
          result.getUTCMinutes() -
            result.getTimezoneOffset() -
            (field[10] * 60 + +field[11] || 0)
        );

      return result;
    }

    return parsed;
  },

  /**
   * Format a duration in milliseconds to a human readable format, e.g.
   * "4y 2d 3h 10m 10s 255ms". Negative durations are formatted like "- 8h 30s".
   * Granularity is always ms.
   * (inspired from Didrik Nordström, didrik@betamos.se, https://gist.github.com/betamos/6306412)
   *
   * @param t Duration in ms
   * @param skipMs boolean to skip the ms part
   * @return string, formatted string containing the duration or "" if t=0
   */
  readableDuration: (t, skipMs = false) => {
    // unit objects with its suffix, divisor and padding setup
    const units = [
      { suffix: "ms", divisor: 1, leftPadding: 3 },
      { suffix: "s", divisor: 1000, leftPadding: 2 },
      { suffix: "m", divisor: 60, leftPadding: 2 },
      { suffix: "h", divisor: 60, leftPadding: 2 },
      { suffix: "d", divisor: 24, leftPadding: 2 },
      { suffix: "y", divisor: 365 },
    ];

    const out = [];

    let trunc;
    t = parseInt(t);
    let n = Math.abs(t);

    units.forEach((unit, i) => {
      n = Math.floor(n / unit.divisor);
      trunc = i + 1 < units.length ? n % units[i + 1].divisor : n;
      if ((!skipMs || unit.suffix !== "ms") && trunc)
        out.unshift("" + trunc + units[i].suffix);
    });

    if (t < 0) out.unshift("-");

    return out.join(" ");
  },
};

export default DateHelper;
