import React, { useState, useEffect } from "react";
import { Form, Select } from "antd";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const { Option } = Select;

const fetchClientList = ({ setData, setError }) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.clientListEndpoint}`
    )
    .then((result) => result.data)
    .then(setData)
    .catch(setError);
};

const SelectClientItem = ({
  onFormChange,
  jobStarted,
  jobOver,
  optional = false,
}) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    !(data || error) && fetchClientList({ setData, setError });
  }, [data, error]);

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => {
        return prevValues.eventType !== currentValues.eventType;
      }}
    >
      {({ getFieldValue, setFieldsValue }) => {
        const eventType = getFieldValue("eventType");

        return (
          <Form.Item
            name="client"
            label="Client"
            rules={[
              {
                required: eventType !== "OPPORTUNITY" && !optional,
              },
            ]}
          >
            <Select
              disabled={jobStarted && !jobOver}
              showSearch
              allowClear
              style={{ width: 200 }}
              placeholder="Select a client"
              onChange={onFormChange}
            >
              {data &&
                data.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default SelectClientItem;
