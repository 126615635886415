import { Tag } from "antd";
import React from "react";

const ZoneRollTypeTag = ({ type }) => {
  const style = {
    fontSize: "10px",
    margin: "0 5px",
    padding: "0 1px",
  };

  if (type === "PRE_ROLL")
    return (
      <Tag color="green" style={style}>
        PRE
      </Tag>
    );
  else if (type === "MID_ROLL")
    return (
      <Tag color="gold" style={style}>
        MID
      </Tag>
    );
  else if (type === "POST_ROLL")
    return (
      <Tag color="blue" style={style}>
        POST
      </Tag>
    );
  else if (type === "UNDEFINED")
    return (
      <Tag color="volcano" style={style}>
        UND
      </Tag>
    );
  else
    return (
      <Tag color="red" style={style}>
        UNK
      </Tag>
    );
};

export default ZoneRollTypeTag;
