import React, { useEffect, useState } from "react";
import useInterval from "../../hooks/useInterval";
import { Button, Space } from "antd";
import { serviceLocator } from "../../services/ServiceLocatorImpl";

const PATH_RANDOM_JOB_OVER = "/dev/test/randomJobOver";

const JOB_STATUS_REFRESH_FREQUENCY = 2 * 1000;

const fetchJobStatus = () => {
  return serviceLocator
    .getApiService()
    .get(
      `${
        serviceLocator.getGlobalConfiguration().pathBase
      }${PATH_RANDOM_JOB_OVER}`
    );
};

const PollingTest = () => {
  const [counter, setCounter] = useState(0);
  const [delay, setDelay] = useState(null);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [pollingActive, setPollingActive] = useState(false);

  const onRun = () => {
    setData(undefined);
    resetCounter();
    starPolling();
  };

  const onCancel = () => {
    stopPolling();

    // call termination API endpoint, which returns the updated job payload
  };

  const starPolling = () => {
    setPollingActive(true);
    setDelay(JOB_STATUS_REFRESH_FREQUENCY); // a non null delay value is the triggers for starting the polling
  };

  const stopPolling = () => {
    setDelay(null);
    setPollingActive(false);
  };

  const resetCounter = () => {
    setCounter(0);
  };

  const checkEndPollingReached = (data) => data.over;

  // watch data field modifications, stop polling if poll end-condition validated
  useEffect(() => {
    if (data && checkEndPollingReached(data)) {
      stopPolling();
    }
  }, [data]);

  useInterval(async () => {
    if (delay == null) return;

    setCounter(counter + 1);

    await fetchJobStatus()
      .then((response) => response.data)
      .then(setData)
      .catch(setError);
  }, delay);

  return (
    <>
      <Space>
        <Button htmlType="button" onClick={onRun} disabled={pollingActive}>
          Run
        </Button>
        <Button htmlType="button" onClick={onCancel} disabled={!pollingActive}>
          Cancel
        </Button>
      </Space>
      <div>#ticks: {counter}</div>
      <div>delay: {delay}</div>
      <div>my job status: {JSON.stringify(data)}</div>
      {error ? <div>error: {error}</div> : null}
    </>
  );
};

export default PollingTest;
