import React from "react";
import { Badge } from "antd";
import { useSelector } from "react-redux";
import { selectJobs } from "../../../reducers/txTrackingReducer";

const Report = ({ client, adid, eventType }) => {
  const jobs = useSelector(selectJobs);
  const data = jobs.find(($) => $.eventType === eventType);

  let list =
    data &&
    data.result &&
    data.result
      .filter(($) => $.client === client)
      .filter(($) => $.adid === adid)
      .map(($) => {
        try {
          return Number.parseInt($.playedpercentage);
        } catch (e) {
          return 0; // default, should not happen
        }
      })
      .sort((i1, i2) => i1 - i2);

  return (
    <h5
      style={{
        margin: "10px 0px 10px 0px",
        padding: 0,
      }}
    >
      Quartiles
      {list && (
        <span
          style={{
            margin: "0px 0px 0px 10px",
            padding: 0,
          }}
        >
          {[0, 25, 50, 75, 100].map((step, index) => (
            <Badge
              key={index}
              status={list.includes(step) ? "success" : "default"}
            />
          ))}
        </span>
      )}
    </h5>
  );
};

export default Report;
