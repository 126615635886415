import React from "react";
import { Col, Row, Card } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as OktaLogo } from "../../okta-logo.svg";
import { ReactComponent as LogoSvg } from "../../logo.svg";
import "./Login.css";
import CommonButton from "../common/CommonButton";

// Define the prop types for the Login component
interface LoginProps {
  onClick: () => void; // onClick is a function with no arguments and no return value
}

const Login: React.FC<LoginProps> = ({ onClick }) => (
  <>
    <Row
      justify="center"
      align="top"
      style={{
        borderBottom: "1px solid #EEEEEE",
        padding: "10px 0 10px",
        fontSize: "36px",
      }}
    >
      <Icon component={LogoSvg} />
    </Row>
    <Row
      justify="center"
      align="middle"
      style={{
        minHeight: "80vh",
      }}
    >
      <Col>
        <Card
          title="Hi! I’m your Lobster, Ready to Mine Data with You."
          bordered={true}
          style={{
            width: 400,
            borderRadius: "5px",
          }}
          headStyle={{
            fontSize: "14px",
            fontWeight: "bold",
            textAlign: "center",
            borderBottom: "2px solid #6666c4",
          }}
          bodyStyle={{
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          <p>Sign in with</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CommonButton
              onClick={onClick}
              style={{
                borderRadius: "5px",
                borderColor: "#6666c4",
                borderWidth: "2px",
                borderStyle: "solid",
                width: "150px",
                maxWidth: "100%",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "25px",
              }}
            >
              <OktaLogo
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "100px",
                }}
              />
            </CommonButton>
          </div>
        </Card>
      </Col>
    </Row>
  </>
);

export default Login;
