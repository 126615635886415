import React from "react";
//import { ControlledEditor } from "@monaco-editor/react";
import MonacoEditor from "react-monaco-editor";

const MonacoEditorWrapper = (props) => {
  const { value, onChange, editorDidMount, options, ...restProps } = props;

  const _options = {
    selectOnLineNumbers: true,
    wordWrap: "on",
    scrollBeyondLastLin: false,
    automaticLayout: true,
    ...options,
  };
  return (
    <MonacoEditor
      height="60vh"
      width="62vw"
      language="mysql"
      theme="vs-dark"
      value={value}
      options={_options}
      onChange={onChange}
      editorDidMount={editorDidMount}
      {...restProps}
    />
  );
};

export default MonacoEditorWrapper;
