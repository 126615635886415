import React from "react";
import { Space, Typography } from "antd";
import {
  CheckCircleTwoTone,
  QuestionCircleTwoTone,
  StopTwoTone,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectJobs } from "../../../reducers/txTrackingReducer";

const { Text } = Typography;

const booleanTextToIcon = (text) => {
  if (text === "true") {
    return <CheckCircleTwoTone twoToneColor="#52c41a" title={text} />;
  } else if (text === "false") {
    return <StopTwoTone twoToneColor="orange" title={text} />;
  } else {
    return <QuestionCircleTwoTone twoToneColor="orange" title={text} />;
  }
};

const Click = ({ client, adid }) => {
  const jobs = useSelector(selectJobs);
  const data = jobs.find(($) => $.eventType === "AD_CLICK");

  const list =
    data &&
    data.result &&
    data.result
      .filter(($) => $.client === client)
      .filter(($) => $.adid === adid)
      .map(($) => ({
        client: $.client,
        adid: $.adid,
        adtype: $.adtype,
        companion: $.companion,
        secondscreen: $.secondscreen,
      }));

  return list && list.length > 0 ? (
    <div
      style={{
        margin: "10px 0px 10px 0px",
        padding: 0,
      }}
    >
      <Text>Click</Text>
      <span style={{ margin: "0px 0px 0px 10px", padding: 0 }}>
        <ul>
          {list.map(($) => (
            <li>
              <Space>
                Companion{booleanTextToIcon($.companion)}Second Screen
                {booleanTextToIcon($.secondscreen)}
              </Space>
            </li>
          ))}
        </ul>
      </span>
    </div>
  ) : (
    ""
  );
};

export default Click;
