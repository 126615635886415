import React, { useEffect, useState } from "react";
import { Button, Form, Select, Input, List } from "antd";
import "./SelectFieldListItem.css";
import FieldFormatHelper from "../../../utils/FieldFormatHelper";
import CommonButton from "../../common/CommonButton";
import { CloseOutlined } from "@ant-design/icons";
import ReactDragListView from "react-drag-listview";
import TagListItem from "../../common/TagListItem";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const { Option } = Select;

const fetchEventTypeFieldList = ({ setData, setError }) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.eventTypeFieldListEndpoint}`
    )
    .then((result) => result.data)
    .then(setData)
    .catch(setError);
};

const fetchAudienceSegmentsFieldList = ({
  setAudienceSegmentsData,
  setError,
}) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.audienceSegmentFieldsEndpoint}`
    )
    .then((result) => result.data)
    .then((data) =>
      data.sort((a, b) => a.type.display.localeCompare(b.type.display))
    )
    .then(setAudienceSegmentsData)
    .catch(setError);
};

const fetchEventTypeList = ({ setEventTypesData, setEventTypesError }) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.eventTypeFieldListEndpoint}`
    )
    .then((result) => result.data)
    .then((data) =>
      data.sort((a, b) => a.type.display.localeCompare(b.type.display))
    )
    .then(setEventTypesData)
    .catch(setEventTypesError);
};

export const SelectFieldListItem = ({
  isVisible,
  onFormChange,
  jobStarted,
  jobOver,
  items,
  onItemsChange,
}) => {
  const [data, setData] = useState(undefined);
  const [audienceSegmentsData, setAudienceSegmentsData] = useState(undefined);
  const [error, setError] = useState(undefined);

  const [eventTypesData, setEventTypesData] = useState(undefined);
  const [eventTypesError, setEventTypesError] = useState(undefined);

  useEffect(() => {
    !(data || error) && fetchEventTypeFieldList({ setData, setError });
  }, [data, error]);

  useEffect(() => {
    !(audienceSegmentsData || error) &&
      fetchAudienceSegmentsFieldList({ setAudienceSegmentsData, setError });
  }, [audienceSegmentsData, error]);

  useEffect(() => {
    !(eventTypesData || eventTypesError) &&
      fetchEventTypeList({ setEventTypesData, setEventTypesError });
  }, [eventTypesData, eventTypesError]);

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => {
        return prevValues.eventType !== currentValues.eventType;
      }}
    >
      {({ getFieldValue, setFieldsValue }) => {
        const eventType = getFieldValue("eventType");
        const hasRawEventsFields =
          getFieldValue("datasetType") === "RAW_EVENTS" && eventType && data;
        const hasAudienceSegmentsFields =
          getFieldValue("datasetType") === "AUDIENCE_SEGMENTS" &&
          audienceSegmentsData;

        let fields;
        if (hasRawEventsFields) {
          const eventTypeFieldsObject = data.find(
            (item) => item.id === eventType
          );
          fields = eventTypeFieldsObject?.fields ?? [];
        } else if (hasAudienceSegmentsFields) {
          const globalConfiguration = serviceLocator.getGlobalConfiguration();

          const audienceSegmentsFieldsObject = audienceSegmentsData.find(
            (item) =>
              item.id ===
              globalConfiguration.jobConfig.defaultAudienceSegmentTypeValue
          );
          fields = audienceSegmentsFieldsObject?.fields ?? [];
        } else {
          fields = [];
        }

        fields = fields
          .map((field) => FieldFormatHelper.enrichField(field))
          .sort((o1, o2) => (o1.display > o2.display ? 1 : -1));

        const updateItems = (callback) => {
          if (jobStarted && !jobOver) {
            return;
          }

          onItemsChange((oldItems) => {
            const newValue = callback(oldItems);
            setFieldsValue({ fields: newValue });
            onFormChange();
            return newValue;
          });
        };

        const onClickAll = () => {
          const allSelectableFieldNames = fields
            .filter((field) => field.selectable)
            .map((field) => field.name);

          updateItems(() => allSelectableFieldNames);
        };

        const onRemoveAll = () => {
          updateItems((oldItems) => {
            return [];
          });
        };

        const onChange = (value) => {
          if (items.includes(value)) {
            return;
          }

          updateItems((oldItems) => [...oldItems, value]);
        };

        const onRemoveItem = (item) => {
          updateItems((oldItems) => {
            if (!oldItems.includes(item)) {
              return;
            }
            return oldItems.filter((e) => e !== item);
          });
        };

        const onDragEnd = (fromIndex, toIndex) => {
          updateItems((oldItems) => {
            if (toIndex < 0 || (jobStarted && !jobOver)) return oldItems;

            const newItems = [...oldItems];
            const item = newItems.splice(fromIndex, 1)[0];
            newItems.splice(toIndex, 0, item);
            return newItems;
          });
        };

        return (
          isVisible && (
            <Form.Item label="Fields">
              <Form.Item name="fields" hidden>
                <Select value={items} />
              </Form.Item>
              <Input.Group compact>
                <div className={"fields-container"}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: 200 }}>
                      <Select
                        showSearch
                        disabled={jobStarted && !jobOver}
                        style={{ width: 200 }}
                        placeholder={"Add more fields"}
                        onChange={onChange}
                        filterOption={(input, option) =>
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={null}
                      >
                        {fields &&
                          fields
                            .filter((field) => field.selectable)
                            .map((field) => (
                              <Option
                                key={field.name}
                                value={field.name}
                                title={FieldFormatHelper.formatTitle(field)}
                              >
                                {field.display}
                              </Option>
                            ))}
                      </Select>
                    </div>
                    <Form.Item style={{ width: "10%" }}>
                      <Button
                        disabled={jobStarted && !jobOver}
                        type="link"
                        style={{ width: "100%" }}
                        onClick={onClickAll}
                      >
                        All
                      </Button>
                    </Form.Item>
                    <Form.Item style={{ width: "10%", marginLeft: "5px" }}>
                      <CommonButton
                        disabled={jobStarted && !jobOver}
                        type="text"
                        size="small"
                        onClick={onRemoveAll}
                      >
                        <CloseOutlined />
                      </CommonButton>
                    </Form.Item>
                  </div>
                  <ReactDragListView
                    nodeSelector=".ant-list-item.draggable"
                    onDragEnd={onDragEnd}
                  >
                    <div className={"fields-list"}>
                      <List
                        size="small"
                        dataSource={items}
                        locale={{ emptyText: "No fields selected" }}
                        renderItem={(item) => {
                          return (
                            <TagListItem
                              className={"draggable"}
                              extra={
                                <CommonButton
                                  type="text"
                                  size="small"
                                  onClick={() => onRemoveItem(item)}
                                >
                                  <CloseOutlined />
                                </CommonButton>
                              }
                            >
                              {FieldFormatHelper.convertNameToDisplayName(item)}
                            </TagListItem>
                          );
                        }}
                      />
                    </div>
                  </ReactDragListView>
                </div>
              </Input.Group>
            </Form.Item>
          )
        );
      }}
    </Form.Item>
  );
};
