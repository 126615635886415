import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Button, Form, Space } from "antd";
import moment from "moment";
import SelectClientItem from "./SelectClientItem";
import { SelectEventTypeItem } from "./SelectEventTypeItem";
import { SelectQueryModeItem } from "./SelectQueryModeItem";
import { SelectFieldListItem } from "./SelectFieldListItem";
import { SelectDateRangeItem } from "./SelectDateRangeItem";
import { SelectFiltersItem } from "./SelectFiltersItem";
import { SelectLimitItem } from "./SelectLimitItem";
import { SelectDatasetTypeItem } from "./SelectDatasetTypeItem";
import useStateWithLocalStorage from "../../../hooks/useStateWithLocalStorage";
import { refreshJob } from "../../../reducers/queryBuilderReducer";
import { useDispatch } from "react-redux";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 2,
    span: 16,
  },
};

//TODO move to helper
const toString = (object) => {
  return object && JSON.stringify(object, null, " ");
};

//TODO move to const
const initFormValues = {
  datasetType: "RAW_EVENTS",
  limit: 1000,
  queryMode: "select",
};

const DataExportForm = ({
  handlePreview,
  handleRequest,
  jobStarted,
  jobOver,
  handleCancel,
  handleFormChange,
}) => {
  const [form] = Form.useForm();

  const [formFieldsValue, storeFormFieldsValue] = useStateWithLocalStorage(
    "reportExport.formFieldsValue"
  );
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  // Filter null and undefined properties
  const clean = (obj) =>
    Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);

  const initForm = () => {
    // go to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // unmarshall stored object
    try {
      const parsedStoredForm = JSON.parse(formFieldsValue);

      clean(parsedStoredForm);

      if (parsedStoredForm.dateRange) {
        const dateRangeValueArray = parsedStoredForm.dateRange;
        dateRangeValueArray[0] = moment(dateRangeValueArray[0]).utc(false);
        dateRangeValueArray[1] = moment(dateRangeValueArray[1]).utc(false);
      }

      // Reset Opp event type value to its new value, for backward compatibility with queries from history (20201021) & (20210202)
      if (
        parsedStoredForm.eventType === "AD_OPPORTUNITY" ||
        parsedStoredForm.eventType === "OPPORTUNITY_V3"
      )
        parsedStoredForm.eventType = "OPPORTUNITY";

      form.setFieldsValue(parsedStoredForm);

      setItems(parsedStoredForm?.fields ?? []);

      //TEST code to investigate why all the form is not filled when using setFieldsValue
      //             if (parsedStoredForm.fields) {
      //                 console.log('in init, stored:' + JSON.stringify(parsedStoredForm));
      //             }
      //             //form.setFieldsValue(parsedStoredForm);
      //             //form.setFieldsValue({...parsedStoredForm});
      // //           form.setFieldsValue({...{fields:{value:parsedStoredForm.fields}}})
      //             //form.setFields([{'eventType':'AD_IMPRESSION'}]);
      //             form.setFieldsValue({'eventType':'AD_IMPRESSION'});
      //             form.setFieldsValue({'client':'pandora'});
      //             //form.setFields([ {'name':['fields'],'value':['ip','clickable']} ]);
      //             //form.setFieldsValue({fields:['clickable']} );
      //             form.setFieldsValue({['fields']:['ip','requestid','clickable']} );
      //
      //             const test = form.getFieldsValue();
      //             console.log('in init:' + JSON.stringify(test));
      //
      //             handleRequestParams();
      //end test code
    } catch (ignore) {}
  };

  const saveForm = () => {
    //console.log('save form in localStorage!');
    storeFormFieldsValue(JSON.stringify(form.getFieldsValue()));
  };

  // init form from local storage
  // eslint-disable-next-line
  useEffect(() => initForm(), []);

  const [requestParams, setRequestParams] = useState(toString(initFormValues));

  const handleRequestParams = () => {
    setRequestParams(toString(form.getFieldsValue()));

    handlePreview(form.getFieldsValue());
  };

  const onFinish = async (values) => {
    handleRequestParams();
    handleRequest(values);
    setTimeout(() => {
      dispatch(refreshJob());
    }, 2000);
  };

  const onCancel = () => {
    handleCancel();
  };

  // callback for items
  const onFormChange = () => {
    handleRequestParams();

    handleFormChange();

    saveForm();
  };

  const updateForm = () => {
    handleRequestParams();

    handleFormChange();

    saveForm();
  };

  const onReset = () => {
    form.resetFields();

    updateForm();
  };

  const onDatasetTypeChange = () => {
    const resetValues = {
      client: null,
      eventType: null,
      fields: null,
      filters: null,
    };

    setItems([]);

    form.setFieldsValue({ ...resetValues });

    updateForm();
  };

  const onRawEventTypeChange = () => {
    const resetValues = {
      fields: null,
      filters: null,
    };

    setItems([]);

    form.setFieldsValue({ ...resetValues });

    updateForm();
  };

  const onAutoFill = () => {
    const dummyData = {
      queryMode: "select",
      client: "pandora",
      eventType: "AD_IMPRESSION",
      fields: ["_datetime", "timestamp", "id", "requestid", "ip"],
      dateRange: [moment().utc().startOf("day"), moment().utc().endOf("day")],
      limit: 10,
    };

    form.setFieldsValue({ ...dummyData });

    updateForm();
  };

  const showFiltersAndFieldListItems =
    form.getFieldsValue().eventType ||
    form.getFieldsValue().datasetType === "AUDIENCE_SEGMENTS";

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="dataExportForm"
        onFinish={onFinish}
        initialValues={initFormValues}
      >
        <SelectDatasetTypeItem
          onChange={onFormChange}
          jobStarted={jobStarted}
          jobOver={jobOver}
          onReset={onDatasetTypeChange}
        />
        {form.getFieldsValue().datasetType !== "AUDIENCE_SEGMENTS" && (
          <>
            <SelectEventTypeItem
              onFormChange={onFormChange}
              jobStarted={jobStarted}
              jobOver={jobOver}
              onReset={onRawEventTypeChange}
            />
            <SelectClientItem
              onFormChange={onFormChange}
              jobStarted={jobStarted}
              jobOver={jobOver}
            />
          </>
        )}
        <SelectFieldListItem
          onFormChange={onFormChange}
          jobStarted={jobStarted}
          jobOver={jobOver}
          items={items}
          onItemsChange={setItems}
          isVisible={showFiltersAndFieldListItems}
        />
        <SelectFiltersItem
          onFormChange={onFormChange}
          jobStarted={jobStarted}
          jobOver={jobOver}
          isVisible={showFiltersAndFieldListItems}
        />
        <SelectQueryModeItem
          onFormChange={onFormChange}
          jobStarted={jobStarted}
          jobOver={jobOver}
        />
        <SelectDateRangeItem
          onFormChange={onFormChange}
          jobStarted={jobStarted}
          jobOver={jobOver}
        />
        <SelectLimitItem
          onFormChange={onFormChange}
          jobStarted={jobStarted}
          jobOver={jobOver}
        />
        <Form.Item {...tailLayout}>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              hidden={jobStarted && !jobOver}
            >
              Run
            </Button>
            <Button
              htmlType="button"
              onClick={onReset}
              hidden={jobStarted && !jobOver}
            >
              Reset
            </Button>
            <Button
              danger
              type="primary"
              onClick={onCancel}
              hidden={!jobStarted || jobOver}
            >
              Cancel
            </Button>
            <Button
              type="link"
              onClick={onAutoFill}
              hidden={true || (jobStarted && !jobOver)}
            >
              autofill with dummy data
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {false && <div>debug data: {JSON.stringify(form.getFieldsValue())}</div>}
      {false && <div>debug form: {requestParams}</div>}
    </>
  );
};

export default DataExportForm;
