import { ServiceLocator } from "./ServiceLocator";
import { GlobalConfiguration } from "./config/GlobalConfiguration";
import { GlobalConfigurationImpl } from "./config/impl/GlobalConfigurationImpl";
import { AuthService } from "./auth/AuthService";
import { OktaAuthServiceImpl } from "./auth/impl/OktaAuthServiceImpl";
import { OktaAuth, OktaAuthOptions } from "@okta/okta-auth-js";
import { ApiService } from "./api/ApiService";
import { ApiServiceImpl } from "./api/ApiServiceImpl";
import { LobsterApiService } from "./api/LobsterApiService";
import { LobsterApiServiceImpl } from "./api/LobsterApiServiceImpl";
import { DummyAuthServiceImpl } from "./auth/impl/DummyAuthServiceImpl";

/**
 * Implements the ServiceLocator interface and manages the actual singleton instances.
 *
 * Services are accessed through the ServiceLocator interface, promoting loose coupling and enhancing testability.
 * Using the interface allows easy replacement with mock implementations for testing, ensuring that the code can be
 * tested independently of the actual service implementations.
 */
class ServiceLocatorImpl implements ServiceLocator {
  private static globalConfigInstance: GlobalConfiguration;
  //  private static authInstance: OktaAuth | undefined;
  private static authServiceInstance: AuthService;
  private static apiServiceInstance: ApiService;
  private static lobsterApiServiceInstance: LobsterApiService;

  /**
   * Returns the singleton instance of GlobalConfiguration.
   * Initializes it if it hasn't been created yet.
   * @returns {GlobalConfiguration} The singleton instance.
   */
  public getGlobalConfiguration(): GlobalConfiguration {
    if (!ServiceLocatorImpl.globalConfigInstance) {
      ServiceLocatorImpl.globalConfigInstance = new GlobalConfigurationImpl();
    }
    return ServiceLocatorImpl.globalConfigInstance;
  }

  /**
   * Returns the singleton instance of AuthService.
   * Initializes it if it hasn't been created yet.
   * Ensures that OktaAuth and GlobalConfiguration are initialized first.
   * @returns {AuthService} The singleton instance.
   */
  public getAuthService(): AuthService {
    if (!ServiceLocatorImpl.authServiceInstance) {
      const globalConfig = this.getGlobalConfiguration();
      const oktaAuthOptions: Readonly<OktaAuthOptions> = {
        clientId: globalConfig.oktaConfig.clientId,
        issuer: globalConfig.oktaConfig.issuer,
        redirectUri: `${window.location.origin}/login/callback`, //TODO make it configurable
        scopes: ["openid", "profile", "email"], //TODO make it configurable
        pkce: true, //TODO make it configurable
      };

      // Check configuration validity only in non-test environments
      if (
        process.env.NODE_ENV !== "test" &&
        (!oktaAuthOptions.clientId || !oktaAuthOptions.issuer)
      ) {
        throw new Error(
          "Okta configuration is invalid: Client ID and Issuer are required."
        );
      }

      // Check if authentication is enabled and choose the correct implementation
      if (globalConfig.oktaConfig.authEnabled) {
        //        ServiceLocatorImpl.authInstance = new OktaAuth(oktaAuthOptions);
        ServiceLocatorImpl.authServiceInstance = new OktaAuthServiceImpl(
          //          ServiceLocatorImpl.authInstance,
          new OktaAuth(oktaAuthOptions),
          globalConfig
        );
      } else {
        //        ServiceLocatorImpl.authInstance = undefined;
        ServiceLocatorImpl.authServiceInstance = new DummyAuthServiceImpl();
      }
    }

    return ServiceLocatorImpl.authServiceInstance;
  }

  public getApiService(): ApiService {
    if (!ServiceLocatorImpl.apiServiceInstance) {
      ServiceLocatorImpl.apiServiceInstance = new ApiServiceImpl();
    }
    return ServiceLocatorImpl.apiServiceInstance;
  }

  public getLobsterApiService(): LobsterApiService {
    if (!ServiceLocatorImpl.lobsterApiServiceInstance) {
      ServiceLocatorImpl.lobsterApiServiceInstance =
        new LobsterApiServiceImpl();
    }
    return ServiceLocatorImpl.lobsterApiServiceInstance;
  }
}

//TODO use ServiceLocatorContext that exposes ServiceLocatorImpl & serviceLocator
// Export a singleton instance of ServiceLocatorImpl that implements ServiceLocator
const serviceLocator: ServiceLocator = new ServiceLocatorImpl();
export { ServiceLocatorImpl, serviceLocator };
