/**
 * User model class that defines the structure of a user object.
 */
export class User {
  firstName?: string;
  lastName?: string;
  userName?: string; // User's full name
  id?: string; // User's unique identifier (e.g., subject ID)
  email?: string;
  preferred_username?: string;

  /**
   * Constructs a User object.
   * @param init - Partial initialization object to set properties of the User.
   */
  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}
