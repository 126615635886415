import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Card, Progress, Space, Tag } from "antd";
import DateHelper from "../../utils/DateHelper";
import {
  StarOutlined,
  LoadingOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import CommonButton from "../common/CommonButton";
import ResultSampleTable from "./ResultSampleTable";
import lobsterApiService from "../../services/lobsterApiService";
import AthenaCostHelper from "../../utils/AthenaCostHelper";

const formatter = new Intl.NumberFormat("en", {
  style: "decimal",
  unit: "byte",
});

const jobStates = ["RUNNING", "FAILED", "SUCCEEDED", "TERMINATED", "ERROR"];
const colorList = ["#ffb83d", "#f54a04", "#24aa05", "#1e1e1e", "#f54a04"];

const ResultPane = ({
  job,
  result,
  resultPage,
  hasMoreResults,
  resultErrorMessage,
  isResultLoading,
  loadMoreResults,
  handleAnalyzeTransactionId,
}) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [numberOfLines, setNumberOfLines] = useState(0);

  const isOngoingJobState = (job) =>
    job &&
    !(
      job.state === "TERMINATED" ||
      job.state === "FAILED" ||
      job.state === "SUCCEEDED" ||
      job.state === "ERROR"
    );

  // watcher for elapsedTime evaluation
  useEffect(() => {
    job &&
      job.submittedDate &&
      setElapsedTime(
        new Date().getTime() - DateHelper.parseDateTime(job.submittedDate)
      );
  }, [job]);

  const fileLink = (item) => lobsterApiService.buildExportFileLink(item);

  const handleMeanRowLength = (mrl) => {
    if (
      job &&
      job.dataExportResponse &&
      job.dataExportResponse.map &&
      job.dataExportResponse.map.totalFileSize > 0
    ) {
      const totalFileSize =
        job.dataExportResponse.map && job.dataExportResponse.map.totalFileSize;
      const estimatedLines = Math.round(totalFileSize / mrl);
      setNumberOfLines(estimatedLines);
    }
  };

  return (
    <Card size="small" title="Result">
      {job && (
        <>
          <Space style={{ margin: "10px 0px 10px 0px" }}>
            {isOngoingJobState(job) ? <LoadingOutlined /> : <StarOutlined />}
            <Tag color={colorList[jobStates.indexOf(job.state) || 0]}>
              {job.state}
            </Tag>
          </Space>
          <div style={{ margin: "10px 0 10px 0" }}>
            <Progress
              showInfo={false}
              percent={(elapsedTime / 1000 / 300) * 100}
            />
          </div>
          <div style={{ margin: "10px 0 10px 0" }}>
            Estimated Time Elapsed:{" "}
            {DateHelper.readableDuration(elapsedTime, true)}
          </div>
        </>
      )}
      {job &&
        job.dataExportResponse &&
        job.dataExportResponse.map &&
        "totalFileSize" in job.dataExportResponse.map &&
        !job.dataExportResponse.failed && (
          <>
            <Space>
              <div>
                The cost of your query is{" "}
                {AthenaCostHelper.formatCostForDisplay(
                  job.dataExportResponse.map.queryExecutionResult.statistics
                    .dataScannedInBytes
                )}
                .
              </div>
              <div>
                The size of your report is{" "}
                {formatter.format(job.dataExportResponse.map.totalFileSize)}{" "}
                bytes, with {formatter.format(numberOfLines)} estimated line(s).
              </div>
              {job.dataExportResponse.map.queryExecutionResult
                .queryExecutionId &&
                job.dataExportResponse.map.totalFileSize > 0 && (
                  <div>
                    You can export it in CSV:
                    <CommonButton href={fileLink(job)}>
                      <DownloadOutlined />
                    </CommonButton>
                  </div>
                )}
            </Space>
            <ResultSampleTable
              result={result}
              resultPage={resultPage}
              hasMoreResults={hasMoreResults}
              resultErrorMessage={resultErrorMessage}
              isResultLoading={isResultLoading}
              handleMeanRowLength={handleMeanRowLength}
              handleAnalyzeTransactionId={handleAnalyzeTransactionId}
              handleMoreClick={loadMoreResults}
            />
          </>
        )}
      {job && job.dataExportResponse && job.dataExportResponse.failed && (
        <Space>
          <Tag icon={<CloseCircleOutlined />} color="error">
            SQL Error!
          </Tag>
          <div>
            {job.dataExportResponse.map.queryExecutionResult.errorMessage}
          </div>
        </Space>
      )}
    </Card>
  );
};

export default ResultPane;
