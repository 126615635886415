import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import FieldFormatHelper from "../../../utils/FieldFormatHelper";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const fetchEventTypeFieldSampleList = ({ setData, setError }) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.eventTypeFieldSampleListEndpoint}`
    )
    .then((result) => result.data)
    .then(setData)
    .catch(setError);
};

const fetchAudienceSegmentsFieldSampleList = ({
  setAudienceSegmentsData,
  setError,
}) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.audienceSegmentsSamplesEndpoint}`
    )
    .then((result) => result.data)
    .then(setAudienceSegmentsData)
    .catch(setError);
};

const PreviewPane = ({ values }) => {
  const [data, setData] = useState(undefined);
  const [audienceSegmentsData, setAudienceSegmentsData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    !(data || error) && fetchEventTypeFieldSampleList({ setData, setError });
  }, [data, error]);

  useEffect(() => {
    !(audienceSegmentsData || error) &&
      fetchAudienceSegmentsFieldSampleList({
        setAudienceSegmentsData,
        setError,
      });
  }, [audienceSegmentsData, error]);

  //tmp code to investigate the partial filled form issue
  //     useEffect(() => {
  //         //TODO tmp line
  //         console.log('values : ' + JSON.stringify(values));
  //     }, [values]);

  const columns =
    values &&
    values.fields &&
    values.fields.map((obj) => ({
      title: FieldFormatHelper.convertNameToDisplayName(obj),
      dataIndex: obj,
      key: obj,
      ellipsis: false,
      width: 100,
    }));

  const isSelectedFields = values && values.fields && values.fields.length > 0;

  const dataSource = [];

  let samples;

  if (
    data &&
    values &&
    values.eventType &&
    values.datasetType === "RAW_EVENTS"
  ) {
    samples =
      data.find((obj) => obj.id === values.eventType)?.fieldToSamples ?? [];
  } else if (
    audienceSegmentsData &&
    values &&
    values.datasetType === "AUDIENCE_SEGMENTS"
  ) {
    const globalConfiguration = serviceLocator.getGlobalConfiguration();

    samples =
      audienceSegmentsData.find(
        (obj) =>
          obj.id ===
          globalConfiguration.jobConfig.defaultAudienceSegmentTypeValue
      )?.fieldToSamples ?? [];
  }

  let cntr = 0;

  if (isSelectedFields && samples && values) {
    samples[Object.keys(samples)[0]] // pick one field to identify sample size
      .forEach((ignore, index) => {
        let sample = {};
        sample["key"] = sample["id"] || cntr++; //TODO quick hack for having a unique id per row
        values.fields.forEach((item) => {
          sample[item] = samples[item] && samples[item][index];

          // resize column width
          const columnRef = columns.find((obj) => obj.key === item);
          const itemLength = sample[item] ? sample[item].length : 1;
          const adaptativeSizingWeight = (length) => {
            // empirical, can be improved...
            const weight = length < 250 ? (length < 10 ? 14 : 10) : 8;
            return weight * length;
          };
          columnRef.width = Math.max(
            columnRef.width,
            adaptativeSizingWeight(columnRef.title.length),
            adaptativeSizingWeight(itemLength)
          );
        });
        dataSource.push(sample);
      });
  }

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{
          y: 400,
          x: 400,
        }}
        bordered
        title={() => (
          <div style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500" }}>
            Preview
          </div>
        )}
      />
    </>
  );
};

export default PreviewPane;
