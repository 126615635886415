import React from "react";
import useSafeAsync from "../../../hooks/useSafeAsync";
import { useDispatch, useSelector } from "react-redux";
import {
  loadMoreJobResults,
  selectQueryState,
  setJob,
  setJobOver,
  setJobStarted,
  setPollingStarted,
  startJob,
} from "../../../reducers/queryEditorReducer";
import Toaster from "../../../utils/Toaster";
import { Col, Row } from "antd";
import QueryEditorForm from "./QueryEditorForm";
import ResultPane from "../ResultPane";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const QueryEditor = () => {
  const {
    job,
    jobStarted,
    pollingStarted,
    jobOver,
    result,
    resultPage,
    hasMoreResults,
    resultErrorMessage,
    isResultLoading,
  } = useSelector(selectQueryState);

  const safeAsync = useSafeAsync();
  const dispatch = useDispatch();

  //callback
  const handleCancel = () => {
    const jobId = job && job.uid;

    if (jobId === null) return;

    dispatch(setJobOver(true));

    Toaster.notify("warning", "Report request cancelled");
    // call cancel api for job uid
    const globalConfiguration = serviceLocator.getGlobalConfiguration();

    const { promise } = safeAsync(
      serviceLocator
        .getApiService()
        .delete(
          `${globalConfiguration.pathBase}${globalConfiguration.dataExportConfig.dataExportCancelEndpoint}/${jobId}`
        )
    );

    promise
      .then((result) => result.data)
      .then((data) => {
        dispatch(setJob(data));
      })
      .catch((error) => {
        Toaster.notify("error", error.message);
      });
  };
  const handleFormChange = () => {
    dispatch(setJob(null));
    dispatch(setJobStarted(false));
    dispatch(setJobOver(false));
  };

  return (
    <div className="QueryEditor">
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <strong>Set Up Your SQL Query</strong>
          </Col>
          <Col span={0} />
        </Row>

        <QueryEditorForm
          handleRequest={(values) => {
            dispatch(startJob(values));
          }}
          jobStarted={jobStarted}
          jobOver={jobOver}
          handleCancel={handleCancel}
          handleFormChange={handleFormChange}
        />

        {jobStarted && (
          <ResultPane
            job={job}
            pollingStarted={pollingStarted}
            result={result}
            resultPage={resultPage}
            hasMoreResults={hasMoreResults}
            resultErrorMessage={resultErrorMessage}
            isResultLoading={isResultLoading}
            onPollingDone={() => {
              dispatch(setJobOver(true));
            }}
            setPollingStarted={(isStarted) =>
              dispatch(setPollingStarted(isStarted))
            }
            loadMoreResults={() => dispatch(loadMoreJobResults())}
            handleForcedCancel={() => {
              dispatch(setJobOver(true));
            }}
          />
        )}

        {false && result && <p>async call resp: {result}</p>}
        {false && (
          <Row gutter={[16, 16]}>
            <Col span={1}>
              <span>debug:</span>
            </Col>
            <Col span={23}>
              <span>{JSON.stringify(job, null, " ")}</span>
            </Col>
          </Row>
        )}
      </>
    </div>
  );
};

export default QueryEditor;
