import { Button, Form, Space } from "antd";
import { useEffect, useState } from "react";

const tailLayout = {
  wrapperCol: {
    offset: 1,
    span: 16,
  },
};

const ActionFormItem = ({
  form,
  onFormValuesChange,
  onReset,
  isAnyJobRunning = false,
  onCancel,
}) => {
  const [showCancelButton, setShowCancelButton] = useState(false);

  useEffect(() => {
    if (isAnyJobRunning) {
      setTimeout(() => {
        setShowCancelButton(true);
      }, 1000);
    } else {
      setShowCancelButton(false);
    }
  }, [isAnyJobRunning]);

  const _onReset = () => {
    form.resetFields();
    onFormValuesChange({});
    onReset();
  };

  return (
    <Form.Item {...tailLayout}>
      <Space>
        <Button type="primary" htmlType="submit" hidden={isAnyJobRunning}>
          Go!
        </Button>
        <Button htmlType="button" onClick={_onReset} hidden={isAnyJobRunning}>
          Reset
        </Button>
        <Button
          danger
          type="primary"
          onClick={onCancel}
          hidden={!showCancelButton}
        >
          Cancel
        </Button>
      </Space>
    </Form.Item>
  );
};

export default ActionFormItem;
