import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DataExport from "../query/export/DataExport";
import QueryEditor from "../query/build/QueryEditor";
import TxTracking from "../txtracking/TxTracking";
import History from "../jobs/History";
import JobList from "../jobs/JobList";
import AsyncTest from "../test/AsyncTest";
import AsyncTestFunct from "../test/AsyncTestFunct";
import PollingTest from "../test/PollingTest";
import MountingTest from "../test/MountingTest";
import ExportDigest from "../exportdigest/ExportDigest";
import NotFound from "../common/NotFound";

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/export" />} />
      <Route path="/export" component={DataExport} />
      <Route path="/txTracking" component={TxTracking} />
      <Route path="/queryEditor" component={QueryEditor} />
      <Route path="/history" component={History} />
      <Route path="/jobList" component={JobList} />
      <Route path="/exportDigest" component={ExportDigest} />
      <Route path="/asyncTest" component={AsyncTest} />
      <Route path="/asyncTestFunct" component={AsyncTestFunct} />
      <Route path="/pollingTest" component={PollingTest} />
      <Route path="/mountingTest" component={MountingTest} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
