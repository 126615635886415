import React, { useState } from "react";
import useSafeAsync from "../../../hooks/useSafeAsync";
import Toaster from "../../../utils/Toaster";
import moment from "moment";
import { Col, Row } from "antd";
import DataExportForm from "./DataExportForm";
import ResultPane from "../ResultPane";
import PreviewPane from "./PreviewPane";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadMoreJobResults,
  selectQueryState,
  setJob,
  setJobOver,
  setJobStarted,
  startJob,
} from "../../../reducers/queryBuilderReducer";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const DataExport = ({ history }) => {
  const [values, setValues] = useState(null);

  const {
    job,
    jobStarted,
    jobOver,
    result,
    resultPage,
    hasMoreResults,
    resultErrorMessage,
    isResultLoading,
  } = useSelector(selectQueryState);

  const safeAsync = useSafeAsync();
  const dispatch = useDispatch();

  //callback
  const handleCancel = () => {
    const jobId = job && job.uid;

    if (jobId === null) return;

    dispatch(setJobOver(true));

    Toaster.notify("warning", "Report request cancelled");
    // call cancel api for job uid
    const globalConfiguration = serviceLocator.getGlobalConfiguration();

    const { promise } = safeAsync(
      serviceLocator
        .getApiService()
        .delete(
          `${globalConfiguration.pathBase}${globalConfiguration.dataExportConfig.dataExportCancelEndpoint}/${jobId}`
        )
    );

    promise
      .then((result) => result.data)
      .then((data) => {
        dispatch(setJob(data));
      })
      .catch((error) => {
        Toaster.notify("error", error.message);
      });
  };

  const handleFormChange = () => {
    dispatch(setJob(null));
    dispatch(setJobStarted(false));
    dispatch(setJobOver(false));
  };

  const handleAnalyzeTransactionId = (transactionId) => {
    // from "dateRange":["2021-10-13T00:00:00.000Z","2021-10-13T23:59:59.999Z"] -> compute delta as 'floor(interval in days/2)' and ref date as 'start date + delta'
    let delta;
    let midDate;
    if (values) {
      const _startDate = moment(values.dateRange[0]).utc(true);
      const _endDate = moment(values.dateRange[1]).utc(true);
      delta = Math.ceil(_endDate.diff(_startDate, "days") / 2);
      midDate = _startDate.add(delta, "days").toISOString();
    } else {
      const localStorageFormValues = JSON.parse(
        localStorage.getItem("transactionTracker.formFieldsValue")
      );
      delta = localStorageFormValues["delta"];
      midDate = localStorageFormValues["date"];
    }

    const data = {
      // TODO use a TRANSACTION_TRACKER_PROTOTYPE instead
      transactionId: transactionId,
      date: midDate,
      delta: delta,
    };
    // persist the items in the saved data object stored in the localStorage
    localStorage.setItem(
      "transactionTracker.formFieldsValue",
      JSON.stringify(data)
    ); // did it this way, as cannot use a hook // TODO define a key

    // navigate to the Report builder view
    history.push("/txTracking"); // TODO define a key
  };

  return (
    <div className="DataExport">
      <>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <strong>Define Your Query</strong>
          </Col>
          <Col span={20} />
        </Row>

        <DataExportForm
          handlePreview={setValues}
          handleRequest={(formValues) => {
            dispatch(startJob(formValues));
          }}
          jobStarted={jobStarted}
          jobOver={jobOver}
          handleCancel={handleCancel}
          handleFormChange={handleFormChange}
        />

        {jobStarted && (
          <ResultPane
            job={job}
            result={result}
            resultPage={resultPage}
            hasMoreResults={hasMoreResults}
            resultErrorMessage={resultErrorMessage}
            isResultLoading={isResultLoading}
            loadMoreResults={() => dispatch(loadMoreJobResults())}
            handleAnalyzeTransactionId={handleAnalyzeTransactionId}
          />
        )}
        {!jobStarted && <PreviewPane values={values} />}

        {false && job && <p>async call resp: {job}</p>}
        {false && (
          <Row gutter={[16, 16]}>
            <Col span={1}>
              <span>debug:</span>
            </Col>
            <Col span={23}>
              <span>{JSON.stringify(job, null, " ")}</span>
            </Col>
          </Row>
        )}
      </>
    </div>
  );
};

export default withRouter(DataExport);
