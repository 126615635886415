import React from "react";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import { selectJobs } from "../../../reducers/txTrackingReducer";

const { Paragraph } = Typography;

const ThirdPartyTracking = ({ client, adid, zoneid }) => {
  const jobs = useSelector(selectJobs);
  const data = jobs.find(($) => $.eventType === "THIRD_PARTY_TRACKING");

  const list =
    data &&
    data.result &&
    data.result
      .filter(($) => $.client === client)
      .filter(($) => $.adid === adid)
      .filter(($) => $.zoneid === zoneid)
      .map(($) => ({
        thirdpartyurl: $.thirdpartyurl,
      }));

  return list && list.length > 0 ? (
    <div>
      <h5
        style={{
          margin: "10px 0px 10px 0px",
          padding: 0,
        }}
      >
        Third Party URL(s)
      </h5>
      <ul>
        {list.map(($, index) => (
          <li key={index}>
            <Paragraph
              ellipsis={{ rows: 1, expandable: true, symbol: "more" }}
              copyable={true}
              code={true}
            >
              {$.thirdpartyurl}
            </Paragraph>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    ""
  );
};

export default ThirdPartyTracking;
