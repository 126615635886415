const takeBefore = (str, discriminant) => {
  const pos = str.indexOf(discriminant);
  return pos === -1 ? str : str.substring(0, pos);
};

const takeAfter = (str, discriminant) => {
  let pos = str.indexOf(discriminant);
  if (pos === -1) return "";
  else {
    pos = pos + discriminant.length;
    return str.substring(pos);
  }
};

const removeLastChar = (str) => {
  return str.length === 0 ? str : str.substring(0, str.length - 1);
};

const removeFirstChar = (str) => {
  return str.length === 0 ? str : str.substring(1);
};

const ObjectHelper = {
  /**
   * Checks if a variable is a JavaScript object and nothing else (i.e. not an Array, Set...)
   * Inspired from https://medium.com/javascript-in-plain-english/javascript-check-if-a-variable-is-an-object-and-nothing-else-not-an-array-a-set-etc-a3987ea08fd7
   *
   * @param variable
   * @returns {boolean}
   */
  isObject: (variable) => {
    return Object.prototype.toString.call(variable) === "[object Object]";
  },

  //* take {} blocks in []
  // * stream KVs
  //   * '=V' -> just reach the next ','
  //   * '=[' -> just reach the next '],'
  //
  // input sample: '[{status=WON, url=adswizz://podwave?zoneId=544, categories=[IAB3-13, IAB3-13 - Cable Companies], dealids=[]}]'
  // output sample: '[{"status":"WON", "url":"adswizz://podwave?zoneId=544", "categories":"[IAB3-13, IAB3-13 - Cable Companies]", "dealids":"[]"}]'
  // (edge cases: [] or [{}] or null)
  convertPseudoObjectToJsonText: (pseudoObject) => {
    if (!pseudoObject || pseudoObject === "[]" || pseudoObject === "[{}]")
      return pseudoObject;

    // strip []
    let tmp = removeLastChar(removeFirstChar(pseudoObject));
    // build list of stringified objects
    const list = [];
    while (tmp.length > 0) {
      const block = removeFirstChar(takeBefore(tmp, "}")); // {a},{b} -> a
      list.push(block);
      tmp = takeAfter(tmp, "}"); // {a},{b} -> ,{b} OR {a} -> ''
      if (tmp.length > 0) tmp = removeFirstChar(tmp).trim(); // removing any comma, and trim possible spaces after the comma
    }

    for (let i = 0; i < list.length; i++) {
      let block = list[i];
      const lis = [];
      // convert 'a=b, c=d' to '"a":"b", "c":d"'
      while (block.length > 0) {
        const key = takeBefore(block, "=");
        const remaining = takeAfter(block, "=");
        let value;
        if (remaining[0] === "[") {
          value = remaining.includes("], ")
            ? takeBefore(remaining, "], ") + "]"
            : remaining;
          block = takeAfter(remaining, "], ");
        } else {
          value = takeBefore(remaining, ", ");
          block = takeAfter(remaining, ", ");
        }
        lis.push('"' + key + '":' + (value ? '"' + value + '"' : null));
      }

      list[i] = "{".concat(lis.join(",")).concat("}");
    }

    return "[".concat(list.join(",")).concat("]");
  },

  // * wrap top keys with double quotes, and replace its assignment symbol '=' by ':'
  //
  // input sample: '{NO_BID_BLOCKED_ADV_CATEGORY=[{"abc": 1}], NO_BID_BELOW_FLOOR_PRICE=[{"def": 2}]}'
  // output sample: '{"NO_BID_BLOCKED_ADV_CATEGORY":[{"abc": 1}], "NO_BID_BELOW_FLOOR_PRICE":[{"def": 2}]}'
  // (edge cases: {} or null)
  convertPseudoObjectType2ToJsonText: (pseudoObject) => {
    if (!pseudoObject || pseudoObject === "{}") return pseudoObject;

    return pseudoObject
      .replace(/^{/g, '{"') // start of first item name
      .replace(/}], /g, '}], "') // start of intermediary item names
      .replace(/=\[{/g, '":[{'); // end of item names
  },
};

export default ObjectHelper;
