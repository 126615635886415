import {
  OktaConfig,
  RoutesConfig,
  ReferenceDataPaths,
  JobConfig,
  DataExportPaths,
  TaskConfig,
  TemplateConfig,
  GlobalConfiguration,
} from "../GlobalConfiguration";

/**
 * This class implements the GlobalConfiguration interface, providing concrete
 * configuration values for the application. It initializes various configurations
 * using environment variables and default values, ensuring that different parts
 * of the application have consistent and centralized access to necessary settings.
 */
class GlobalConfigurationImpl implements GlobalConfiguration {
  readonly oktaConfig: OktaConfig;
  readonly routesConfig: RoutesConfig;
  readonly advancedUserEmails: string;
  readonly pathBase: string | undefined;
  readonly referenceDataConfig: ReferenceDataPaths;
  readonly jobConfig: JobConfig;
  readonly dataExportConfig: DataExportPaths;
  readonly taskConfig: TaskConfig;
  readonly templateConfig: TemplateConfig;

  constructor() {
    this.oktaConfig = {
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID || "",
      issuer: process.env.REACT_APP_OKTA_ISSUER || "",
      authEnabled: process.env.REACT_APP_AUTH_ENABLED !== "false",
    };

    this.routesConfig = {
      loginCallback: "/login/callback",
      home: "/",
    };

    this.advancedUserEmails = process.env.REACT_APP_ADVANCED_USER_EMAILS || "";

    this.pathBase = process.env.REACT_APP_PATH_BASE;

    this.referenceDataConfig = {
      clientListEndpoint: "/referenceData/clientNames",
      datasetTypesEndpoint: "/referenceData/datasetTypes",
      eventTypeListEndpoint:
        "/referenceData/datasetTypes/RAW_EVENTS/eventTypes",
      eventTypeFieldListEndpoint:
        "/referenceData/datasetTypes/RAW_EVENTS/eventTypes/fields",
      eventTypeFieldSampleListEndpoint:
        "/referenceData/datasetTypes/RAW_EVENTS/eventTypes/fields/samples",
      audienceSegmentTypesEndpoint:
        "/referenceData/datasetTypes/AUDIENCE_SEGMENTS/audienceSegmentTypes",
      audienceSegmentFieldsEndpoint:
        "/referenceData/datasetTypes/AUDIENCE_SEGMENTS/audienceSegmentTypes/fields",
      audienceSegmentsSamplesEndpoint:
        "/referenceData/datasetTypes/AUDIENCE_SEGMENTS/audienceSegmentTypes/fields/samples",
    };

    this.jobConfig = {
      jobListEndpoint: "/jobs",
      defaultJobType: "",
      paramJobState: "state",
      defaultJobState: "",
      paramJobTypes: "types",
      defaultHistoryJobTypes:
        "BuiltQueryDataExportJob,PlaintextQueryDataExportJob",
      paramDirection: "direction",
      defaultDirection: "DESC",
      paramPage: "page",
      defaultPage: 0,
      paramSize: "size",
      defaultSize: 50,
      defaultAudienceSegmentTypeValue: "AUDIENCE",
    };

    this.dataExportConfig = {
      dataExportSubmitEndpoint: "/jobs/dataExport",
      plaintextDataExportSubmitEndpoint: "/jobs/plaintextDataExport",
      rawDataExportSubmitEndpoint: "/jobs/rawDataExport",
      dataExportCancelEndpoint: "/jobs/terminate", // append /{id} dynamically
      dataExportDownloadEndpoint: "/query/query-execution/stream/id", // append /{id} dynamically
      dataExportPreviewResultEndpoint: "/query/query-execution/content/id", // append /{id} dynamically
      dataExportPreviewResultParamPage: "page",
      dataExportPreviewResultDefaultPage: 0,
      dataExportPreviewResultParamSize: "size",
      dataExportPreviewResultDefaultSize: 1000,
      dataExportDigestEndpoint: "/jobs/rawExportDigest", // with ?days={days}
    };

    this.taskConfig = {
      taskListEndpoint: "/tasks",
    };

    this.templateConfig = {
      audiomaxScClUrlTemplate:
        "https://audiomax.adswizz.com/%client%/#/revenue/salesChannels/%saleschannelid%/deals/%contractlineid%/settings",
      audioserveCaAdUrlTemplate:
        "https://audioserve.adswizz.com/%client%/#/campaigns/%campaignid%/ads/%adid%/settings",
    };
  }
}

export { GlobalConfigurationImpl };
