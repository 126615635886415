import React, { useEffect, useMemo, useState } from "react";
import { Divider, Empty, Input, Spin, Tooltip } from "antd";
import ReactJson from "react-json-view";
import { InfoCircleOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import "./DetailsDebugTabPane.css";
import { useSelector } from "react-redux";
import { selectJobs } from "../../../reducers/txTrackingReducer";
import CenteredEmpty from "../../common/CenteredEmpty";
import SpinContainer from "../../common/SpinContainer";

const hasResult = (jobs) => jobs.some((j) => j.result && j.result.length > 0);

const removeEmpty = (obj) => {
  if (!obj) {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null && v !== "")
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
};

/**
 * Returns the object with its content filtered according to the searchFilter.
 * If the content is empty after the filtering, null will be returned.
 *
 * @param obj
 * @param searchFilter
 * @returns {boolean|*}
 */
const filterObject = (obj, searchFilter) => {
  if (obj === undefined || obj === null) {
    return null;
  }
  if (typeof obj === "string") {
    return obj.includes(searchFilter) ? obj : null;
  }

  const filteredObject = Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        const filteredKey = filterObject(key, searchFilter);
        const filteredValue = filterObject(value, searchFilter);

        if (filteredKey === null && filteredValue === null) {
          return null;
        }

        return [filteredKey ?? key, filteredValue ?? value];
      })
      .filter((entry) => entry !== null)
  );

  return Object.keys(filteredObject).length > 0 ? filteredObject : null;
};

const applySearchFilters = (job, searchFilter) => {
  return {
    ...job,
    result:
      searchFilter === "" ? job.result : filterObject(job.result, searchFilter),
  };
};

const jobToDebugSection = (job) => ({
  key: job.eventType,
  title: job.title,
  src: job.result,
});

const DetailsDebugTabPane = (props) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [debugSections, setDebugSections] = useState([]);

  const jobs = useSelector(selectJobs);

  const searchHandler = (e) => {
    setSearchFilter(e.target.value);
  };

  const debouncedSearchHandler = useMemo(
    () => debounce(searchHandler, 150),
    []
  );

  const hasContent = hasResult(jobs);

  useEffect(() => {
    const sections = [...jobs]
      .map((job) => ({
        eventType: job.eventType,
        title: job.title,
        result: job.result,
      }))
      .sort((a, b) => a.eventType.localeCompare(b.eventType))
      .map((job) => ({ ...job, result: removeEmpty(job.result) }))
      .map((job) => applySearchFilters(job, searchFilter))
      .filter((job) => !!job.result && Object.keys(job.result).length > 0)
      .map(jobToDebugSection);

    setDebugSections(sections);
  }, [jobs, searchFilter]);

  let sections = debugSections.map((section) => (
    <div key={section.key}>
      <Divider orientation="left">{section.title}</Divider>
      <ReactJson
        name={false}
        displayObjectSize
        displayDataTypes={false}
        enableClipboard
        collapsed={searchFilter.length < 3 ? 1 : 1}
        collapseStringsAfterLength={40}
        src={section.src}
      />
    </div>
  ));

  return (
    <>
      {props.isLoading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        !hasContent && <CenteredEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      {hasContent && (
        <div>
          <Input
            style={{
              width: "300px",
              margin: "15px 0 30px 0",
            }}
            size="small"
            placeholder="Filter..."
            allowClear
            suffix={
              <Tooltip title="Enter a word to filter out the JSON">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            }
            onChange={debouncedSearchHandler}
          />
          <div className={"details-container"}>
            {sections.length > 0
              ? sections
              : hasContent && (
                  <CenteredEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
          </div>
        </div>
      )}
    </>
  );
};

export default DetailsDebugTabPane;
