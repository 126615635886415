import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

export const SelectDatasetTypeItem = ({
  jobStarted,
  jobOver,
  onChange,
  onReset,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const globalConfiguration = serviceLocator.getGlobalConfiguration();

    serviceLocator
      .getApiService()
      .get(
        `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.datasetTypesEndpoint}`
      )
      .then((result) => result.data)
      .then(setData)
      .catch(setError);
  }, []);

  const onDatasetChange = () => {
    onReset();
    onChange();
  };

  return (
    <Form.Item
      name="datasetType"
      label="Dataset Type"
      rules={[
        {
          required: true,
          message: "'Dataset Type' is required",
        },
      ]}
    >
      <Select
        disabled={jobStarted && !jobOver}
        showSearch
        allowClear
        style={{
          width: 200,
        }}
        placeholder="Select a Dataset Type"
        onChange={onDatasetChange}
      >
        {data &&
          !error &&
          data.map((item) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.type.display}
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};
