import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { LogoutOutlined, StarFilled } from "@ant-design/icons";
import Toaster from "../../utils/Toaster";
import CornerLoader from "../../utils/CornerLoader";
import { serviceLocator } from "../../services/ServiceLocatorImpl";

const UserMenu = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdvancedUser, setIsAdvancedUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await serviceLocator.getAuthService().getCurrentUser();
        setCurrentUser(user);

        const advancedUser = await serviceLocator.getAuthService().isAdvUser();
        setIsAdvancedUser(advancedUser);
      } catch (error) {
        console.warn("Failed to fetch user or check advanced status:", error);
        Toaster.notify("warning", "Error loading user information");
      } finally {
        setLoading(false); // Ensure loading is set to false in both success and failure cases
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return CornerLoader;
  }

  if (!currentUser) {
    return null; // or any fallback UI if needed
  }

  const handleLogout = async () => {
    Toaster.notify("info", "Logging out...");

    try {
      await serviceLocator.getAuthService().logout();
    } catch (error) {
      Toaster.notify(
        "error",
        "An error occurred while logging out. Please try again."
      );
    }
  };

  return (
    <ul id="menu">
      <li
        title={
          isAdvancedUser ? "Advanced user mode granted" : "Default user mode"
        }
      >
        Hi {currentUser.firstName} {currentUser.lastName}
        {isAdvancedUser && (
          <StarFilled
            style={{ color: "gold", fontSize: "12px", marginLeft: "4px" }}
          />
        )}
      </li>
      <li>
        <Button type="link" onClick={handleLogout}>
          <LogoutOutlined title="Sign out" />
        </Button>
      </li>
    </ul>
  );
};

export default UserMenu;
