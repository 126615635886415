import TreeItem from "./TreeItem";
import React from "react";

const OpportunityTreeItem = ({ event }) => (
  <TreeItem
    count={event.nodes ? event.nodes.length : 0}
    clientName={event.client}
    zoneId={event.zoneid}
  />
);

export default OpportunityTreeItem;
