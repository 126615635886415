import React from "react";
import { Alert, Card, Col, Row } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as LogoSvg } from "../../logo.svg";
import Error from "./Error";
import { ErrorProps } from "../../types/errorProps";

const ErrorLoginView: React.FC<ErrorProps> = ({ errorMessage, retryLogin }) => {
  return (
    <>
      <Row
        justify="center"
        align="top"
        style={{
          borderBottom: "1px solid #EEEEEE",
          padding: "10px 0 10px",
          fontSize: "36px",
        }}
      >
        <Icon component={LogoSvg} />
      </Row>
      <Row
        justify="center"
        align="middle"
        style={{
          minHeight: "80vh",
          backgroundColor: "#f0f2f5",
        }}
      >
        <Col>
          <Card
            style={{
              width: 400,
              borderRadius: "5px",
              textAlign: "center",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Alert
              message="Authentication Error"
              description={
                errorMessage ||
                "An unexpected error occurred during authentication."
              }
              type="error"
              showIcon
              style={{ marginBottom: "20px" }}
            />
            <Error errorMessage={errorMessage} retryLogin={retryLogin} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ErrorLoginView;
