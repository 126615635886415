import TreeItem from "./TreeItem";
import React from "react";

const BidsTreeItem = ({ event }) => (
  <TreeItem
    count={event.nodes ? event.nodes.length : 0}
    clientName={event.client}
    shouldHaveSecondaryBadgeCount={event.bids && event.bids.length > 0}
    secondaryBadgeCount={`${event.bids ? event.bids.length : 0} bid responses`}
    shouldHaveCheck={
      event.bids && event.bids.filter((bid) => bid.status === "WON").length > 0
    }
    zoneId={event.zoneid}
  />
);

export default BidsTreeItem;
