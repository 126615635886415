import React from "react";
import {
  Badge,
  Card,
  Empty,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
  Tree,
} from "antd";
import {
  ExclamationCircleTwoTone,
  QuestionCircleOutlined,
  WarningTwoTone,
} from "@ant-design/icons";

import "./JobPane.css";
import { withRouter } from "react-router-dom";
import { TreeTitle } from "./TreeTitle";
import CenteredEmpty from "../common/CenteredEmpty";

const displayJobOutcome = (job) => {
  switch (job.data.state) {
    case "TERMINATED":
      return <Tag color={"black"}>Canceled</Tag>;
    case "FAILED":
      return <WarningTwoTone twoToneColor="red" />;
    case "SUCCEEDED":
      return;
    default:
      return <ExclamationCircleTwoTone />; // should not happen
  }
};

const JobPane = withRouter(
  ({ job, history, isTrailMode, onTrailModeToggle }) => {
    const isOngoingJobState = (job) =>
      job &&
      job.data &&
      !(
        job.data.state === "TERMINATED" ||
        job.data.state === "FAILED" ||
        job.data.state === "SUCCEEDED" ||
        job.data.state === "ERROR"
      );

    const loading = isOngoingJobState(job);

    const nodes = isTrailMode ? job.trailTreeData : job.eventsTreeData;
    return (
      <Card
        title={
          <>
            <Space>
              {job.title}
              {job.eventType === "BIDS" && (
                <Tooltip
                  title={
                    "The absence of bids could be due to the downsampling of BIDS events"
                  }
                >
                  <QuestionCircleOutlined style={{ marginLeft: 3 }} />
                </Tooltip>
              )}
              {loading && <Spin size="small" />}
              {!loading && job && job.data && displayJobOutcome(job)}
              {job && job.result && (
                <Space>
                  <Badge
                    count={`${job.result.length} ${
                      job.result.length === 1 ? "event" : "events"
                    }`}
                    style={{ backgroundColor: "#1890FF" }}
                  />
                </Space>
              )}
            </Space>
          </>
        }
        extra={
          ["OPPORTUNITY", "ZONE_REQUEST", "BIDS"].includes(job.eventType) && (
            <Switch
              checkedChildren="trail"
              unCheckedChildren="events"
              checked={isTrailMode}
              disabled={loading || !job.result}
              onChange={onTrailModeToggle}
            />
          )
        }
      >
        {!loading && job && job.data && job.data.state === "SUCCEEDED" ? (
          nodes && nodes.length > 0 ? (
            <Tree
              key={`${job.eventType}.${nodes.length}.${isTrailMode}`}
              showIcon={false}
              showLine={{ showLeafIcon: false }}
              switcherIcon={<span className="ant-tree-switcher-leaf-line" />}
              defaultExpandAll
              treeData={nodes}
              titleRender={(node) => <TreeTitle node={node} job={job} />}
            />
          ) : (
            <CenteredEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        ) : null}

        {false && job.extraTreeData && job.extraTreeData.length > 0 ? (
          <div className={"extraDataTree"}>
            <span>
              The following events are not found in the corresponding
              Opportunity trail events:
            </span>
            <Tree
              key={`${job.eventType}.${job.extraTreeData.length}`}
              showIcon={false}
              showLine={{ showLeafIcon: false }}
              switcherIcon={<span className="ant-tree-switcher-leaf-line" />}
              defaultExpandAll
              treeData={job.extraTreeData}
              titleRender={(node) => (
                <TreeTitle node={node} job={job} badgeColor="#808080" />
              )}
            />
          </div>
        ) : null}

        {(loading ||
          (job && !job.data) ||
          (job && job.data && job.data.state === "TERMINATED")) && (
          <CenteredEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {job && job.resultError && (
          <div>{"Error! reason: " + JSON.stringify(job.resultError)}</div>
        )}
      </Card>
    );
  }
);

export default JobPane;
