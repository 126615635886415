import { createSlice } from "@reduxjs/toolkit";
import { txTrackingJobs } from "../contexts/TxTrackingContext";
import lobsterApiService from "../services/lobsterApiService";
import Toaster from "../utils/Toaster";
import { fetchJobResult, fetchJob, initializeJob } from "../api/jobs";
import moment from "moment";

const JOB_STATUS_REFRESH_FREQUENCY = 5 * 1000;

const getJobExecutionId = (data) =>
  data?.dataExportResponse?.map?.queryExecutionResult?.queryExecutionId;

const isJobRunning = (job) => {
  return job.state === "QUEUED" || job.state === "RUNNING";
};

export const txTrackingSlice = createSlice({
  name: "txTracking",
  initialState: {
    jobs: txTrackingJobs,
    jobsCancelling: false,
  },
  reducers: {
    setJobs: (state, action) => {
      state.jobs = action.payload;
    },
    setJob: (state, action) => {
      const { job, data, result, resultError, extraData = {} } = action.payload;

      const targetIndex = state.jobs.findIndex((innerJob) => {
        return innerJob.eventType === job.eventType;
      });

      const targetJob = state.jobs[targetIndex];

      state.jobs[targetIndex] = {
        ...targetJob,
        data: data || targetJob.data,
        result: result || targetJob.result,
        resultError: resultError || targetJob.resultError,
        ...extraData,
      };
    },
    setJobAsCanceled: (state, action) => {
      const jobToCancel = state.jobs.find((job) => {
        return job.data.uid === action.payload.data.uid;
      });

      jobToCancel.data = action.payload.data;
      jobToCancel.result = jobToCancel.resultError = undefined;
    },
  },
});

export const { setJobs, setJob, setJobAsCanceled } = txTrackingSlice.actions;

export const cancelJobs = () => async (dispatch, getState) => {
  const runningJobs = selectRunningJobs(getState());

  /* Only cancel if there are running jobs. */
  if (runningJobs.length === 0) {
    return;
  }

  const jobsCount = `${runningJobs.length} ${
    runningJobs.length === 1 ? "job" : "jobs"
  }`;

  Toaster.notify("info", `Cancelling ${jobsCount}`);

  /* Wait for all the individual job cancellation promises to finish. */
  await Promise.all(
    runningJobs.map(async (job) => {
      try {
        const response = await lobsterApiService.terminateJob(job.data.uid);

        // Assuming response is an Axios-like response
        if (response.status === 200) {
          const data = response.data; // Axios responses have a 'data' property
          dispatch(setJobAsCanceled({ data })); // Use the parsed JSON data
        } else {
          // Handle non-200 responses
          Toaster.notify(
            "error",
            `Failed to cancel job: ${response.statusText}`
          );
        }
      } catch (error) {
        // Handle any other errors (e.g., network errors)
        Toaster.notify(
          "error",
          `Error occurred while canceling job: ${error.message}`
        );
      }
    })
  );

  Toaster.notify("success", `Cancelled ${jobsCount}`);
};

export const fetchResult = (jobEventType, id) => async (dispatch, getState) => {
  if (!id) {
    return;
  }

  const job = selectJobByType(jobEventType)(getState());

  fetchJobResult(id)
    .then((result) => result.data)
    .then((data) =>
      dispatch(
        setJob({
          job: job,
          result: data,
        })
      )
    )
    .catch((error) =>
      dispatch(
        setJob({
          job: job,
          resultError: error,
        })
      )
    );
};

export const refreshJob = (jobEventType) => async (dispatch, getState) => {
  const job = selectJobByType(jobEventType)(getState());

  if (job && job.data) {
    await fetchJob(job.data.uid)
      .then((response) => response.data)
      .then((data) => {
        const newJob = {
          job: job,
          data: data,
        };
        dispatch(setJob(newJob));

        if (data && data.state === "SUCCEEDED") {
          const oldId = getJobExecutionId(job.data);
          const newId = getJobExecutionId(data);

          if (oldId !== newId) {
            dispatch(fetchResult(job.eventType, newId));
          }
        }
        if (isJobRunning(data)) {
          setTimeout(() => {
            dispatch(refreshJob(jobEventType));
          }, JOB_STATUS_REFRESH_FREQUENCY);
        }
      })
      .catch((error) => {
        Toaster.notify("error", error.message);

        dispatch(
          setJob({
            job: job,
            data: {
              ...job.data,
              state: "ERROR",
            },
            resultError: error,
          })
        );
      });
  }
};

export const startJobs = (requestParams) => async (dispatch, getState) => {
  const { jobs } = getState().txTracking;

  jobs.forEach((job) => {
    dispatch(startJob(job, requestParams));
  });
};

export const startJob = (job, requestParams) => async (dispatch) => {
  if (requestParams && requestParams.transactionId) {
    const [startDay, stopDay] = buildDateRange(
      requestParams.date,
      requestParams.delta
    );

    const values = {
      eventType: job.eventType,
      client: requestParams.client,
      queryMode: "select",
      dateRange: [startDay.toISOString(), stopDay.toISOString()], //["2021-09-14T00:00:00.000Z","2021-09-14T23:59:59.999Z"],
      limit: "1000",
      fields: [], //"_datetime", "client", "timestamp"], // no useragent for zone_request
      filters: [
        {
          field: job.transactionIdName,
          operator: "EQUALS",
          value: requestParams.transactionId,
        },
      ],
      nestedMode: job.nestedMode,
    };

    initializeJob(values)
      .then((result) => {
        const newJob = {
          job: job,
          data: result.data,
        };

        dispatch(setJob(newJob));
        setTimeout(() => {
          dispatch(refreshJob(job.eventType));
        }, 1000);
      })
      .catch((error) => {
        Toaster.notify("error", error.message);
        return false;
      });
  }
};

const buildDateRange = (date, delta) => {
  const _date = moment(date).utc(true).startOf("day");

  const startDay = _date.clone().subtract(delta, "days");

  const stopDay = _date
    .clone()
    .add(delta, "days")
    .add(1, "days")
    .subtract(1, "milliseconds"); // move to end of the day

  return [startDay, stopDay];
};

/* Selectors */
export const selectJobs = (state) => state.txTracking.jobs;
export const selectRunningJobs = (state) => {
  return selectJobs(state).filter(
    (job) =>
      job.data && (job.data.state === "QUEUED" || job.data.state === "RUNNING")
  );
};
export const selectIsAnyJobRunning = (state) => {
  return selectRunningJobs(state).length > 0;
};

export const selectJobByType = (type) => (state) =>
  state.txTracking.jobs.find((job) => job?.eventType === type);

export default txTrackingSlice.reducer;
