import React, { useEffect } from "react";
import CommonButton from "../common/CommonButton";
import { ErrorProps } from "../../types/errorProps";

const Error: React.FC<ErrorProps> = ({ errorMessage, retryLogin }) => {
  const timeout = 10000;

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("Cannot login. Go to home page after " + timeout + " ms");
      window.location.href = "/"; // Use `href` instead of direct assignment
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout]);

  return (
    <div style={{ margin: "5px" }}>
      <p>{errorMessage}</p>
      <p>
        A fresh authentication token is required! You are going to be redirected
        to Lobster home in a few seconds.
      </p>
      <CommonButton onClick={retryLogin}>Retry Login</CommonButton>
      <CommonButton href="/">Go to Home</CommonButton>
    </div>
  );
};

export default Error;
