import React, { useState, useEffect } from "react";
import _ from "lodash";
import { List, Tabs } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import TxTrackingForm from "./TxTrackingForm";
import TxTrackingTitle from "./TxTrackingTitle";
import { txTrackingJobs } from "../../contexts/TxTrackingContext";
import JobPane from "./JobPane";
import DetailsDebugTabPane from "./tabpane/DetailsDebugTabPane";
import TimelineTabPane from "./tabpane/TimelineTabPane";
import DetailsTabPane from "./tabpane/DetailsTabPane";
import AdDataTabPane from "./tabpane/AdDataTabPane";
import AdDataDetailsTabPane from "./tabpane/AdDataDetailsTabPane";
import BidsDataTabPane from "./bids/BidsDataTabPane";
import OpportunityModel from "./bids/model/OpportunityModel";
import {
  selectJobs,
  setJobs,
  setJob,
  cancelJobs,
  selectIsAnyJobRunning,
  startJobs,
} from "../../reducers/txTrackingReducer";
import "./TxTracking.css";

const { TabPane } = Tabs;

const TxTrackingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CustomTabPane = styled(TabPane)`
  display: flex;
  flex-direction: column;
`;

const grid = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 3,
};

const TxTracking = () => {
  const [lastSubmitTimestamp, setLastSubmitTimestamp] = useState(0);

  const dispatch = useDispatch();

  const jobs = useSelector(selectJobs);
  const isAnyJobRunning = useSelector(selectIsAnyJobRunning);

  const [jobTrailModeMap, setJobTrailModeMap] = useState({
    OPPORTUNITY: true,
    ZONE_REQUEST: jobs.length > 0,
    BIDS: jobs.length > 0,
  });

  const oppJob = jobs.find((job) => job.eventType === "OPPORTUNITY");
  const zoneRequestJob = jobs.find((job) => job.eventType === "ZONE_REQUEST");
  const bidsJob = jobs.find((job) => job.eventType === "BIDS");

  const onSubmit = (values) => {
    dispatch(setJobs(txTrackingJobs));
    setLastSubmitTimestamp(Date.now());
    dispatch(startJobs(values));
  };

  const onReset = () => {
    dispatch(setJobs(txTrackingJobs));
  };

  useEffect(() => {
    if (isJobReady(oppJob)) {
      buildTrailTree(oppJob);
    }
  }, [oppJob]);

  useEffect(() => {
    if (
      isJobSucceeded(oppJob) &&
      isJobReady(zoneRequestJob) &&
      zoneRequestJob.eventsParsed
    ) {
      buildTrailTree(zoneRequestJob);
    }

    if (
      isJobSucceeded(oppJob) &&
      isJobSucceeded(zoneRequestJob) &&
      !zoneRequestJob.extraTreeData &&
      zoneRequestJob.eventsParsed
    ) {
      let allEvents = [];
      zoneRequestJob.result.forEach((zoneRequest) => {
        allEvents = [...allEvents, ...zoneRequest.detailsList];
      });

      const extraData = allEvents.filter((zoneEvent) => {
        return !oppJob.result.find(
          (oppEvent) =>
            oppEvent.client === zoneEvent.client &&
            oppEvent.zoneid === zoneEvent.zoneid
        );
      });

      dispatch(
        setJob({
          job: zoneRequestJob,
          extraData: {
            extraTreeData: buildEventsTreeData(extraData, "EXTRADATA"),
          },
        })
      );
    }
  }, [oppJob, zoneRequestJob]);

  useEffect(() => {
    if (
      isJobSucceeded(zoneRequestJob) &&
      zoneRequestJob.result.length > 0 &&
      !zoneRequestJob.eventsParsed
    ) {
      let newResult = zoneRequestJob.result.map((zoneRequest) => ({
        ...zoneRequest,
        detailsList: OpportunityModel.parseZoneRequestDetailsList(
          zoneRequest.zonerequestdetailslist
        ),
      }));

      dispatch(
        setJob({
          job: zoneRequestJob,
          result: newResult,
          extraData: {
            eventsParsed: true,
          },
        })
      );
    }
  }, [zoneRequestJob]);

  useEffect(() => {
    if (
      isJobSucceeded(oppJob) &&
      isJobReady(bidsJob) &&
      bidsJob.result.length > 0
    ) {
      buildTrailTree(bidsJob);
    }
  }, [oppJob, bidsJob]);

  useEffect(() => {
    // Generate events tree data for all jobs when they finish.
    jobs
      .filter((job) => isJobReady(job, false) && job.result.length > 0)
      .forEach((job) => {
        dispatch(
          setJob({
            job: job,
            extraData: {
              eventsTreeData: buildEventsTreeData(job.result, job.eventType),
            },
          })
        );
      });
  }, [jobs]);

  const buildTrailTree = (job) => {
    let filterEvents = (event) => true;

    let originalOppsModel;
    if (job.eventType === "BIDS") {
      filterEvents = (event) => {
        return event.bids && event.bids.length > 0;
      };

      const [oppsModel] = OpportunityModel.populateModel(
        oppJob.result,
        job.result
      );
      originalOppsModel = oppsModel;
    } else {
      originalOppsModel = OpportunityModel.toOppsModel(oppJob.result);
    }

    // skip root model part, so you just get an array of nodes
    let oppsModel = [];
    originalOppsModel.forEach(($) => {
      oppsModel = oppsModel.concat($.nodes);
    });

    const traverseOpportunityEvents = (
      events,
      topNodeIndex = -1,
      top = true
    ) => {
      return events.filter(filterEvents).map((event) => {
        if (top) topNodeIndex++;

        const treeNode = {};
        treeNode.key = topNodeIndex + "-" + event.metanodepath;
        treeNode.event = event;
        treeNode.type = "TRAIL";

        if (event.nodes && event.nodes.length > 0) {
          // if not a leaf
          treeNode.children = traverseOpportunityEvents(
            event.nodes,
            topNodeIndex,
            false
          );
        }

        return treeNode;
      });
    };

    const treeData = traverseOpportunityEvents(oppsModel);

    dispatch(
      setJob({
        job: job,
        extraData: {
          trailTreeData: treeData,
        },
      })
    );

    setJobTrailModeMap((prevJobTrailModeMap) => ({
      ...prevJobTrailModeMap,
      [job.eventType]: true,
    }));
  };

  const isJobSucceeded = (job) => {
    return job && job.data && job.data.state === "SUCCEEDED" && !!job.result;
  };

  const isJobReady = (job, isTrailMode = true) => {
    if (!job) return false;

    const existingTreeData = isTrailMode
      ? job.trailTreeData
      : job.eventsTreeData;

    return isJobSucceeded(job) && !existingTreeData;
  };

  const buildEventsTreeData = (events) => {
    const groupsMap = events.reduce((groupsMap, event) => {
      let group = groupsMap[event.client];

      if (!group) {
        group = {
          client: event.client,
          transactionType: event.transactiontype,
          adId: event.adid,
          zoneid: [],
          events: [],
        };
      }

      return {
        ...groupsMap,
        [event.client]: {
          ...group,
          zoneid: [...group.zoneid, event.zoneid],
          events: [...group.events, event],
          transactionType: group.transactionType,
          adId: group.adId,
        },
      };
    }, {});

    const groups = _.sortBy(Object.values(groupsMap), "client").map(
      (group) => ({
        group,
        type: "EVENTS",
        key: group.client,
      })
    );

    return [
      {
        type: "EVENTS",
        key: "top",
        eventsCount: events.length,
        children: groups,
      },
    ];
  };

  return (
    <TxTrackingContainer className="transaction-tracking">
      <TxTrackingTitle />

      <TxTrackingForm
        onSubmit={onSubmit}
        onReset={onReset}
        onCancel={() => dispatch(cancelJobs())}
        isAnyJobRunning={isAnyJobRunning}
      />

      <Tabs defaultActiveKey="1" key={lastSubmitTimestamp}>
        <CustomTabPane tab="Summary" key="1">
          <>
            <List
              style={{ marginTop: 15 }}
              grid={grid}
              dataSource={jobs}
              renderItem={(item) => (
                <List.Item>
                  <JobPane
                    key={item.eventType}
                    job={item}
                    isTrailMode={!!jobTrailModeMap[item.eventType]}
                    onTrailModeToggle={() => {
                      setJobTrailModeMap((prevJobTrailModeMap) => ({
                        ...prevJobTrailModeMap,
                        [item.eventType]: !prevJobTrailModeMap[item.eventType],
                      }));
                    }}
                  />
                </List.Item>
              )}
            />
          </>
        </CustomTabPane>

        <CustomTabPane tab="Ads Data" key="3">
          <AdDataTabPane isLoading={isAnyJobRunning} />
        </CustomTabPane>

        <CustomTabPane tab="Bids Data" key="4">
          <BidsDataTabPane isLoading={isAnyJobRunning} />
        </CustomTabPane>

        {false && (
          <CustomTabPane tab="Ads Data Details" key="5">
            <AdDataDetailsTabPane />
          </CustomTabPane>
        )}

        <CustomTabPane tab="Timeline" key="6">
          <TimelineTabPane isLoading={isAnyJobRunning} />
        </CustomTabPane>

        {false && (
          <CustomTabPane tab="xDetails" key="7">
            <DetailsTabPane />
          </CustomTabPane>
        )}

        <CustomTabPane tab="Details" key="8">
          <DetailsDebugTabPane isLoading={isAnyJobRunning} />
        </CustomTabPane>
      </Tabs>
    </TxTrackingContainer>
  );
};

export default TxTracking;
