import OpportunityTreeItem from "./treeitem/OpportunityTreeItem";
import ZoneRequestTreeItem from "./treeitem/ZoneRequestTreeItem";
import BidsTreeItem from "./treeitem/BidsTreeItem";
import { Badge, Popover, Space } from "antd";
import React from "react";

export const TreeTitle = ({ node, job, badgeColor = "#1890FF" }) => {
  if (node.type === "TRAIL")
    switch (job.eventType) {
      case "OPPORTUNITY":
        return <OpportunityTreeItem job={job} event={node.event} />;
      case "ZONE_REQUEST":
        return <ZoneRequestTreeItem job={job} event={node.event} />;
      case "BIDS":
        return <BidsTreeItem job={job} event={node.event} />;
      default:
        throw new Error("TitleComponent value not supported");
    }

  if (node.group) {
    const { group } = node;

    return (
      <Space>
        <Badge
          count={group.events.length}
          style={{ backgroundColor: badgeColor }}
        />
        {job.eventType &&
        (job.eventType === "AD_IMPRESSION" ||
          job.eventType === "CONTRACT_IMPRESSION") ? (
          <Popover
            title="Additional Information"
            content={
              <div>
                <div>Zone IDs: {group.zoneid.join(", ")}</div>
                <div>Transaction Type: {group.transactionType}</div>
                <div>AD ID: {group.adId}</div>
              </div>
            }
            trigger="click"
          >
            <div>{group.client}</div>
          </Popover>
        ) : (
          <Popover
            title="Additional Information"
            content={<div>Zone IDs: {group.zoneid.join(", ")}</div>}
            trigger="click"
          >
            <div>{group.client}</div>
          </Popover>
        )}
      </Space>
    );
  } else {
    return (
      <Badge
        count={node.eventsCount}
        style={{ backgroundColor: badgeColor }}
        overflowCount={999}
      />
    );
  }
};
