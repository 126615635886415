const FieldFormatHelper = {
  enrichField: (field) => {
    return (
      field &&
      field.name && {
        ...field,
        display: FieldFormatHelper.convertNameToDisplayName(field.name),
      }
    );
  },

  convertNameToDisplayName: (name) => {
    switch (name) {
      case "_datetime":
      case "timestamp_human": // TODO obsolete since 20211015
        return "timestamp (Human)";
      case "timestamp":
        return "timestamp (UNIX)";
      default:
        return name;
    }
  },

  formatTitle: (field) =>
    `schema info:\n  type: ${FieldFormatHelper.formatType(
      field
    )}\n  name: ${FieldFormatHelper.formatName(field)}`,

  formatType: (field) => (field.name.startsWith("_") ? "NA" : field.type),

  formatName: (field) => (field.name.startsWith("_") ? "NA" : field.name),
};

export default FieldFormatHelper;
