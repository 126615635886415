import React from "react";
import { Security } from "@okta/okta-react";
import Routes from "../components/routes/Routes";
import { serviceLocator } from "../services/ServiceLocatorImpl";
import { AuthService } from "../services/auth/AuthService";

const AppWithSecurity: React.FC = () => {
  const authService: AuthService = serviceLocator.getAuthService();
  const authEnabled =
    serviceLocator.getGlobalConfiguration().oktaConfig.authEnabled;

  // If authentication is enabled, wrap with Security; otherwise, render Routes directly
  const authInstance = authService.getAuthInstance();
  return authEnabled && authInstance ? (
    <Security
      oktaAuth={authInstance}
      restoreOriginalUri={async (_oktaAuth, originalUri) =>
        window.location.replace(originalUri || "/")
      }
    >
      <Routes />
    </Security>
  ) : (
    <Routes />
  );
};

export default AppWithSecurity;
