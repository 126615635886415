import React, { useState } from "react";
import { DatePicker, Form } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

export const SelectDateRangeItem = ({ onFormChange, jobStarted, jobOver }) => {
  function onChange(dates, dateStrings) {
    onFormChange();
  }

  const [dates, setDates] = useState([]);

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 31;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 31;
    return tooEarly || tooLate;
  };

  return (
    <>
      <Form.Item
        name="dateRange"
        label="Date Range"
        rules={[
          {
            required: true,
            message: "'date range' is required",
          },
        ]}
      >
        <RangePicker
          disabled={jobStarted && !jobOver}
          ranges={{
            Today: [moment().utc().startOf("day"), moment().utc().endOf("day")],
            Yesterday: [
              moment().utc().subtract(1, "days").startOf("day"),
              moment().utc().subtract(1, "days").endOf("day"),
            ],
            "This Week": [
              moment().utc().startOf("isoWeek"),
              moment().utc().endOf("isoWeek"),
            ],
            "Last Week": [
              moment().utc().subtract(1, "weeks").startOf("isoWeek"),
              moment().utc().subtract(1, "weeks").endOf("isoWeek"),
            ],
            "This Month": [
              moment().utc().startOf("month"),
              moment().utc().endOf("month"),
            ],
            "Last Month": [
              moment().utc().subtract(1, "months").startOf("month"),
              moment().utc().subtract(1, "months").endOf("month"),
            ],
          }}
          defaultPickerValue={[
            moment().utc().startOf("day"),
            moment().utc().endOf("day"),
          ]}
          format={dateFormat}
          onChange={onChange}
          disabledDate={disabledDate}
          onCalendarChange={(value) => {
            setDates(value);
          }}
        />
      </Form.Item>
    </>
  );
};
