import { AuthService } from "../AuthService";
import { User } from "../../../model/User";
import { OktaAuth } from "@okta/okta-auth-js";

class DummyAuthServiceImpl implements AuthService {
  getAuthInstance(): OktaAuth | undefined {
    return undefined;
  }

  async getCurrentUser(): Promise<User> {
    return new User({
      firstName: "Pinchy",
      lastName: "theMiner",
      userName: "Pinchy theMiner",
      id: "lobster-001",
      email: "pinchy.theminer@adswizz.com",
      preferred_username: "pinchy.theminer",
    });
  }

  async login(): Promise<void> {
    console.log("Fake login: No action needed");
  }

  async logout(): Promise<void> {
    console.log("Fake logout: No action needed");
  }

  getAccessToken(): string | undefined {
    return "fake-access-token";
  }

  isProd(): boolean {
    return false;
  }

  async isAdvUser(): Promise<boolean> {
    return true; // Always return true for advanced user in fake mode
  }

  async handleLoginRedirect(): Promise<void> {
    console.log("Fake login redirect handled");
  }

  getOriginalUri(): string | undefined {
    return "/";
  }

  isAuthenticated(): boolean {
    return true; // Fake user is always authenticated
  }

  isAuthStateReady(): boolean {
    return true; // Auth state is always ready in fake implementation
  }

  signInWithRedirect(): void {
    console.log("Fake sign in redirect triggered");
  }
}

export { DummyAuthServiceImpl };
