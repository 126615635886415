import DateHelper from "../../../utils/DateHelper";
import AthenaCostHelper from "../../../utils/AthenaCostHelper";
import React from "react";

const formatter = new Intl.NumberFormat("en", {
  style: "decimal",
  unit: "byte",
});

const JobDetails = ({ item }) => {
  return (
    <div>
      <div>
        <strong>Requester: </strong>
        {item.requesterUsername || "NA"}
      </div>
      <div>
        <strong>UID: </strong>
        {item.uid}
      </div>
      <div>
        <strong>Type: </strong>
        {item.type}
      </div>
      <div>
        <strong>Queuing Delay: </strong>
        {item.startedDate
          ? Math.round(
              (DateHelper.parseDateTime(item.startedDate) -
                DateHelper.parseDateTime(item.submittedDate)) /
                1000
            ) + " s"
          : "NA"}
      </div>

      {item.dataExportResponse && item.dataExportResponse.map && (
        <>
          {item.dataExportResponse.map.totalFileSize &&
          item.dataExportResponse.map.totalFileSize > 0 ? (
            <div>
              <strong>Result Size: </strong>
              {formatter.format(item.dataExportResponse.map.totalFileSize)}{" "}
              byte(s)
            </div>
          ) : (
            ""
          )}
          {item.dataExportResponse.map.queryExecutionResult &&
            item.dataExportResponse.map.queryExecutionResult.errorMessage && (
              <div style={{ width: 320 }}>
                <strong>Error: </strong>
                {item.dataExportResponse.map.queryExecutionResult.errorMessage}
              </div>
            )}
          {item.dataExportResponse.map.queryExecutionResult &&
          item.dataExportResponse.map.queryExecutionResult.statistics &&
          item.dataExportResponse.map.queryExecutionResult.statistics
            .dataScannedInBytes ? (
            <>
              <div>
                <strong>Scanned Data: </strong>
                {formatter.format(
                  item.dataExportResponse.map.queryExecutionResult.statistics
                    .dataScannedInBytes
                )}{" "}
                byte(s)
              </div>
              <div>
                <strong>Query Cost: </strong>$
                {AthenaCostHelper.formatCost(
                  item.dataExportResponse.map.queryExecutionResult.statistics
                    .dataScannedInBytes,
                  6
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default JobDetails;
