import { Badge, Popover, Space, Tooltip } from "antd";
import React from "react";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";

const TreeItem = (props) => {
  return (
    <Space>
      <Badge count={props.count} style={{ backgroundColor: "#1890FF" }} />
      <div
        style={{
          color: props.isClientNameDisabled ? "#888" : "#000",
        }}
      >
        {props.zoneId && !props.isClientNameDisabled ? (
          <Popover
            title="Additional Information"
            content={<div>Zone IDs: {props.zoneId}</div>}
            trigger="click"
          >
            <div>{props.clientName}</div>
          </Popover>
        ) : (
          <Space>{props.clientName}</Space>
        )}

        {props.isClientNameDisabled && (
          <span style={{ marginLeft: "5px" }}>
            <Tooltip title="Zone Request events are not logged on SSP-only nodes">
              <ExclamationCircleTwoTone />
            </Tooltip>
          </span>
        )}
      </div>
      {props.shouldHaveSecondaryBadgeCount && (
        <Badge
          count={props.secondaryBadgeCount}
          style={{ backgroundColor: "#52c41a" }}
        />
      )}

      {props.shouldHaveCheck && (
        <Tooltip title={"A bid was won"}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Tooltip>
      )}
    </Space>
  );
};

export default TreeItem;
