import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ApiService } from "./ApiService";
import { serviceLocator } from "../ServiceLocatorImpl";

/**
 * This class implements the ApiService interface and provides methods to perform
 * HTTP requests (GET, POST, DELETE) using the Axios library. It is designed to
 * interact with a backend API, with built-in support for attaching authentication
 * tokens via an Axios request interceptor.
 *
 * The `ApiServiceImpl` class uses a singleton instance of the `AuthService`
 * (accessed via the `ServiceLocator`) to retrieve and attach the necessary
 * authentication token to each request.
 */
class ApiServiceImpl implements ApiService {
  private axiosInstance: AxiosInstance;

  /**
   * Initializes an Axios instance with a base URL, which is either set via an
   * environment variable (`REACT_APP_API_BASE_URL`) or defaults to
   * `http://localhost:8080`. An Axios request interceptor is configured to
   * automatically attach an authentication token to each request.
   */
  constructor() {
    // Initialize Axios instance with a base URL
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8080",
    });

    // Add a request interceptor to attach the token
    this.axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const authService = serviceLocator.getAuthService();
        const accessToken = authService.getAccessToken();

        // Attach the Authorization header if the access token is available
        if (accessToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          };
        }

        // Return the modified config
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  /**
   * Performs a GET request to the specified URL.
   *
   * @param {string} url - The endpoint URL to which the GET request should be made.
   * @returns {Promise<any>} - A promise that resolves with the response data from the GET request.
   */
  get(url: string): Promise<any> {
    return this.axiosInstance.get(url);
  }

  /**
   * Performs a POST request to the specified URL with the provided data.
   *
   * @param {string} url - The endpoint URL to which the POST request should be made.
   * @param {any} data - The payload data to be sent in the POST request.
   * @returns {Promise<any>} - A promise that resolves with the response data from the POST request.
   */
  post(url: string, data: any): Promise<any> {
    return this.axiosInstance.post(url, data);
  }

  /**
   * Performs a DELETE request to the specified URL, optionally with data.
   *
   * @param {string} url - The endpoint URL to which the DELETE request should be made.
   * @param {any} [data] - Optional payload data to be sent in the DELETE request.
   * @returns {Promise<any>} - A promise that resolves with the response data from the DELETE request.
   */
  delete(url: string, data?: any): Promise<any> {
    return this.axiosInstance.delete(url, { data });
  }
}

export { ApiServiceImpl };
