import React, { useEffect, useState, useRef } from "react";

const Effect = (props) => {
  const val = useRef();

  useEffect(() => {
    // Specify how to clean up after this effect:
    return () => (val.current = props);
  }, [props]);

  useEffect(() => {
    console.log("MOUNT", props);
    // Specify how to clean up after this effect:
    return () => console.log("UNMOUNT", val.current);
  }, [props]);

  return null;
};

const MountingTest = () => {
  const [name, setName] = useState("test");
  const [shouldRender, enableRender] = useState(true);

  return (
    <div>
      <input
        type="checkbox"
        checked={shouldRender}
        onChange={() => enableRender(!shouldRender)}
      />
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      {shouldRender && <Effect name={name} />}
    </div>
  );
};

export default MountingTest;
