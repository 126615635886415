import { Link, useLocation } from "react-router-dom";
import { Menu, Spin } from "antd";
import {
  ApartmentOutlined,
  ExportOutlined,
  ProfileOutlined,
  StopFilled,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { serviceLocator } from "../../services/ServiceLocatorImpl";

const { SubMenu } = Menu;

const SiderMenu = () => {
  // https://reacttraining.com/react-router/web/guides/quick-start
  // https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/hooks.md

  const [loading, setLoading] = useState(true);
  const [isAdvancedUser, setIsAdvancedUser] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const advancedUser = await serviceLocator.getAuthService().isAdvUser(); // Check if the user is advanced
        setIsAdvancedUser(advancedUser);
      } catch (error) {
        console.error("Failed to fetch user:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Menu
      theme="dark"
      mode="vertical"
      defaultSelectedKeys={["/export"]}
      selectedKeys={[pathname]}
    >
      <Menu.Item key="/export">
        <ExportOutlined />
        <span>Report Builder</span>
        <Link to="/export" />
      </Menu.Item>
      <Menu.Item key="/txTracking">
        <ApartmentOutlined />
        <span>Transaction Tracker</span>
        <Link to="/txTracking" />
      </Menu.Item>
      <Menu.Item key="/queryEditor">
        <ExportOutlined />
        <span>Query Editor</span>
        <Link to="/queryEditor" />
      </Menu.Item>
      <Menu.Item key="/history">
        <ProfileOutlined />
        <span>History</span>
        <Link to="/history" />
      </Menu.Item>

      {isAdvancedUser && renderAdvancedUserMenu()}

      <Menu.Item key="/asyncTest" hidden>
        <StopFilled />
        <span>AsyncTest</span>
        <Link to="/asyncTest" />
      </Menu.Item>
      <Menu.Item key="/asyncTestFunct" hidden>
        <StopFilled />
        <span>AsyncTestFunct</span>
        <Link to="/asyncTestFunct" />
      </Menu.Item>
    </Menu>
  );
};

const renderAdvancedUserMenu = () => (
  <SubMenu key="/adv" title="Advanced Options">
    <Menu.Item key="/jobList">
      <ProfileOutlined />
      <span>JobList (adv)</span>
      <Link to="/jobList" />
    </Menu.Item>
    <Menu.Item key="/exportDigest">
      <ProfileOutlined />
      <span>ExportDigest (adv)</span>
      <Link to="/exportDigest" />
    </Menu.Item>
  </SubMenu>
);

export default SiderMenu;
