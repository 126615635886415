const JOB_PROTOTYPE = {
  title: undefined,
  eventType: undefined,
  transactionIdName: undefined,
  favoriteItems: undefined,
  state: undefined,
  data: undefined,
  result: undefined,
  resultError: undefined,
  eventsTreeData: undefined,
  trailTreeData: undefined,
  extraTreeData: undefined,
  nestedMode: undefined,
};

const JOB_TYPES = [
  {
    title: "Zone Request",
    eventType: "ZONE_REQUEST",
    transactionIdName: "transactionid",
    favoriteItems: ["_datetime", "transactionid", "instanceid", "client"],
    nestedMode: true,
  },
  {
    title: "Bids",
    eventType: "BIDS",
    transactionIdName: "transactionid",
    favoriteItems: [
      "_datetime",
      "transactionid",
      "instanceid",
      "client",
      "useragent",
    ],
    nestedMode: false,
  },
  {
    title: "Opportunity",
    eventType: "OPPORTUNITY",
    transactionIdName: "transactionid",
    favoriteItems: [
      "_datetime",
      "transactionid",
      "instanceid",
      "client",
      "deviceuseragent", // TODO naming convention !
    ],
    nestedMode: true,
  },
  {
    title: "Contract Impression",
    eventType: "CONTRACT_IMPRESSION",
    transactionIdName: "transactionid",
    favoriteItems: [
      "_datetime",
      "transactionid",
      "instanceid",
      "adid",
      "publisherid",
      "publishername",
      "client",
      "useragent",
    ],
    nestedMode: false,
  },
  {
    title: "Impression",
    eventType: "AD_IMPRESSION",
    transactionIdName: "transactionid",
    favoriteItems: [
      "_datetime",
      "transactionid",
      "instanceid",
      "adid",
      "client",
      "useragent",
    ],
    nestedMode: false,
  },
  {
    title: "Click",
    eventType: "AD_CLICK",
    transactionIdName: "transactionid",
    favoriteItems: [
      "_datetime",
      "transactionid",
      "instanceid",
      "adid",
      "client",
      "useragent",
    ],
    nestedMode: false,
  },
  {
    title: "Contract Report",
    eventType: "CONTRACT_REPORT",
    transactionIdName: "transactionid",
    favoriteItems: [
      "_datetime",
      "transactionid",
      "instanceid",
      "adid",
      "client",
      "useragent",
    ],
    nestedMode: false,
  },
  {
    title: "Report",
    eventType: "AD_REPORT",
    transactionIdName: "transactionid",
    favoriteItems: [
      "_datetime",
      "transactionid",
      "instanceid",
      "adid",
      "client",
      "useragent",
    ],
    nestedMode: false,
  },
  {
    title: "Third Party Tracking",
    eventType: "THIRD_PARTY_TRACKING",
    transactionIdName: "traceid",
    favoriteItems: [
      "_datetime",
      "traceid", // no transactionid for 3rd party tracking
      "instanceid",
      "adid",
      "client",
      "useragent",
    ],
    nestedMode: false,
  },
];

export const txTrackingJobs = JOB_TYPES
  //.filter(($) => $.eventType === "ZONE_REQUEST" || $.eventType === "OPPORTUNITY")
  .map(
    ({ title, eventType, transactionIdName, favoriteItems, nestedMode }) => ({
      ...JOB_PROTOTYPE,
      title,
      eventType,
      transactionIdName,
      favoriteItems,
      nestedMode,
    })
  );
