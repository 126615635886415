import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Row, Col, Button, Form, Space } from "antd";
import { InputTextAreaItem } from "./InputTextAreaItem";
import useStateWithLocalStorage from "../../../hooks/useStateWithLocalStorage";
import { SearchListItem } from "./SearchListItem";
import { refreshJob } from "../../../reducers/queryEditorReducer";
import { useDispatch } from "react-redux";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 24,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 16,
  },
};

//TODO move to helper
const toString = (object) => {
  return object && JSON.stringify(object, null, " ");
};

//TODO move to const
//const initFormValues = {limit: 1000, queryMode: 'select'};
const initFormValues = { plaintextQuery: "-- test" };

const QueryEditorForm = ({
  handleRequest,
  jobStarted,
  jobOver,
  handleCancel,
  handleFormChange,
}) => {
  const [form] = Form.useForm();

  const [formFieldsValue, storeFormFieldsValue] = useStateWithLocalStorage(
    "queryEditor.formFieldsValue"
  );

  const dispatch = useDispatch();

  // Filter null and undefined properties
  const clean = (obj) =>
    Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);

  const initForm = () => {
    //console.log("load form with an effect: " + formFieldsValue);

    // unmarshall stored object
    try {
      const parsedStoredForm = JSON.parse(formFieldsValue);

      clean(parsedStoredForm);

      form.setFieldsValue(parsedStoredForm);
      handleRequestParams();
      saveForm();
    } catch (ignore) {}
  };

  const saveForm = () => {
    //        console.log('save form in localStorage! -> ' + JSON.stringify(form.getFieldsValue()));
    storeFormFieldsValue(JSON.stringify(form.getFieldsValue()));
  };

  // init form from local storage
  // eslint-disable-next-line
  useEffect(() => initForm(), []);

  const [requestParams, setRequestParams] = useState(toString(initFormValues));

  const handleRequestParams = () => {
    setRequestParams(toString(form.getFieldsValue()));

    //        handlePreview(form.getFieldsValue());
  };

  const onFinish = (values) => {
    handleRequestParams();
    setTimeout(() => {
      dispatch(refreshJob());
    }, 2000);
    handleRequest(values);
  };

  const onCancel = () => {
    handleCancel();
  };

  // callback for items
  const onFormChange = () => {
    handleRequestParams();

    handleFormChange();

    saveForm();
  };

  const onReset = () => {
    form.resetFields();
    handleRequestParams();

    handleFormChange();

    saveForm();
  };

  const [myEditor, setMyEditor] = useState(undefined);

  const onEditorInit = (editor) => {
    setMyEditor(editor);
  };

  const onTreeDoubleClick = (value, type) => {
    let text;
    if (type === "table") text = value + " ";
    else if (type === "field") text = value + ", ";
    else text = value;

    // inspired from https://stackoverflow.com/questions/41642649/how-do-i-insert-text-into-a-monaco-editor
    const selection = myEditor.getSelection();
    const id = { major: 1, minor: 1 };
    const op = {
      identifier: id,
      range: selection,
      text: text,
      forceMoveMarkers: true,
    };
    myEditor.executeEdits("my-source", [op]);

    myEditor.focus();
  };

  const onAutoFill = () => {
    const dummyData = {
      plaintextQuery:
        "-- test plaintext query\n" +
        "SELECT FROM_UNIXTIME(timestamp/1000) AS ts, adid, xpaid \n" +
        "FROM v3_ad_impression_cymd \n" +
        "WHERE client = 'pandora'\n" +
        "AND year='2021' AND month='01' AND day='05'\n" +
        "LIMIT 10\n",
    };

    form.setFieldsValue({ ...dummyData });

    handleRequestParams();

    handleFormChange();

    saveForm();
  };

  return (
    <>
      <Form
        {...layout}
        //                style={{display: 'inline-block'}}
        form={form}
        name="queryEditorForm"
        onFinish={onFinish}
        initialValues={formFieldsValue} //{initFormValues}
      >
        <Row>
          <Col span={6}>
            <SearchListItem
              jobStarted={jobStarted}
              jobOver={jobOver}
              onTreeDoubleClick={onTreeDoubleClick}
            />
          </Col>
          <Col span={18}>
            <InputTextAreaItem
              form={form}
              onFormChange={onFormChange}
              onReset={onReset}
              jobStarted={jobStarted}
              jobOver={jobOver}
              onEditorInit={onEditorInit}
            />
          </Col>
        </Row>
        <Form.Item {...tailLayout}>
          <Space style={{ marginTop: "20px" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "18px" }}
              hidden={jobStarted && !jobOver}
            >
              Run
            </Button>
            <Button
              htmlType="button"
              onClick={onReset}
              hidden={true || (jobStarted && !jobOver)}
            >
              Reset
            </Button>
            <Button
              danger
              type="primary"
              onClick={onCancel}
              hidden={!jobStarted || jobOver}
            >
              Cancel
            </Button>
            <Button
              type="link"
              onClick={onAutoFill}
              hidden={true || (jobStarted && !jobOver)}
            >
              autofill with dummy data
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {false && <div>debug form: {JSON.stringify(form.getFieldsValue())}</div>}
      {false && <div>debug requestParams: {requestParams}</div>}
    </>
  );
};

export default QueryEditorForm;
