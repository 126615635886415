import { OktaAuth } from "@okta/okta-auth-js";
import { AuthService } from "../AuthService";
import { User } from "../../../model/User";
import { GlobalConfiguration } from "../../config/GlobalConfiguration";

/**
 * This class implements the AuthService interface using Okta for authentication.
 * It provides methods to handle authentication, login, logout, and user information retrieval.
 */
class OktaAuthServiceImpl implements AuthService {
  private readonly oktaAuthInstance: OktaAuth;

  private globalConfiguration: GlobalConfiguration;

  /**
   * Constructs the OktaAuthServiceImpl.
   * @param oktaAuthInstance - An instance of OktaAuth.
   * @param globalConfiguration
   */
  constructor(
    oktaAuthInstance: OktaAuth,
    globalConfiguration: GlobalConfiguration
  ) {
    this.oktaAuthInstance = oktaAuthInstance;
    this.globalConfiguration = globalConfiguration;
  }

  /**
   * Provides access to the OktaAuth instance.
   * @returns {OktaAuth} The OktaAuth instance.
   */
  getAuthInstance(): OktaAuth | undefined {
    return this.oktaAuthInstance;
  }

  /**
   * Retrieves the current user's information.
   * @returns {Promise<User>} A promise that resolves to the User object.
   */
  async getCurrentUser(): Promise<User> {
    try {
      const userInfo = await this.oktaAuthInstance.getUser();
      return new User({
        firstName: userInfo?.given_name,
        lastName: userInfo?.family_name,
        userName: userInfo?.name,
        id: userInfo?.sub,
        email: userInfo?.email,
        preferred_username: userInfo?.preferred_username,
      });
    } catch (error) {
      console.error("Failed to get user info:", error);
      return new User(); // Return default User
    }
  }

  /**
   * Initiates the login process using Okta's redirect method.
   * @returns {Promise<void>} A promise that resolves when the login process is initiated.
   */
  async login(): Promise<void> {
    try {
      await this.oktaAuthInstance.signInWithRedirect();
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  }

  /**
   * Logs out the current user by ending the session with Okta.
   * @returns {Promise<void>} A promise that resolves when the user is logged out.
   */
  async logout(): Promise<void> {
    try {
      await this.oktaAuthInstance.signOut({
        postLogoutRedirectUri: window.location.origin,
      });
      console.log("Logout successful");
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  }

  /**
   * Checks if the current environment is production.
   * @returns {boolean} True if the environment is production, otherwise false.
   */
  isProd(): boolean {
    return process.env.REACT_APP_ENV === "production";
  }

  /**
   * Checks if the current user is considered an advanced user based on their email.
   * @returns {Promise<boolean>} A promise that resolves to true if the user is an advanced user, otherwise false.
   */
  async isAdvUser(): Promise<boolean> {
    try {
      const user = await this.getCurrentUser();

      if (!user || !user.email) return false;

      return this.globalConfiguration.advancedUserEmails
        .split(",")
        .map((email) => email.trim())
        .includes(user.email);
    } catch (error) {
      console.error("Error checking if user is advanced:", error);
      return false;
    }
  }

  /**
   * Gets the access token for the current session.
   * @returns {string | undefined} The access token if available, otherwise undefined.
   */
  getAccessToken(): string | undefined {
    try {
      return this.oktaAuthInstance.getAccessToken();
    } catch (error) {
      return undefined;
    }
  }

  handleLoginRedirect(): Promise<void> {
    return this.oktaAuthInstance.handleLoginRedirect();
  }
  getOriginalUri(): string | undefined {
    return this.oktaAuthInstance.getOriginalUri();
  }
  isAuthenticated(): boolean {
    const authState = this.oktaAuthInstance.authStateManager.getAuthState();
    return authState?.isAuthenticated || false;
  }
  isAuthStateReady(): boolean {
    const authState = this.oktaAuthInstance.authStateManager.getAuthState();
    return !authState?.isPending;
  }
  signInWithRedirect(): Promise<void> {
    return this.oktaAuthInstance.signInWithRedirect();
  }
}

export { OktaAuthServiceImpl };
