import React, { useEffect, useState, useCallback } from "react";
import "antd/dist/antd.css";
import "./ResultSampleTable.css";
import { Space, Table, Tag } from "antd";
import FieldFormatHelper from "../../utils/FieldFormatHelper";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import CommonButton from "../common/CommonButton";
import scrollIntoView from "scroll-into-view";
import { serviceLocator } from "../../services/ServiceLocatorImpl";

const posColumnProperty = {
  title: "#",
  dataIndex: "#",
  key: "#",
  ellipsis: false,
  width: 50,
};

const ResultSampleTable = ({
  result,
  resultPage,
  hasMoreResults,
  resultErrorMessage,
  isResultLoading,
  handleMeanRowLength,
  handleAnalyzeTransactionId,
  handleMoreClick,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [scrollPos, setScrollPos] = useState(0);

  const computeScrollPosition = useCallback(() => {
    const defaultSize =
      serviceLocator.getGlobalConfiguration().dataExportConfig
        .dataExportPreviewResultDefaultSize;
    setScrollPos(resultPage * defaultSize);
  }, [resultPage]);

  useEffect(() => {
    computeScrollPosition();
  }, [computeScrollPosition]);

  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
      scrollIntoView(document.querySelector(".scroll-row"), {
        align: {
          topOffset: scrollPos === 0 ? 220 : 0,
          top: 0,
          left: 0,
        },
      });
    }, 200);
    return () => clearTimeout(scrollTimeout);
  }, [result, scrollPos]);

  useEffect(() => {
    if (result.length > 0) {
      const meanRowLength =
        result.reduce(
          (acc, element) => acc + JSON.stringify(Object.values(element)).length,
          0
        ) /
        (result.length - 0.5);

      handleMeanRowLength(meanRowLength);
    }
  }, [handleMeanRowLength, result]);

  const renderColumnFactory = useCallback(
    (columnName) => {
      return (text, record) => {
        if (columnName === "transactionid") {
          return (
            <Space size="small">
              {text}
              <CommonButton
                title="Use in Transaction Tracker"
                onClick={() => handleAnalyzeTransactionId(text)}
              >
                <EditOutlined />
              </CommonButton>
            </Space>
          );
        } else {
          return text;
        }
      };
    },
    [handleAnalyzeTransactionId]
  );

  useEffect(() => {
    if (result.length > 0) {
      const firstResult = result[0];
      const newColumns = Object.keys(firstResult).map((key) => ({
        title: FieldFormatHelper.convertNameToDisplayName(key),
        dataIndex: key,
        key,
        ellipsis: false,
        width: 50,
        render: key === "transactionid" ? renderColumnFactory(key) : undefined,
      }));

      newColumns.unshift(posColumnProperty);
      setColumns(newColumns);
    }
  }, [result, renderColumnFactory]);

  useEffect(() => {
    if (columns.length > 0) {
      const updatedDataSource = result.map((sample, index) => {
        const updatedSample = { ...sample, "#": (index + 1).toString() };
        columns.forEach((column) => {
          if (column.dataIndex && sample[column.dataIndex]) {
            const columnWidth = Math.max(
              column.width,
              10 *
                Math.max(column.title.length, sample[column.dataIndex].length)
            );
            column.width = columnWidth;
          }
        });
        return updatedSample;
      });

      setDataSource(updatedDataSource);
    }
  }, [columns, result]);

  return (
    <div>
      {resultErrorMessage && (
        <div>
          <Space>
            <Tag icon={<CloseCircleOutlined />} color="error">
              An error occurred while fetching the query result!
            </Tag>
            <div>{resultErrorMessage}</div>
          </Space>
        </div>
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{
          y: 800,
          x: "max-content", // Changed to handle dynamic column widths
        }}
        bordered
        rowKey="#"
        loading={isResultLoading}
        title={() => (
          <div style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500" }}>
            Query Result
          </div>
        )}
        rowClassName={(record, index) =>
          index === scrollPos ? "scroll-row" : ""
        }
      />
      {hasMoreResults && (
        <div className="query-result-list-more">
          <CommonButton
            loading={isResultLoading}
            type="primary"
            onClick={handleMoreClick}
          >
            More
          </CommonButton>
        </div>
      )}
    </div>
  );
};

export default ResultSampleTable;
