import { createSlice } from "@reduxjs/toolkit";
import Toaster from "../utils/Toaster";
import { fetchJob, fetchJobResult } from "../api/jobs";
import { serviceLocator } from "../services/ServiceLocatorImpl";

export const queryEditorSlice = createSlice({
  name: "queryEditor",
  initialState: {
    job: null,
    jobStarted: false,
    jobOver: false,
    pollingStarted: false,
    result: [],
    resultPage:
      serviceLocator.getGlobalConfiguration().dataExportConfig
        .dataExportPreviewResultDefaultPage,
    hasMoreResults: true,
    isResultLoading: false,
    resultErrorMessage: null,
  },
  reducers: {
    setJob: (state, action) => {
      state.job = action.payload;
    },
    setJobStarted: (state, action) => {
      state.jobStarted = action.payload;
    },
    setJobOver: (state, action) => {
      state.jobOver = action.payload;
    },
    setPollingStarted: (state, action) => {
      state.pollingStarted = action.payload;
    },
    setResults: (state, action) => {
      state.result = action.payload;
    },
    appendResults: (state, action) => {
      state.result = [...state.result, ...action.payload];
    },
    setResultPage: (state, action) => {
      state.resultPage = action.payload;
    },
    setHasMoreResults: (state, action) => {
      state.hasMoreResults = action.payload;
    },
    setIsResultLoading: (state, action) => {
      state.isResultLoading = action.payload;
    },
    setResultErrorMessage: (state, action) => {
      state.resultErrorMessage = action.payload;
    },
    setJobStateAsError: (state, action) => {
      if (state.job) {
        state.job.state = "ERROR";
      }
    },
  },
});

export const {
  setJob,
  setJobOver,
  setJobStarted,
  setPollingStarted,
  setResults,
  appendResults,
  setResultPage,
  setHasMoreResults,
  setIsResultLoading,
  setResultErrorMessage,
  setJobStateAsError,
} = queryEditorSlice.actions;

/**
 * Helper function to determine whether a job's execution has finished (results ready) or not.
 *
 * @param job
 * @returns boolean
 */
const isJobSuccessful = (job) => {
  return (
    job?.dataExportResponse?.map &&
    "totalFileSize" in job.dataExportResponse.map &&
    !job.dataExportResponse.failed
  );
};

const isJobRunning = (job) => {
  return job.state === "QUEUED" || job.state === "RUNNING";
};

const isJobFailed = (job) => {
  return job.state === "FAILED";
};

export const startJob = (values) => async (dispatch) => {
  dispatch(setJob(null));
  dispatch(setJobStarted(true));
  dispatch(setJobOver(false));

  Toaster.notify("info", "Report request submitted");
  serviceLocator
    .getApiService()
    .post(
      `${serviceLocator.getGlobalConfiguration().pathBase}${
        serviceLocator.getGlobalConfiguration().dataExportConfig
          .plaintextDataExportSubmitEndpoint
      }`,
      {
        plaintextQuery: values.plaintextQuery, // expose the effective fields only...
      }
    )
    .then((result) => result.data)
    .then((data) => {
      dispatch(setJob(data));
    })
    .catch((error) => {
      Toaster.notify("error", error.message);
    });
};

/**
 * Fetches a job and replaces it in state.
 * If a job is determined to be successful, the function will attempt to load the initial job results.
 */
export const refreshJob = () => async (dispatch, getState) => {
  const { job } = getState().queryEditor;

  let response;

  try {
    response = await fetchJob(job.uid);
    if (isJobRunning(response.data)) {
      setTimeout(async () => {
        dispatch(refreshJob());
      }, 5000);
    }

    if (isJobFailed(response.data)) {
      dispatch(setJobOver(true));
    }
  } catch (error) {
    Toaster.notify("error", error.message);
    dispatch(setJobStateAsError());
    dispatch(setPollingStarted(false));
    dispatch(setJobOver(true));
    throw error;
  }

  const newJob = response.data;
  dispatch(setJob(newJob));

  /* If the job was successful, fetch the job result. */
  if (isJobSuccessful(newJob)) {
    dispatch(loadInitialJobResults());
    dispatch(setJobOver(true));
  }
};

/**
 * Loads the initial results for this job by fetching the first page and replacing the results in state.
 */
export const loadInitialJobResults = () => async (dispatch) => {
  dispatch(
    setResultPage(
      serviceLocator.getGlobalConfiguration().dataExportConfig
        .dataExportPreviewResultDefaultPage
    )
  );
  const newResults = await dispatch(
    fetchJobResultsForPage(
      serviceLocator.getGlobalConfiguration().dataExportConfig
        .dataExportPreviewResultDefaultPage
    )
  );
  dispatch(setResults(newResults));
};

/**
 * Loads subsequent results for this job by determining the next page, fetching it, and appending the results in state.
 */
export const loadMoreJobResults = () => async (dispatch, getState) => {
  const { resultPage } = getState().queryEditor;
  const nextPage = resultPage + 1;
  dispatch(setResultPage(nextPage));

  const newResults = await dispatch(fetchJobResultsForPage(nextPage));
  dispatch(appendResults(newResults));
};

/**
 * Fetches the job results of a specific page.
 *
 * Expects the underlying job to already be successful and thus ready for its results to be fetched.
 *
 * If the API returns the exact number of results it was asked for, this is considered a hint that
 * there could be more results on a subsequent page (i.e. hasMoreResults is set to true).
 *
 * @param page
 */
export const fetchJobResultsForPage = (page) => async (dispatch, getState) => {
  const queryExecutionId = selectQueryExecutionId(getState());

  dispatch(setIsResultLoading(true));

  let response;
  try {
    response = await fetchJobResult(queryExecutionId, page);
  } catch (error) {
    dispatch(setResultErrorMessage(error.message));
    dispatch(setIsResultLoading(false));
    throw error;
  }

  const newResults = response.data;
  const hasMoreResults =
    newResults.length ===
    serviceLocator.getGlobalConfiguration().dataExportConfig
      .dataExportPreviewResultDefaultSize;

  dispatch(setHasMoreResults(hasMoreResults));
  dispatch(setIsResultLoading(false));

  return newResults;
};

/* Selectors */
export const selectQueryState = (state) => state.queryEditor;
export const selectPollingStarted = (state) => state.queryEditor.pollingStarted;
export const selectQueryExecutionId = (state) =>
  state.queryEditor.job?.dataExportResponse?.map?.queryExecutionResult
    ?.queryExecutionId;

export default queryEditorSlice.reducer;
