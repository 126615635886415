import { Form, Input } from "antd";

const formLayout = {
  wrapperCol: {
    span: 8,
  },
};

const trimValidation = (rule, value, callback) => {
  const message =
    value === value.trim()
      ? undefined
      : "Remove leading or trailing spaces or tabs";

  return callback(message);
};

const TransactionIdFormItem = ({ onFormChange, isAnyJobRunning = false }) => {
  return (
    <Form.Item
      {...formLayout}
      name="transactionId"
      label="Transaction ID"
      rules={[
        {
          required: true,
          message: "A Transaction ID is required",
        },
        {
          validator: trimValidation,
        },
      ]}
    >
      <Input
        placeholder="Enter your Transaction ID"
        onChange={onFormChange}
        allowClear
        disabled={isAnyJobRunning}
      />
    </Form.Item>
  );
};

export default TransactionIdFormItem;
