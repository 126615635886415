import { useEffect, useState } from "react";
import { Form } from "antd";
import TransactionIdFormItem from "./formitem/TransactionIdFormItem";
import DateInputFormItem from "./formitem/DateInputFormItem";
import ActionFormItem from "./formitem/ActionFormItem";
import useStateWithLocalStorage from "../../hooks/useStateWithLocalStorage";
import moment from "moment";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
};

const initFormValues = {};

const TxTrackingForm = ({ onSubmit, onReset, onCancel, isAnyJobRunning }) => {
  const [form] = Form.useForm();

  const [formFieldsValue, storeFormFieldsValue] = useStateWithLocalStorage(
    "transactionTracker.formFieldsValue"
  );

  const [formValues, setFormValues] = useState({});

  const saveForm = () => {
    storeFormFieldsValue(JSON.stringify(form.getFieldsValue()));
  };

  // Filter null and undefined properties
  const clean = (obj) =>
    Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);

  // init form from local storage
  const initForm = () => {
    // go to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // unmarshall stored object
    try {
      const parsedStoredForm = JSON.parse(formFieldsValue);

      clean(parsedStoredForm);

      if (parsedStoredForm.date) {
        const dateValue = parsedStoredForm.date;
        parsedStoredForm.date = moment(dateValue).utc(false);
      }

      form.setFieldsValue(parsedStoredForm);
    } catch (ignore) {}
  };
  // eslint-disable-next-line
  useEffect(() => initForm(), []);

  const onFinish = (values) => {
    setFormValues((old) => values);

    onSubmit(values);

    saveForm();
  };

  const onFormChange = () => {
    setFormValues((old) => form.getFieldsValue);

    saveForm();
  };

  const _onReset = () => {
    form.resetFields();

    onReset();

    saveForm();
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="txTrackingForm"
        onFinish={onFinish}
        initialValues={initFormValues}
      >
        <TransactionIdFormItem
          onFormChange={onFormChange}
          isAnyJobRunning={isAnyJobRunning}
        />
        <DateInputFormItem
          onFormChange={onFormChange}
          isAnyJobRunning={isAnyJobRunning}
        />

        <ActionFormItem
          form={form}
          onFormValuesChange={setFormValues}
          onReset={_onReset}
          onCancel={onCancel}
          isAnyJobRunning={isAnyJobRunning}
        />
      </Form>
      {false && <div>debug data: {JSON.stringify(formValues)}</div>}
    </>
  );
};

export default TxTrackingForm;
