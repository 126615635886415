import { useCallback } from "react";
import useMountedState from "./useMountedState";

const useSafeAsync = () => {
  const isMounted = useMountedState();

  return useCallback(
    (promise) => {
      let isCancelled = false;

      const wrappedPromise = new Promise((resolve, reject) => {
        promise
          .then((value) => {
            if (isMounted() && !isCancelled) {
              resolve(value);
            }
          })
          .catch((error) => {
            if (isMounted() && !isCancelled) {
              reject(error);
            }
          });
      });

      // Return a cleanup function to allow cancellation
      return {
        promise: wrappedPromise,
        cancel() {
          isCancelled = true;
        },
      };
    },
    [isMounted]
  );
};

export default useSafeAsync;
