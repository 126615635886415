import { configureStore } from "@reduxjs/toolkit";
import txTrackingReducer from "./reducers/txTrackingReducer";
import queryBuilderReducer from "./reducers/queryBuilderReducer";
import queryEditorReducer from "./reducers/queryEditorReducer";

// Configure the Redux store
const store = configureStore({
  reducer: {
    txTracking: txTrackingReducer,
    queryBuilder: queryBuilderReducer,
    queryEditor: queryEditorReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {txTracking: TxTrackingState, queryBuilder: QueryBuilderState, queryEditor: QueryEditorState}
export type AppDispatch = typeof store.dispatch;

export default store;
