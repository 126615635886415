import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./SelectFiltersItem.css";
import { Button, Form, Input, Select, Space } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import FieldFormatHelper from "../../../utils/FieldFormatHelper";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const { Option } = Select;

const fetchEventTypeFieldList = ({ setData, setError }) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.eventTypeFieldListEndpoint}`
    )
    .then((result) => result.data)
    .then(setData)
    .catch(setError);
};

const fetchAudienceSegmentsFieldList = ({
  setAudienceSegmentsData,
  setError,
}) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.audienceSegmentFieldsEndpoint}`
    )
    .then((result) => result.data)
    .then(setAudienceSegmentsData)
    .catch(setError);
};

const retrieverOptions = (type) => {
  if (
    (type && type.startsWith("varchar")) ||
    type === "integer" ||
    type === "bigint" ||
    type === "double"
  )
    return (
      <>
        <Option
          key="CONTAINS"
          value="CONTAINS"
          title="Use a single value, case insensitive"
        >
          contains
        </Option>
        <Option
          key="DOESNOTCONTAIN"
          value="DOESNOTCONTAIN"
          title="Use a single value, case insensitive"
        >
          does not contain
        </Option>
        <Option
          key="EQUALS"
          value="EQUALS"
          title="Use a single value, case insensitive"
        >
          equals
        </Option>
        <Option
          key="ISANYOF"
          value="ISANYOF"
          title="Use commas between each value with no spaces or other formatting, case insensitive"
        >
          is any of
        </Option>
        <Option
          key="ISNOTANYOF"
          value="ISNOTANYOF"
          title="Use commas between each value with no spaces or other formatting, case insensitive"
        >
          is not any of
        </Option>
        <Option
          key="ISNOTEQUAL"
          value="ISNOTEQUAL"
          title="Use a single value, case insensitive"
        >
          is not equal
        </Option>
        <Option
          key="LIKE"
          value="LIKE"
          title="Use a SQL LIKE expression, case insensitive"
        >
          SQL LIKE
        </Option>
        <Option
          key="NOTLIKE"
          value="NOTLIKE"
          title="Use a SQL LIKE expression, case insensitive"
        >
          SQL NOT LIKE
        </Option>
        <Option key="ISNULL" value="ISNULL" title="Use no value">
          is NULL
        </Option>
        <Option key="ISNOTNULL" value="ISNOTNULL" title="Use no value">
          is not NULL
        </Option>
      </>
    );
  else if (type === "array(varchar)")
    return (
      <>
        <Option
          key="CONTAINS"
          value="CONTAINS"
          title="Use a single value, case insensitive"
        >
          contains
        </Option>
        <Option
          key="DOESNOTCONTAIN"
          value="DOESNOTCONTAIN"
          title="Use a single value, case insensitive"
        >
          does not contain
        </Option>
        <Option
          key="ISANYOF"
          value="ISANYOF"
          title="Use commas between each value with no spaces or other formatting, case insensitive"
        >
          is any of
        </Option>
        <Option
          key="ISNOTANYOF"
          value="ISNOTANYOF"
          title="Use commas between each value with no spaces or other formatting, case insensitive"
        >
          is not any of
        </Option>
        <Option
          key="LIKE"
          value="LIKE"
          title="Use a SQL LIKE expression, case insensitive"
        >
          SQL LIKE
        </Option>
        <Option
          key="NOTLIKE"
          value="NOTLIKE"
          title="Use a SQL LIKE expression, case insensitive"
        >
          SQL NOT LIKE
        </Option>
        <Option key="ISNULL" value="ISNULL" title="Use no value">
          is NULL
        </Option>
        <Option key="ISNOTNULL" value="ISNOTNULL" title="Use no value">
          is not NULL
        </Option>
      </>
    );
  else if (type === "boolean")
    return (
      <>
        <Option
          key="EQUALS"
          value="EQUALS"
          title="Use an exact value [false,true]"
        >
          equals
        </Option>
        <Option key="ISNULL" value="ISNULL" title="Use no value">
          is NULL
        </Option>
        <Option key="ISNOTNULL" value="ISNOTNULL" title="Use no value">
          is not NULL
        </Option>
      </>
    );
  else if (
    (type && type.startsWith("array(row(")) ||
    type === "map(varchar, array(varchar))" ||
    type === "map(varchar, varchar)"
  )
    return (
      <>
        <Option
          key="CONTAINS"
          value="CONTAINS"
          title="Use a single value, case insensitive"
        >
          contains
        </Option>
        <Option
          key="DOESNOTCONTAIN"
          value="DOESNOTCONTAIN"
          title="Use a single value, case insensitive"
        >
          does not contain
        </Option>
        <Option
          key="LIKE"
          value="LIKE"
          title="Use a SQL LIKE expression, case insensitive"
        >
          SQL LIKE
        </Option>
        <Option
          key="NOTLIKE"
          value="NOTLIKE"
          title="Use a SQL LIKE expression, case insensitive"
        >
          SQL NOT LIKE
        </Option>
        <Option key="ISNULL" value="ISNULL" title="Use no value">
          is NULL
        </Option>
        <Option key="ISNOTNULL" value="ISNOTNULL" title="Use no value">
          is not NULL
        </Option>
      </>
    );
  //    else if (type === 'map(varchar, array(varchar))')
  else
    return (
      <>
        <Option
          key="CONTAINS"
          value="CONTAINS"
          title="Use a single value, case insensitive"
        >
          contains
        </Option>
        <Option
          key="DOESNOTCONTAIN"
          value="DOESNOTCONTAIN"
          title="Use a single value, case insensitive"
        >
          does not contain
        </Option>
        <Option key="ISNULL" value="ISNULL" title="Use no value">
          is NULL
        </Option>
        <Option key="ISNOTNULL" value="ISNOTNULL" title="Use no value">
          is not NULL
        </Option>
      </>
    );
  //    else
  //        return null;
};

export const SelectFiltersItem = ({
  isVisible,
  onFormChange,
  jobStarted,
  jobOver,
}) => {
  const [data, setData] = useState(undefined);
  const [audienceSegmentsData, setAudienceSegmentsData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    !(data || error) && fetchEventTypeFieldList({ setData, setError });
  }, [data, error]);

  useEffect(() => {
    !(audienceSegmentsData || error) &&
      fetchAudienceSegmentsFieldList({ setAudienceSegmentsData, setError });
  }, [audienceSegmentsData, error]);

  const onFieldChange = (item, index, getFieldValue) => {
    onFormChange();

    const filter = getFieldValue("filters")[index];

    // reset
    filter.operator = undefined;
    filter.value = undefined;
  };

  const onOperatorChange = (item, index, getFieldValue) => {
    onFormChange();

    const filter = getFieldValue("filters")[index];

    // reset
    if (filter.operator === "ISNULL" || filter.operator === "ISNOTNULL")
      filter.value = undefined;
  };

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => {
        return prevValues.eventType !== currentValues.eventType;
      }}
    >
      {({ getFieldValue }) => {
        const eventType = getFieldValue("eventType");
        const hasRawEventsFields =
          getFieldValue("datasetType") === "RAW_EVENTS" && eventType && data;
        const hasAudienceSegmentsFields =
          getFieldValue("datasetType") === "AUDIENCE_SEGMENTS" &&
          audienceSegmentsData;

        let fields;
        if (hasRawEventsFields) {
          const eventTypeFieldsObject = data.find(
            (item) => item.id === eventType
          );
          fields = eventTypeFieldsObject?.fields ?? [];
        } else if (hasAudienceSegmentsFields) {
          const globalConfiguration = serviceLocator.getGlobalConfiguration();

          const audienceSegmentsFieldsObject = audienceSegmentsData.find(
            (item) =>
              item.id ===
              globalConfiguration.jobConfig.defaultAudienceSegmentTypeValue
          );
          fields = audienceSegmentsFieldsObject?.fields ?? [];
        } else {
          fields = [];
        }

        fields = fields
          .map((field) => {
            field.operatorOptions = retrieverOptions(field.type);
            return FieldFormatHelper.enrichField(field);
          })
          .sort((o1, o2) => (o1.display > o2.display ? 1 : -1));

        const getOperatorOptions = (name) => {
          const filter = getFieldValue("filters")[name];

          if (!filter) return null;

          const fieldName = filter.field;

          if (!fieldName) return null;

          const field = fields.find((item) => item.name === fieldName);

          if (!field) return null;

          return field.operatorOptions;
        };

        const valueFieldShown = (filter) =>
          filter
            ? filter.operator &&
              filter.operator !== "ISNULL" &&
              filter.operator !== "ISNOTNULL"
            : false;

        return (
          isVisible && (
            <Form.Item
              name="filters"
              label="Filters"
              required={
                getFieldValue("filters") && getFieldValue("filters").length > 0
              }
            >
              <Form.List name="filters">
                {(items, { add, remove }) => {
                  /**
                   * `fields` internal fill with `name`, `key`, `fieldKey` props.
                   * It can be extended into sub field to support multiple dynamic fields.
                   */
                  return (
                    <div>
                      {items.map((item, index) => {
                        return (
                          <Space
                            key={item.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="start"
                          >
                            <Form.Item
                              {...item}
                              name={[item.name, "field"]}
                              fieldKey={[item.fieldKey, "field"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Select a field",
                                },
                              ]}
                            >
                              <Select
                                disabled={jobStarted && !jobOver}
                                showSearch
                                allowClear
                                style={{ width: 200 }}
                                placeholder="field"
                                onChange={() =>
                                  onFieldChange(item, index, getFieldValue)
                                }
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {fields &&
                                  fields
                                    .filter((field) => field.filterable)
                                    .map((field) => (
                                      <Option
                                        key={field.name}
                                        value={field.name}
                                        title={FieldFormatHelper.formatTitle(
                                          field
                                        )}
                                      >
                                        {field.display}
                                      </Option>
                                    ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...item}
                              name={[item.name, "operator"]}
                              fieldKey={[item.fieldKey, "operator"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Select an operator",
                                },
                              ]}
                            >
                              <Select
                                disabled={jobStarted && !jobOver}
                                showSearch
                                allowClear
                                style={{ width: 150 }}
                                placeholder="operator"
                                onChange={() =>
                                  onOperatorChange(item, index, getFieldValue)
                                }
                                //                                                            onChange={onFormChange}
                              >
                                {getOperatorOptions(item.name)}
                              </Select>
                            </Form.Item>
                            {valueFieldShown(
                              getFieldValue("filters")[item.name]
                            ) && (
                              <Form.Item
                                {...item}
                                name={[item.name, "value"]}
                                fieldKey={[item.fieldKey, "value"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "Enter your value",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={jobStarted && !jobOver}
                                  placeholder="empty value"
                                  style={{ width: 200 }}
                                  onChange={onFormChange}
                                />
                              </Form.Item>
                            )}
                            <CloseOutlined
                              className="dynamic-delete-button"
                              onClick={() => {
                                remove(item.name);
                                onFormChange();
                              }}
                            />
                          </Space>
                        );
                      })}
                      <Form.Item>
                        <Button
                          disabled={jobStarted && !jobOver}
                          type="dashed"
                          onClick={() => {
                            add();
                            onFormChange();
                          }}
                          style={{ width: "90%" }}
                        >
                          <PlusOutlined /> Add a filter
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Form.Item>
          )
        );
      }}
    </Form.Item>
  );
};
