import React, { useEffect, useState } from "react";
import {
  DEFAULT_QUERY,
  DEFAULT_HPP,
  PATH_BASE,
  PATH_SEARCH,
  PARAM_SEARCH,
  PARAM_PAGE,
  PARAM_HPP,
} from "./constants";
import { serviceLocator } from "../../services/ServiceLocatorImpl";

export default function AsyncTestFunct() {
  const [data, setData] = useState({ hits: [] });
  const [query, setQuery] = useState(DEFAULT_QUERY);
  //    const [page, setPage] = useState(0);
  const page = 0;

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const result = await serviceLocator
        .getApiService()
        .get(
          `${PATH_BASE}${PATH_SEARCH}?${PARAM_SEARCH}${query}&${PARAM_PAGE}${page}&${PARAM_HPP}${DEFAULT_HPP}`
        );
      if (!ignore) setData(result.data);
    }

    fetchData();
    return () => {
      ignore = true;
    };
  }, [query]);

  return (
    <>
      <input value={query} onChange={(e) => setQuery(e.target.value)} />
      <ul>
        {data.hits.map((item) => (
          <li key={item.objectID}>
            <a href={item.url}>{item.title}</a>
          </li>
        ))}
      </ul>
    </>
  );
}
