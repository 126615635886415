import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { serviceLocator } from "../../services/ServiceLocatorImpl";
import CommonButton from "../common/CommonButton";
import { AuthService } from "../../services/auth/AuthService";

const CustomLoginCallback: React.FC = () => {
  const authService: AuthService = serviceLocator.getAuthService();
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleLoginRedirect = async () => {
      try {
        await authService.handleLoginRedirect();
        const originalUri = authService.getOriginalUri(); // Retrieve original URI
        history.replace(originalUri || "/"); // Fallback to "/" if originalUri is not available
      } catch (err) {
        console.error("Error during login redirect:", err);
        setError(err instanceof Error ? err.message : String(err)); // Set error as a string
      }
    };

    if (!authService.isAuthenticated()) {
      handleLoginRedirect();
    }
  }, [authService, history]);

  // Handle loading state while authentication is in progress
  if (!authService.isAuthStateReady()) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        <p>An error occurred during login:</p>
        <p>{error}</p>
        <p>
          <CommonButton
            onClick={() => authService.signInWithRedirect()}
            type="link"
            style={{
              textDecoration: "underline",
              padding: 0,
              background: "none",
              border: "none",
            }}
          >
            Try Again
          </CommonButton>
        </p>
      </div>
    );
  }

  // If authState is resolved and no error, redirecting should have taken place
  return null;
};

export default CustomLoginCallback;
