import React from "react";
import "antd/dist/antd.css";
import { Form, AutoComplete } from "antd";

const { Option } = AutoComplete;

const options = [
  {
    value: "10",
  },
  {
    value: "100",
  },
  {
    value: "1000",
    label: "1 000",
  },
  {
    value: "10000",
    label: "10 000",
  },
  {
    value: "100000",
    label: "100 000",
  },
  {
    value: "Unlimited",
    label: "Unlimited",
  },
];

export const SelectLimitItem = ({ onFormChange, jobStarted, jobOver }) => (
  <Form.Item
    name="limit"
    label="Maximum Number of Results"
    rules={[
      {
        required: true,
        message: "'Maximum Number of Results' is required",
      },
    ]}
  >
    <AutoComplete
      disabled={jobStarted && !jobOver}
      showSearch
      allowClear
      style={{
        width: 200,
      }}
      placeholder="Max. Results"
      onChange={onFormChange}
    >
      {options.map((item) => (
        <Option key={item.value} value={item.value}>
          {item.label || item.value}
        </Option>
      ))}
    </AutoComplete>
  </Form.Item>
);
