import React, { useState } from "react";
import "./App.css";
import OverallLayout from "../components/layout/OverallLayout";
import { useOktaAuth } from "@okta/okta-react";
import Login from "../components/login/Login";
import ErrorLoginView from "../components/login/ErrorLoginView";
import Loading from "../components/common/Loading";
import { serviceLocator } from "../services/ServiceLocatorImpl";
import { AuthService } from "../services/auth/AuthService";
import { AuthError } from "../types/auth";

const App: React.FC = () => {
  const authService: AuthService = serviceLocator.getAuthService();
  const isAuthEnabled =
    serviceLocator.getGlobalConfiguration().oktaConfig.authEnabled;

  const { authState } = useOktaAuth();

  const [authError, setAuthError] = useState<AuthError>(null);

  const login = async () => {
    try {
      await authService.login();
    } catch (error: any) {
      console.error("Login error:", error);
      setAuthError(error.message || "Unknown error occurred");
    }
  };

  if (isAuthEnabled) {
    if (!authState) return <Loading />;

    if (authState.error || authError) {
      return (
        <ErrorLoginView
          errorMessage={authError || authState.error?.message}
          retryLogin={login}
        />
      );
    }

    if (!authState.isAuthenticated) {
      return <Login onClick={login} />;
    }
  }

  console.log(
    "REACT_APP_ENV:",
    process.env.REACT_APP_ENV,
    ",",
    "NODE_ENV:",
    process.env.NODE_ENV
  );

  return (
    <div className="App">
      <OverallLayout />
    </div>
  );
};

export default App;
