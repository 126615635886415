import Toaster from "../utils/Toaster";
import { serviceLocator } from "./ServiceLocatorImpl";

const lobsterApiService = {
  /**
   * Builds the 'download url' for accessing the CSV outcome of the report builder.
   *
   * @param {object} job - A job object that succeeded (i.e., with job state set to 'SUCCEEDED' from the API)
   * @returns {string} - The URL for downloading the export file
   */
  buildExportFileLink: (job) => {
    if (!job) return "";

    const queryExecutionId =
      job?.dataExportResponse?.map?.queryExecutionResult?.queryExecutionId;
    if (!queryExecutionId) return "";

    const clientPrefix = job?.dataExportDto?.client
      ? `${job.dataExportDto.client}-${job.dataExportDto.eventType}-`
      : "";

    return `${serviceLocator.getGlobalConfiguration().pathBase}${
      serviceLocator.getGlobalConfiguration().dataExportConfig
        .dataExportDownloadEndpoint
    }/${queryExecutionId}?prefix=${clientPrefix}`;
  },

  /**
   * Terminates a job by its job ID.
   *
   * @param {string} jobId - The ID of the job to terminate
   * @returns {Promise<object|null>} - The response from the API, or null if an error occurred
   */
  terminateJob: async (jobId) => {
    if (!jobId) {
      const errorMessage = "Job ID is required to terminate a job.";
      console.warn(errorMessage);
      Toaster.notify("error", errorMessage);
      return null;
    }

    try {
      const response = await serviceLocator
        .getApiService()
        .delete(
          `${serviceLocator.getGlobalConfiguration().pathBase}${
            serviceLocator.getGlobalConfiguration().dataExportConfig
              .dataExportCancelEndpoint
          }/${jobId}`
        );

      if (response.status !== 200) {
        const errorMessage = `Failed to terminate job. Status: ${response.status}`;
        console.warn(errorMessage);
        Toaster.notify("error", errorMessage);
        return null;
      }

      return response; // Return the whole response object
    } catch (error) {
      console.warn("terminateJob failed", error.message, error);
      Toaster.notify("error", error.message);
      return null; // Return null to indicate failure
    }
  },
};

export default lobsterApiService;
