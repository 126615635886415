const Comparator = {
  ASC: 1,
  DESC: -1,

  propertyBasedComp: (a, b, name, sortOrder = Comparator.ASC) => {
    if (a[name] < b[name]) return -1 * sortOrder;
    if (a[name] > b[name]) return sortOrder;
    return 0;
  },

  listBasedComp: (a, b, list, sortOrder = Comparator.ASC) => {
    const c = list.indexOf(b) - list.indexOf(a);
    if (c > 0) return -1 * sortOrder;
    if (c < 0) return sortOrder;
    return 0;
  },

  // TODO generify with sortOrder
  MULTIPLE_ITEM_ORDER_CONFIGURATION: {
    name: undefined, // String
    orderList: undefined, // array of String
  },

  multipleItemComp: (a, b, config) => {
    const compare = (item1, item2, name, orderList) => {
      const name1 = item1[name];
      const name2 = item2[name];
      const i1 = orderList.indexOf(name1);
      const i2 = orderList.indexOf(name2);

      if (i1 !== -1 && i2 !== -1) {
        const c = i1 - i2;
        if (c > 0) return 1;
        if (c < 0) return -1;
      } else if (i1 === -1 && i2 >= 0) return 1;
      else if (i2 === -1 && i1 >= 0) return -1;
      else if (i1 === -1 && i2 === -1) {
        if (name1 > name2) return 1;
        else if (name1 < name2) return -1;
      }
    };

    for (const el of config) {
      const c = compare(a, b, el.name, el.orderList);
      if (c) return c;
    }

    return 0;
  },
};

export default Comparator;
