import styled from "styled-components";
import { List } from "antd";

const TagListItem = styled(List.Item)`
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 4px;
  padding-left: 16px;
`;

export default TagListItem;
