import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import useSafeAsync from "../../hooks/useSafeAsync";
import CommonFilter from "../common/CommonFilter";
import CommonButton from "../common/CommonButton";
import lobsterApiService from "../../services/lobsterApiService";
import "./JobList.css";
import JobsTable from "./common/JobsTable";
import { ReloadOutlined } from "@ant-design/icons";
import CornerLoader from "../../utils/CornerLoader";
import { serviceLocator } from "../../services/ServiceLocatorImpl";

const JobList = ({ history }) => {
  const [results, setResults] = useState([]);
  const [jobState, setJobState] = useState(
    serviceLocator.getGlobalConfiguration().jobConfig.defaultJobState
  );
  const [jobType, setJobType] = useState(
    serviceLocator.getGlobalConfiguration().jobConfig.defaultJobType
  );
  const [number, setNumber] = useState(
    serviceLocator.getGlobalConfiguration().jobConfig.defaultPage
  );
  const [error, setError] = useState(null);
  const [taskIdToName, setTaskIdToName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const safeAsync = useSafeAsync();

  const nextPage = number + (results && results.length > 1 ? 1 : 0);
  const fileLink = (item) => lobsterApiService.buildExportFileLink(item);

  //TODO fetch it from API at some point
  const jobStates = [
    "CREATED",
    "QUEUED",
    "RUNNING",
    "FAILED",
    "SUCCEEDED",
    "TERMINATED",
    "ERROR",
  ];

  //TODO fetch it from API at some point
  const jobTypes = [
    "BuiltQueryDataExportJob",
    "PlaintextQueryDataExportJob",
    "PredefinedRawDataExportJob",
  ];

  const setJobList = useCallback((data) => {
    const { content, number } = data;

    const updatedNumber =
      content && content.length > 0 ? number : Math.max(number - 1, 0);

    if (
      number === serviceLocator.getGlobalConfiguration().jobConfig.defaultPage
    ) {
      setResults(content);
    } else {
      setResults((prevResults) => [...prevResults, ...content]);
    }
    setNumber(updatedNumber);
    setIsLoading(false);
  }, []);

  const setTaskList = useCallback((data) => {
    const taskIdToName = Object.assign(
      {},
      ...data.map((task) => ({ [task.id]: task.name }))
    );
    setTaskIdToName(taskIdToName);
  }, []);

  const fetchJobList = useCallback(
    (
      jobState,
      jobType,
      page = serviceLocator.getGlobalConfiguration().jobConfig.defaultPage
    ) => {
      setError(null);
      setIsLoading(true);

      // Destructure global configuration for easier access
      const { pathBase, jobConfig } = serviceLocator.getGlobalConfiguration();

      // Define individual query parameters
      const jobStateParam = `${jobConfig.paramJobState}=${jobState}`;
      const directionParam = `${jobConfig.paramDirection}=${jobConfig.defaultDirection}`;
      const pageParam = `${jobConfig.paramPage}=${page}`;
      const sizeParam = `${jobConfig.paramSize}=${jobConfig.defaultSize}`;
      const jobTypesParam = `${jobConfig.paramJobTypes}=${jobType}`;

      const { promise } = safeAsync(
        serviceLocator
          .getApiService()
          .get(
            `${pathBase}${jobConfig.jobListEndpoint}?${jobStateParam}&${jobTypesParam}&${directionParam}&${pageParam}&${sizeParam}`
          )
      );
      promise
        .then((result) => setJobList(result.data))
        .catch((error) => setError(error));
    },
    [safeAsync, setJobList] // Dependencies of fetchJobList
  );

  const fetchTaskList = useCallback(() => {
    const { promise } = safeAsync(
      serviceLocator
        .getApiService()
        .get(
          `${serviceLocator.getGlobalConfiguration().pathBase}${
            serviceLocator.getGlobalConfiguration().taskConfig.taskListEndpoint
          }`
        )
    );
    promise
      .then((result) => setTaskList(result.data))
      .catch((error) => setError(error));
  }, [safeAsync, setTaskList]);

  useEffect(() => {
    fetchJobList(jobState, jobType);
    fetchTaskList();

    return () => {
      setIsLoading(false);
    };
  }, [fetchJobList, fetchTaskList, jobState, jobType]); // Added dependencies

  const onReload = () => {
    setNumber(serviceLocator.getGlobalConfiguration().jobConfig.defaultPage); // reset page
    fetchJobList(jobState, jobType);
  };

  const onJobStateChange = (value) => {
    const jobState =
      value === undefined
        ? serviceLocator.getGlobalConfiguration().jobConfig.defaultJobState
        : value;

    setNumber(serviceLocator.getGlobalConfiguration().jobConfig.defaultPage); // reset page
    setJobState(jobState); // change state

    fetchJobList(jobState, jobType);
  };

  const onJobTypeChange = (value) => {
    const jobType =
      value === undefined
        ? serviceLocator.getGlobalConfiguration().jobConfig.defaultJobType
        : value;

    setNumber(serviceLocator.getGlobalConfiguration().jobConfig.defaultPage); // reset page
    setJobType(jobType); // change state

    fetchJobList(jobState, jobType);
  };

  const onEditQuery = useCallback(
    (item) => {
      // only BuiltQueryDataExportJob can be edited
      if (
        !item ||
        (item.type !== "BuiltQueryDataExportJob" &&
          item.type !== "PlaintextQueryDataExportJob")
      )
        return;

      if (item.type === "BuiltQueryDataExportJob") {
        // persist the item in the saved job stored in the localStorage
        const dto = item.dataExportDto; // present by design
        delete dto.taskId; // clean up
        localStorage.setItem(
          "reportExport.formFieldsValue",
          JSON.stringify(dto)
        );

        // navigate to the Report builder view
        history.push("/export");
      } else if (item.type === "PlaintextQueryDataExportJob") {
        // persist the item in the saved job stored in the localStorage
        const dto = item.plaintextQueryDataExportDto; // present by design
        delete dto.taskId; // clean up
        localStorage.setItem(
          "queryEditor.formFieldsValue",
          JSON.stringify(dto)
        );

        // navigate to the Query editor view
        history.push("/queryEditor");
      } // else // no action
    },
    [history]
  );

  return (
    <div className="job-list-page">
      {isLoading && CornerLoader}
      <div className="filters-container">
        <CommonFilter
          value={jobState}
          options={jobStates}
          onChange={onJobStateChange}
          filterLabel={"State"}
        >
          Filter:
        </CommonFilter>
        <CommonFilter
          value={jobType}
          options={jobTypes}
          onChange={onJobTypeChange}
          onReload={onReload}
          filterLabel={"Type"}
        />
        <CommonButton onClick={onReload}>
          <ReloadOutlined />
        </CommonButton>
      </div>
      {error ? (
        <div>
          <p>Something went wrong.</p>
        </div>
      ) : results && results.length > 0 ? (
        <JobsTable
          list={results}
          onEditQuery={onEditQuery}
          fileLink={fileLink}
          taskIdToName={taskIdToName}
          hasAdditionalInfo={true}
        />
      ) : (
        <div>
          <p>No results.</p>
        </div>
      )}
      <div className="job-list-more">
        <CommonButton
          type="primary"
          onClick={() => fetchJobList(jobState, jobType, nextPage)}
          disabled={isLoading}
        >
          More
        </CommonButton>
      </div>
    </div>
  );
};

export default withRouter(JobList);
