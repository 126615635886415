import React from "react";
import { Badge, Divider, Space, Tag, Tooltip, Typography } from "antd";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import ZoneTypeTag from "../bids/helper/ZoneTypeTag";
import ZoneRollTypeTag from "../bids/helper/ZoneRollTypeTag";
import AdDataDetailsAdImpression from "./AdDataDetailsAdImpression";
import AdDataDetailsContractImpression from "./AdDataDetailsContractImpression";
import Comparator from "../bids/helper/Comparator";
import OpportunityModel from "../bids/model/OpportunityModel";

const { Text } = Typography;

const adSummaryDebug = (ev) => {
  return `*requestid:${ev.requestid}\n*adid:${ev.adid}\n*txtype:${ev.transactiontype}\n*fpnlTenants:${ev.firsttenant}/${ev.prevtenant}/${ev.nexttenant}/${ev.lasttenant}\n*zoneInfo:${ev.zonetype}/${ev.zonerolltype}/${ev.zoneid}`;
};

// order by zrt forced order and adid ASC
const adTrailsComparator = (a, b) => {
  return Comparator.multipleItemComp(a[0], b[0], [
    {
      name: "zonerolltype",
      orderList: OpportunityModel.ZONE_ROLL_TYPE_ORDER,
    },
    {
      name: "adid",
      orderList: [],
    },
  ]);
};

const AdDataDetails = ({ adModel }) => {
  const flattenedAdTrails =
    (adModel && adModel.map((item) => item.adTrails).flat()) || [];
  flattenedAdTrails.sort(adTrailsComparator);

  return (
    <ul
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      {flattenedAdTrails &&
        flattenedAdTrails.map((item, index) => {
          return (
            <li key={index}>
              <h2>
                <Space>
                  <span>Ad {item[0].adid}</span>
                  <ZoneTypeTag type={item[0].zonetype} />
                  {
                    <span title={item[0].zonerolltype}>
                      <ZoneRollTypeTag type={item[0].zonerolltype} />
                    </span>
                  }
                </Space>
              </h2>

              <div
                style={{
                  margin: "0px 0px 0px 20px",
                  padding: 0,
                }}
              >
                <div
                  style={{
                    margin: "20px 0px 0px 0px",
                    padding: 0,
                  }}
                >
                  {
                    <Space direction="vertical">
                      {[item].map((adTrail) => (
                        <>
                          <Space>
                            {adTrail.map(($, index) => {
                              return (
                                <div
                                  style={{
                                    margin: "0px 0px 0px 0px",
                                    padding: "0px 5px 0px 5px",
                                  }}
                                  key={index}
                                >
                                  {index > 0 ? ">" : ""}
                                  <Badge
                                    size="small"
                                    offset={[5, -2]}
                                    count={$.campaignid ? "AS" : "SSP"}
                                    color={$.campaignid ? "#8a489c" : "#de8283"}
                                    key={index}
                                  >
                                    <Tag
                                      style={{
                                        margin: "0px 0px 0px 10px",
                                        padding: "0px 5px 0px 5px",
                                      }}
                                    >
                                      <div title={adSummaryDebug($)}>
                                        <Text strong>{$.client}</Text>
                                        {$._notInTxType ? (
                                          <Tooltip title="Order not guaranteed!">
                                            {" "}
                                            <ExclamationCircleTwoTone twoToneColor="red" />
                                          </Tooltip>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </Tag>
                                  </Badge>
                                </div>
                              );
                            })}
                          </Space>
                          <ul
                            style={{
                              margin: "10px 0px 0px 20px",
                              padding: 0,
                            }}
                          >
                            {adTrail &&
                              adTrail.map((impEvent, index) => {
                                return impEvent.campaignid ? (
                                  <AdDataDetailsAdImpression
                                    impEvent={impEvent}
                                    index={index}
                                  />
                                ) : (
                                  <AdDataDetailsContractImpression
                                    impEvent={impEvent}
                                    index={index}
                                  />
                                );
                              })}
                          </ul>
                        </>
                      ))}
                    </Space>
                  }
                </div>
              </div>
              {adModel.length - 1 !== index ? <Divider /> : ""}
            </li>
          );
        })}
    </ul>
  );
};

export default AdDataDetails;
