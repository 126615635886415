import { Col, Row } from "antd";

const TxTrackingTitle = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <strong>Transaction Tracker</strong>
      </Col>
      <Col span={16} />
    </Row>
  );
};

export default TxTrackingTitle;
