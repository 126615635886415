import React, { useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "antd/dist/antd.css";
import "./OverallLayout.css";
import { Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import styled from "styled-components";

// Components
import SiderMenu from "./SiderMenu";
import UserMenu from "./UserMenu";

// Services
import versionService from "../../services/versionService";
import AppRoutes from "../routes/AppRoutes";

// Styled Components
const { Header, Sider, Content, Footer } = Layout;

const FullLayout = styled(Layout)`
  min-height: 100vh;
`;

const HeaderWrapper = styled(Header)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentWrapper = styled(Content)`
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
  display: flex;
  flex-direction: column;
`;

const FooterWrapper = styled(Footer)`
  text-align: right;
  padding: 5px 20px;
`;

const OverallLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  // Toggle the collapsed state of the sider
  const toggleSidebar = () => {
    setCollapsed((prevState) => !prevState);
  };

  const title = versionService.getFormattedVersion();
  const year = new Date().getFullYear();

  return (
    <Router>
      <FullLayout>
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <Link to="/">
              <div className="logo" title={title} />
            </Link>
            <SiderMenu />
          </Sider>
          <Layout className="site-layout">
            <HeaderWrapper className="site-layout-background">
              <span className="trigger" onClick={toggleSidebar}>
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </span>
              <UserMenu />
            </HeaderWrapper>
            <ContentWrapper className="site-layout-background">
              <AppRoutes />
            </ContentWrapper>
            <FooterWrapper>AdsWizz &copy;{year}</FooterWrapper>
          </Layout>
        </Layout>
      </FullLayout>
    </Router>
  );
};

export default OverallLayout;
