import React from "react";
import { Space } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";

const Impression = ({ client, adid, eventType }) => {
  // const jobs = useSelector(selectJobs);
  // check if c-imp is not a prev hop, and matches the associated ad imp. If so don't count it as an "impression"
  let impTag = <CheckCircleTwoTone twoToneColor="#52c41a" />;
  // if (eventType === "CONTRACT_IMPRESSION") {
  //   const data = jobs.find(($) => $.eventType === "AD_IMPRESSION");
  //   const adImpFound =
  //     data &&
  //     data.result &&
  //     data.result.find(($) => $.client === client && $.adid === adid);
  //
  //   if (adImpFound) impTag = <StopTwoTone twoToneColor="#FFD500" />;
  // }

  return (
    <h5
      style={{
        margin: "10px 0px 10px 0px",
        padding: 0,
      }}
    >
      <Space>
        <span>Impression</span>
        {impTag}
      </Space>
    </h5>
  );
};

export default Impression;
