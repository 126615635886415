import { DatePicker, Form, InputNumber, Space } from "antd";

const dateFormat = "YYYY-MM-DD";

const dateLayout = {
  wrapperCol: {
    offset: 4,
    span: 12,
  },
};

const DateInputFormItem = ({ onFormChange, isAnyJobRunning = false }) => {
  const onDateChange = (date, dateStrings) => {
    onFormChange();
  };

  const onDeltaChange = (value) => {
    onFormChange();
  };

  return (
    <>
      <Form.Item {...dateLayout}>
        <Space>
          <Form.Item
            label="Date"
            name="date"
            rules={[
              {
                required: true,
                message: "A date is required",
              },
            ]}
            style={{ marginLeft: 15 }}
          >
            <DatePicker
              format={dateFormat}
              onChange={onDateChange}
              disabled={isAnyJobRunning}
            />
          </Form.Item>

          <Form.Item colon={false} label={"+/-"} name="delta">
            <InputNumber
              min={0}
              max={10}
              defaultValue={0}
              size="medium"
              keyboard="true"
              onChange={onDeltaChange}
              disabled={isAnyJobRunning}
            />
          </Form.Item>
          <Form.Item // TODO fix this hack
            label="day(s)"
            colon={false}
          />
        </Space>
      </Form.Item>
    </>
  );
};

export default DateInputFormItem;
