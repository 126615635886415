import { Tag } from "antd";
import React from "react";

const ZoneTypeTag = ({ type }) => {
  const style = {
    fontSize: "10px",
    margin: "0 5px",
    padding: "0 1px",
  };

  if (type === "AUDIO")
    return (
      <Tag color="purple" style={style}>
        {type}
      </Tag>
    );
  else if (type === "DISPLAY")
    return (
      <Tag color="geekblue" style={style}>
        {type}
      </Tag>
    );
  else
    return (
      <Tag color="red" style={style}>
        {type}
      </Tag>
    );
};

export default ZoneTypeTag;
