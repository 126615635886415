const MySqlLanguageConfiguration = {
  keywords: [
    //'ACCESSIBLE', 'ACCOUNT', 'ACTION', 'ADD', 'AFTER', 'AGAINST', 'AGGREGATE', 'ALGORITHM', 'ALL', 'ALTER', 'ALWAYS', 'ANALYSE', 'ANALYZE',
    //'AND',
    //'ANY',
    "AS",
    "ASC",
    "ASCII",
    //'ASENSITIVE', 'AT', 'AUTOEXTEND_SIZE', 'AUTO_INCREMENT',
    "AVG",
    //'AVG_ROW_LENGTH', 'BACKUP', 'BEFORE', 'BEGIN',
    //'BETWEEN',
    "BIGINT",
    "BINARY",
    //'BINLOG', 'BIT', 'BLOB', 'BLOCK', 'BOOL',
    "BOOLEAN",
    //'BOTH', 'BTREE',
    "BY",
    "BYTE",
    //'CACHE', 'CALL', 'CASCADE',
    //'CASCADED',
    "CASE",
    //'CATALOG_NAME', 'CHAIN', 'CHANGE', 'CHANGED', 'CHANNEL',
    "CHAR",
    //'CHARACTER', 'CHARSET', 'CHECK', 'CHECKSUM', 'CIPHER',
    //'CLASS_ORIGIN', 'CLIENT', 'CLOSE',
    "COALESCE",
    //'CODE', 'COLLATE', 'COLLATION', 'COLUMN', 'COLUMNS', 'COLUMN_FORMAT', 'COLUMN_NAME', 'COMMENT',
    //'COMMIT', 'COMMITTED', 'COMPACT', 'COMPLETION', 'COMPRESSED', 'COMPRESSION', 'CONCURRENT', 'CONDITION', 'CONNECTION', 'CONSISTENT', 'CONSTRAINT',
    //'CONSTRAINT_CATALOG', 'CONSTRAINT_NAME', 'CONSTRAINT_SCHEMA', 'CONTAINS', 'CONTEXT', 'CONTINUE',
    "CONVERT",
    //'CPU', 'CREATE',
    "CROSS",
    "CUBE",
    //'CURRENT', 'CURRENT_DATE', 'CURRENT_TIME', 'CURRENT_TIMESTAMP', 'CURRENT_USER', 'CURSOR', 'CURSOR_NAME', 'DATA', 'DATABASE', 'DATABASES',
    //'DATAFILE',
    "DATE",
    "DATETIME",
    "DAY",
    "DAY_HOUR",
    "DAY_MICROSECOND",
    "DAY_MINUTE",
    "DAY_SECOND",
    //'DEALLOCATE',
    "DEC",
    "DECIMAL",
    //'DECLARE',
    "DEFAULT",
    //'DEFAULT_AUTH', 'DEFINER', 'DELAYED', 'DELAY_KEY_WRITE', 'DELETE',
    "DESC",
    //'DESCRIBE', 'DES_KEY_FILE', 'DETERMINISTIC', 'DIAGNOSTICS',
    //'DIRECTORY', 'DISABLE', 'DISCARD', 'DISK',
    "DISTINCT",
    //'DISTINCTROW',
    "DIV",
    //'DO',
    "DOUBLE",
    //'DROP', 'DUAL', 'DUMPFILE', 'DUPLICATE', 'DYNAMIC',
    //'EACH',
    "ELSE",
    "ELSEIF",
    //'ENABLE', 'ENCLOSED', 'ENCRYPTION',
    "END",
    //'ENDS', 'ENGINE', 'ENGINES', 'ENUM',
    "ERROR",
    "ERRORS",
    //'ESCAPE', 'ESCAPED',
    //'EVENT', 'EVENTS', 'EVERY', 'EXCHANGE', 'EXECUTE',
    "EXISTS",
    "EXIT",
    //'EXPANSION', 'EXPIRE', 'EXPLAIN', 'EXPORT', 'EXTENDED', 'EXTENT_SIZE',
    "FALSE",
    //'FAST', 'FAULTS', 'FETCH', 'FIELDS', 'FILE', 'FILE_BLOCK_SIZE', 'FILTER', 'FIRST', 'FIXED',
    "FLOAT",
    //'FLOAT4', 'FLOAT8', 'FLUSH',
    //'FOLLOWS', 'FOR', 'FORCE', 'FOREIGN', 'FORMAT', 'FOUND',
    "FROM",
    //'FULL', 'FULLTEXT', 'FUNCTION', 'GENERAL', 'GENERATED', 'GEOMETRY',
    //'GEOMETRYCOLLECTION', 'GET', 'GET_FORMAT', 'GLOBAL', 'GRANT', 'GRANTS',
    "GROUP",
    //'GROUP_REPLICATION', 'HANDLER',
    "HASH",
    "HAVING",
    //'HELP',
    //'HIGH_PRIORITY', 'HOST', 'HOSTS',
    "HOUR",
    "HOUR_MICROSECOND",
    "HOUR_MINUTE",
    "HOUR_SECOND",
    //'IDENTIFIED',
    "IF",
    //'IGNORE', 'IGNORE_SERVER_IDS',
    //'IMPORT', 'INDEX', 'INDEXES', 'INFILE', 'INITIAL_SIZE', 'INNER', 'INOUT', 'INSENSITIVE', 'INSERT', 'INSERT_METHOD', 'INSTALL', 'INSTANCE', 'INT',
    //'INT1', 'INT2', 'INT3', 'INT4', 'INT8',
    "INTEGER",
    //'INTERVAL',
    "INTO",
    //'INVOKER', 'IO', 'IO_AFTER_GTIDS', 'IO_BEFORE_GTIDS', 'IO_THREAD', 'IPC',
    //'ISOLATION', 'ISSUER', 'ITERATE',
    //'JOIN',
    //'JSON', 'KEY', 'KEYS', 'KEY_BLOCK_SIZE', 'KILL', 'LANGUAGE', 'LAST', 'LEADING', 'LEAVE', 'LEAVES',
    //'LEFT',
    "LESS",
    //'LEVEL',
    //'LIKE',
    "LIMIT",
    //'LINEAR', 'LINES', 'LINESTRING', 'LIST', 'LOAD', 'LOCAL',
    "LOCALTIME",
    "LOCALTIMESTAMP",
    //'LOCK',
    //'LOCKS', 'LOGFILE', 'LOGS',
    "LONG",
    //'LONGBLOB', 'LONGTEXT', 'LOOP', 'LOW_PRIORITY',
    //'MASTER', 'MASTER_AUTO_POSITION', 'MASTER_BIND',
    //'MASTER_CONNECT_RETRY', 'MASTER_DELAY', 'MASTER_HEARTBEAT_PERIOD', 'MASTER_HOST', 'MASTER_LOG_FILE', 'MASTER_LOG_POS', 'MASTER_PASSWORD',
    //'MASTER_PORT', 'MASTER_RETRY_COUNT', 'MASTER_SERVER_ID', 'MASTER_SSL', 'MASTER_SSL_CA', 'MASTER_SSL_CAPATH', 'MASTER_SSL_CERT',
    //'MASTER_SSL_CIPHER', 'MASTER_SSL_CRL', 'MASTER_SSL_CRLPATH', 'MASTER_SSL_KEY', 'MASTER_SSL_VERIFY_SERVER_CERT', 'MASTER_TLS_VERSION',
    //'MASTER_USER',
    "MATCH",
    "MAXVALUE",
    //'MAX_CONNECTIONS_PER_HOUR', 'MAX_QUERIES_PER_HOUR', 'MAX_ROWS', 'MAX_SIZE', 'MAX_STATEMENT_TIME',
    //'MAX_UPDATES_PER_HOUR', 'MAX_USER_CONNECTIONS', 'MEDIUM', 'MEDIUMBLOB', 'MEDIUMINT', 'MEDIUMTEXT', 'MEMORY', 'MERGE', 'MESSAGE_TEXT',
    //'MICROSECOND', 'MIDDLEINT', 'MIGRATE',
    "MINUTE",
    //'MINUTE_MICROSECOND', 'MINUTE_SECOND', 'MIN_ROWS', 'MOD', 'MODE', 'MODIFIES', 'MODIFY',
    "MONTH",
    //'MULTILINESTRING', 'MULTIPOINT', 'MULTIPOLYGON', 'MUTEX', 'MYSQL_ERRNO', 'NAME', 'NAMES', 'NATIONAL', 'NATURAL', 'NCHAR', 'NDB', 'NDBCLUSTER',
    //'NEVER', 'NEW', 'NEXT', 'NO', 'NODEGROUP', 'NONBLOCKING', 'NONE', 'NO_WAIT', 'NO_WRITE_TO_BINLOG',
    "NUMBER",
    "NUMERIC",
    //'NVARCHAR',
    "OFFSET",
    //'OLD_PASSWORD',
    "ON",
    //'ONE', 'ONLY', 'OPEN', 'OPTIMIZE', 'OPTIMIZER_COSTS', 'OPTION', 'OPTIONALLY', 'OPTIONS',
    //'OR',
    "ORDER",
    //'OUT',
    //'OUTER',
    //'OUTFILE', 'OWNER', 'PACK_KEYS', 'PAGE', 'PARSER', 'PARSE_GCOL_EXPR', 'PARTIAL', 'PARTITION', 'PARTITIONING', 'PARTITIONS', 'PASSWORD', 'PHASE',
    //'PLUGIN', 'PLUGINS', 'PLUGIN_DIR', 'POINT', 'POLYGON', 'PORT', 'PRECEDES', 'PRECISION', 'PREPARE', 'PRESERVE', 'PREV', 'PRIMARY', 'PRIVILEGES',
    //'PROCEDURE', 'PROCESSLIST', 'PROFILE', 'PROFILES', 'PROXY', 'PURGE', 'QUARTER', 'QUERY', 'QUICK',
    "RANGE",
    //'READ', 'READS', 'READ_ONLY',
    //'READ_WRITE', 'REAL', 'REBUILD', 'RECOVER', 'REDOFILE', 'REDO_BUFFER_SIZE', 'REDUNDANT', 'REFERENCES', 'REGEXP', 'RELAY', 'RELAYLOG',
    //'RELAY_LOG_FILE', 'RELAY_LOG_POS', 'RELAY_THREAD', 'RELEASE', 'RELOAD', 'REMOVE', 'RENAME', 'REORGANIZE', 'REPAIR', 'REPEAT', 'REPEATABLE',
    //'REPLACE', 'REPLICATE_DO_DB', 'REPLICATE_DO_TABLE', 'REPLICATE_IGNORE_DB', 'REPLICATE_IGNORE_TABLE', 'REPLICATE_REWRITE_DB',
    //'REPLICATE_WILD_DO_TABLE', 'REPLICATE_WILD_IGNORE_TABLE', 'REPLICATION', 'REQUIRE', 'RESET', 'RESIGNAL', 'RESTORE', 'RESTRICT', 'RESUME',
    //'RETURN', 'RETURNED_SQLSTATE', 'RETURNS', 'REVERSE', 'REVOKE',
    //'RIGHT',
    //'RLIKE', 'ROLLBACK',
    "ROLLUP",
    //'ROTATE', 'ROUTINE',
    "ROW",
    "ROWS",
    //'ROW_COUNT', 'ROW_FORMAT', 'RTREE', 'SAVEPOINT', 'SCHEDULE', 'SCHEMA', 'SCHEMAS', 'SCHEMA_NAME',
    "SECOND",
    //'SECOND_MICROSECOND', 'SECURITY',
    "SELECT",
    //'SENSITIVE',
    "SEPARATOR",
    //'SERIAL', 'SERIALIZABLE', 'SERVER', 'SESSION', 'SET', 'SHARE', 'SHOW', 'SHUTDOWN', 'SIGNAL', 'SIGNED',
    //'SIMPLE', 'SLAVE', 'SLOW',
    "SMALLINT",
    //'SNAPSHOT', 'SOCKET', 'SOME', 'SONAME', 'SOUNDS', 'SOURCE', 'SPATIAL', 'SPECIFIC',
    "SQL",
    //'SQLEXCEPTION',
    //'SQLSTATE', 'SQLWARNING', 'SQL_AFTER_GTIDS', 'SQL_AFTER_MTS_GAPS', 'SQL_BEFORE_GTIDS', 'SQL_BIG_RESULT', 'SQL_BUFFER_RESULT', 'SQL_CACHE',
    //'SQL_CALC_FOUND_ROWS', 'SQL_NO_CACHE', 'SQL_SMALL_RESULT', 'SQL_THREAD', 'SQL_TSI_DAY', 'SQL_TSI_HOUR', 'SQL_TSI_MINUTE', 'SQL_TSI_MONTH',
    //'SQL_TSI_QUARTER', 'SQL_TSI_SECOND', 'SQL_TSI_WEEK', 'SQL_TSI_YEAR', 'SSL', 'STACKED', 'START', 'STARTING', 'STARTS', 'STATS_AUTO_RECALC',
    //'STATS_PERSISTENT', 'STATS_SAMPLE_PAGES', 'STATUS', 'STOP', 'STORAGE', 'STORED', 'STRAIGHT_JOIN', 'STRING', 'SUBCLASS_ORIGIN', 'SUBJECT',
    //'SUBPARTITION', 'SUBPARTITIONS', 'SUPER', 'SUSPEND', 'SWAPS', 'SWITCHES', 'TABLE', 'TABLES', 'TABLESPACE', 'TABLE_CHECKSUM', 'TABLE_NAME',
    //'TEMPORARY', 'TEMPTABLE', 'TERMINATED', 'TEXT',
    // 'THAN', 'THEN', 'TIME', 'TIMESTAMP',
    // 'TIMESTAMPADD', 'TIMESTAMPDIFF', 'TINYBLOB',
    "TINYINT",
    //'TINYTEXT',
    "TO",
    //'TRAILING', 'TRANSACTION', 'TRIGGER', 'TRIGGERS',
    "TRUE",
    "TRUNCATE",
    "TYPE",
    "TYPES",
    //'UNCOMMITTED', 'UNDEFINED', 'UNDO',
    //'UNDOFILE', 'UNDO_BUFFER_SIZE', 'UNICODE', 'UNINSTALL',
    "UNION",
    //'UNIQUE', 'UNKNOWN', 'UNLOCK', 'UNSIGNED', 'UNTIL', 'UPDATE', 'UPGRADE',
    //'USAGE', 'USE', 'USER', 'USER_RESOURCES', 'USE_FRM', 'USING',
    "UTC_DATE",
    "UTC_TIME",
    "UTC_TIMESTAMP",
    //'VALIDATION', 'VALUE', 'VALUES',
    //'VARBINARY',
    "VARCHAR",
    //'VARCHARACTER', 'VARIABLES', 'VARYING', 'VIEW', 'VIRTUAL', 'WAIT', 'WARNINGS', 'WEEK', 'WEIGHT_STRING',
    "WHEN",
    "WHERE",
    //'WHILE',
    "WITH",
    //'WITHOUT', 'WORK', 'WRAPPER', 'WRITE', 'X509', 'XA', 'XID', 'XML', 'XOR',
    "YEAR",
    "YEAR_MONTH",
    //, 'ZEROFILL'
  ],
  operators: [
    "AND",
    "BETWEEN",
    "IN",
    "LIKE",
    "NOT",
    "OR",
    "IS",
    "NULL",
    "INTERSECT",
    "UNION",
    "INNER",
    "JOIN",
    "LEFT",
    "OUTER",
    "RIGHT",
  ],
  builtinFunctions: [
    "ABS",
    "ACOS",
    "ADDDATE",
    "ADDTIME",
    //'AES_DECRYPT', 'AES_ENCRYPT',
    "ANY_VALUE",
    "Area",
    "AsBinary",
    "AsWKB",
    "ASCII",
    "ASIN",
    "AsText",
    "AsWKT",
    //'ASYMMETRIC_DECRYPT', 'ASYMMETRIC_DERIVE', 'ASYMMETRIC_ENCRYPT', 'ASYMMETRIC_SIGN', 'ASYMMETRIC_VERIFY',
    "ATAN",
    "ATAN2",
    "ATAN",
    "AVG",
    //'BENCHMARK',
    "BIN",
    "BIT_AND",
    "BIT_COUNT",
    "BIT_LENGTH",
    "BIT_OR",
    "BIT_XOR",
    //'Buffer',
    "CAST",
    "CEIL",
    "CEILING",
    "Centroid",
    "CHAR",
    //'CHAR_LENGTH', 'CHARACTER_LENGTH', 'CHARSET', 'COALESCE', 'COERCIBILITY', 'COLLATION', 'COMPRESS',
    "CONCAT",
    //'CONCAT_WS', 'CONNECTION_ID',
    "Contains",
    "CONV",
    "CONVERT",
    //'CONVERT_TZ', 'ConvexHull',
    "COS",
    "COT",
    "COUNT",
    //'CRC32', 'CREATE_ASYMMETRIC_PRIV_KEY',
    //'CREATE_ASYMMETRIC_PUB_KEY', 'CREATE_DH_PARAMETERS', 'CREATE_DIGEST',
    "Crosses",
    "CURDATE",
    "CURRENT_DATE",
    "CURRENT_TIME",
    "CURRENT_TIMESTAMP",
    //'CURRENT_USER',
    // 'CURTIME', 'DATABASE',
    "DATE",
    "DATE_ADD",
    "DATE_FORMAT",
    "DATE_SUB",
    "DATEDIFF",
    "DAY",
    "DAYNAME",
    "DAYOFMONTH",
    "DAYOFWEEK",
    "DAYOFYEAR",
    "DECODE",
    //'DEFAULT', 'DEGREES', 'DES_DECRYPT', 'DES_ENCRYPT', 'Dimension', 'Disjoint', 'Distance', 'ELT', 'ENCODE', 'ENCRYPT',
    //'EndPoint', 'Envelope',
    "Equals",
    "EXP",
    //'EXPORT_SET', 'ExteriorRing',
    "EXTRACT",
    //'ExtractValue',
    "FIELD",
    //'FIND_IN_SET',
    "FLOOR",
    "FORMAT",
    //'FOUND_ROWS',
    "FROM_BASE64",
    "FROM_DAYS",
    "FROM_UNIXTIME",
    //'GeomCollFromText', 'GeometryCollectionFromText', 'GeomCollFromWKB',
    //'GeometryCollectionFromWKB', 'GeometryCollection', 'GeometryN', 'GeometryType', 'GeomFromText', 'GeometryFromText', 'GeomFromWKB',
    //'GeometryFromWKB', 'GET_FORMAT', 'GET_LOCK', 'GLength',
    "GREATEST",
    //'GROUP_CONCAT', 'GTID_SUBSET', 'GTID_SUBTRACT',
    "HEX",
    "HOUR",
    "IF",
    "IFNULL",
    //'INET_ATON', 'INET_NTOA', 'INET6_ATON', 'INET6_NTOA', 'INSERT', 'INSTR', 'InteriorRingN', 'Intersects', 'INTERVAL', 'IS_FREE_LOCK',
    //'IS_IPV4', 'IS_IPV4_COMPAT', 'IS_IPV4_MAPPED', 'IS_IPV6', 'IS_USED_LOCK', 'IsClosed', 'IsEmpty',
    "ISNULL",
    //'IsSimple',
    "JSON_APPEND",
    "JSON_ARRAY",
    "JSON_ARRAY_APPEND",
    "JSON_ARRAY_INSERT",
    "JSON_CONTAINS",
    "JSON_CONTAINS_PATH",
    "JSON_DEPTH",
    "JSON_EXTRACT",
    "JSON_INSERT",
    "JSON_KEYS",
    "JSON_LENGTH",
    "JSON_MERGE",
    "JSON_MERGE_PRESERVE",
    "JSON_OBJECT",
    "JSON_QUOTE",
    "JSON_REMOVE",
    "JSON_REPLACE",
    "JSON_SEARCH",
    "JSON_SET",
    "JSON_TYPE",
    "JSON_UNQUOTE",
    "JSON_VALID",
    //'LAST_INSERT_ID', 'LCASE',
    "LEAST",
    "LEFT",
    "LENGTH",
    //'LineFromText',
    //'LineStringFromText', 'LineFromWKB', 'LineStringFromWKB', 'LineString', 'LN', 'LOAD_FILE',
    "LOCALTIME",
    "LOCALTIMESTAMP",
    "LOCATE",
    //'LOG',
    //'LOG10', 'LOG2',
    "LOWER",
    "LPAD",
    "LTRIM",
    //'MAKE_SET', 'MAKEDATE', 'MAKETIME', 'MASTER_POS_WAIT',
    "MAX",
    //'MBRContains', 'MBRCoveredBy',
    //'MBRCovers', 'MBRDisjoint', 'MBREqual', 'MBREquals', 'MBRIntersects', 'MBROverlaps', 'MBRTouches', 'MBRWithin',
    "MD5",
    "MICROSECOND",
    "MID",
    "MIN",
    "MINUTE",
    //'MLineFromText', 'MultiLineStringFromText', 'MLineFromWKB', 'MultiLineStringFromWKB',
    "MOD",
    "MONTH",
    "MONTHNAME",
    //'MPointFromText', 'MultiPointFromText', 'MPointFromWKB', 'MultiPointFromWKB', 'MPolyFromText', 'MultiPolygonFromText', 'MPolyFromWKB',
    //'MultiPolygonFromWKB', 'MultiLineString', 'MultiPoint', 'MultiPolygon', 'NAME_CONST',
    "NOT IN",
    "NOW",
    "NULLIF",
    //'NumGeometries',
    //'NumInteriorRings', 'NumPoints', 'OCT', 'OCTET_LENGTH', 'OLD_PASSWORD',
    "ORD",
    //'Overlaps', 'PASSWORD', 'PERIOD_ADD', 'PERIOD_DIFF', 'PI',
    //'Point', 'PointFromText', 'PointFromWKB', 'PointN', 'PolyFromText', 'PolygonFromText', 'PolyFromWKB', 'PolygonFromWKB', 'Polygon', 'POSITION',
    "POW",
    "POWER",
    //'PROCEDURE ANALYSE',
    "QUARTER",
    //'QUOTE', 'RADIANS',
    "RAND",
    //'RANDOM_BYTES', 'RELEASE_ALL_LOCKS', 'RELEASE_LOCK', 'REPEAT',
    "REPLACE",
    "REVERSE",
    "RIGHT",
    "ROUND",
    //'ROW_COUNT',
    "RPAD",
    "RTRIM",
    //'SCHEMA',
    "SEC_TO_TIME",
    "SECOND",
    //'SESSION_USER',
    "SHA1",
    "SHA",
    "SHA2",
    "SIGN",
    "SIN",
    //'SLEEP', 'SOUNDEX', 'SPACE',
    "SQRT",
    //'SRID',
    // 'ST_Area', 'ST_AsBinary', 'ST_AsWKB', 'ST_AsGeoJSON', 'ST_AsText', 'ST_AsWKT',
    // 'ST_Buffer', 'ST_Buffer_Strategy', 'ST_Centroid', 'ST_Contains', 'ST_ConvexHull', 'ST_Crosses', 'ST_Difference', 'ST_Dimension', 'ST_Disjoint',
    // 'ST_Distance', 'ST_Distance_Sphere', 'ST_EndPoint', 'ST_Envelope', 'ST_Equals', 'ST_ExteriorRing', 'ST_GeoHash', 'ST_GeomCollFromText',
    // 'ST_GeometryCollectionFromText', 'ST_GeomCollFromTxt', 'ST_GeomCollFromWKB', 'ST_GeometryCollectionFromWKB', 'ST_GeometryN', 'ST_GeometryType',
    // 'ST_GeomFromGeoJSON', 'ST_GeomFromText', 'ST_GeometryFromText', 'ST_GeomFromWKB', 'ST_GeometryFromWKB', 'ST_InteriorRingN', 'ST_Intersection',
    // 'ST_Intersects', 'ST_IsClosed', 'ST_IsEmpty', 'ST_IsSimple', 'ST_IsValid', 'ST_LatFromGeoHash', 'ST_Length', 'ST_LineFromText',
    // 'ST_LineStringFromText', 'ST_LineFromWKB', 'ST_LineStringFromWKB', 'ST_LongFromGeoHash', 'ST_MakeEnvelope', 'ST_MLineFromText',
    // 'ST_MultiLineStringFromText', 'ST_MLineFromWKB', 'ST_MultiLineStringFromWKB', 'ST_MPointFromText', 'ST_MultiPointFromText', 'ST_MPointFromWKB',
    // 'ST_MultiPointFromWKB', 'ST_MPolyFromText', 'ST_MultiPolygonFromText', 'ST_MPolyFromWKB', 'ST_MultiPolygonFromWKB', 'ST_NumGeometries',
    // 'ST_NumInteriorRing', 'ST_NumInteriorRings', 'ST_NumPoints', 'ST_Overlaps', 'ST_PointFromGeoHash', 'ST_PointFromText', 'ST_PointFromWKB',
    // 'ST_PointN', 'ST_PolyFromText', 'ST_PolygonFromText', 'ST_PolyFromWKB', 'ST_PolygonFromWKB', 'ST_Simplify', 'ST_SRID', 'ST_StartPoint',
    // 'ST_SymDifference', 'ST_Touches', 'ST_Union', 'ST_Validate', 'ST_Within', 'ST_X', 'ST_Y',
    //'StartPoint',
    "STD",
    "STDDEV",
    "STDDEV_POP",
    "STDDEV_SAMP",
    "STR_TO_DATE",
    "STRCMP",
    "SUBDATE",
    "SUBSTR",
    "SUBSTRING",
    "SUBSTRING_INDEX",
    "SUBTIME",
    "SUM",
    //'SYSDATE', 'SYSTEM_USER',
    "TAN",
    "TIME",
    "TIME_FORMAT",
    "TIME_TO_SEC",
    "TIMEDIFF",
    "TIMESTAMP",
    "TIMESTAMPADD",
    "TIMESTAMPDIFF",
    "TO_BASE64",
    "TO_DAYS",
    "TO_SECONDS",
    //'Touches',
    "TRIM",
    "TRUNCATE",
    "UCASE",
    //'UNCOMPRESS', 'UNCOMPRESSED_LENGTH', 'UNHEX',
    "UNIX_TIMESTAMP",
    //'UpdateXML',
    "UPPER",
    //'USER',
    "UTC_DATE",
    "UTC_TIME",
    "UTC_TIMESTAMP",
    "UUID",
    "UUID_SHORT",
    //'VALIDATE_PASSWORD_STRENGTH',
    "VALUES",
    //'VAR_POP', 'VAR_SAMP',
    "VARIANCE",
    //'VERSION',
    //'WAIT_FOR_EXECUTED_GTID_SET', 'WAIT_UNTIL_SQL_THREAD_AFTER_GTIDS',
    "WEEK",
    "WEEKDAY",
    "WEEKOFYEAR",
    //'WEIGHT_STRING', 'Within', 'X', 'Y',
    "YEAR",
    "YEARWEEK",
  ],
  builtinVariables: [
    /* NOT SUPPORTED*/
  ],
};

const MySqlSuggestionsHelper = {
  build: (monaco) => {
    const template = {
      label: "<placeholder>",
      kind: monaco.languages.CompletionItemKind.Property,
      insertText: "<placeholder>",
    };

    const map1 = MySqlLanguageConfiguration.keywords.map(($) => {
      return {
        ...template,
        label: $,
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: $,
      };
    });

    const map2 = MySqlLanguageConfiguration.operators.map(($) => {
      return {
        ...template,
        label: $,
        kind: monaco.languages.CompletionItemKind.Operator,
        insertText: $,
      };
    });

    const map3 = MySqlLanguageConfiguration.builtinFunctions.map(($) => {
      return {
        ...template,
        label: $,
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: $,
      };
    });

    return [...map1, ...map2, ...map3];
  },
};

export default MySqlSuggestionsHelper;
