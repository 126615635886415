import React, { useState, useEffect } from "react";
import { Form, Select } from "antd";
import { serviceLocator } from "../../../services/ServiceLocatorImpl";

const { Option } = Select;

const fetchEventTypeList = ({ setData, setError }) => {
  const globalConfiguration = serviceLocator.getGlobalConfiguration();

  return serviceLocator
    .getApiService()
    .get(
      `${globalConfiguration.pathBase}${globalConfiguration.referenceDataConfig.eventTypeListEndpoint}`
    )
    .then((result) => result.data)
    .then((data) =>
      data.sort((a, b) => a.type.display.localeCompare(b.type.display))
    )
    .then(setData)
    .catch(setError);
};

export const SelectEventTypeItem = ({
  onFormChange,
  jobStarted,
  jobOver,
  onReset,
}) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    !(data || error) && fetchEventTypeList({ setData, setError });
  }, [data, error]);

  const onChange = (value) => {
    onReset();
    onFormChange();
  };

  return (
    <Form.Item
      name="eventType"
      label="Event Type"
      rules={[
        {
          required: true,
          message: "'Event Type' is required",
        },
      ]}
    >
      <Select
        disabled={jobStarted && !jobOver}
        showSearch
        allowClear
        style={{
          width: 200,
        }}
        placeholder="Select an event type"
        onChange={onChange}
      >
        {data &&
          data.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.type.display}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};
