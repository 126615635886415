const EventTypeFormatHelper = {
  //TODO fetch it from API
  toPrettyEventType: (eventType) => {
    if (!eventType) return "-";

    switch (eventType) {
      case "AD_IMPRESSION":
        return "Impression";
      case "AD_CLICK":
        return "Click";
      case "CONTRACT_IMPRESSION":
        return "Contract-impression";
      case "AD_OPPORTUNITY":
        return "Opportunity"; // deprecated
      case "OPPORTUNITY":
        return "Opportunity";
      case "OPPORTUNITY_V3":
        return "Opportunity"; // temporary during the migration
      case "AD_REPORT":
        return "Report";
      case "CONTRACT_REPORT":
        return "Contract-report";
      case "BIDS":
        return "Bids";
      case "THIRD_PARTY_TRACKING":
        return "Third-party-tracking";
      case "ZONE_REQUEST":
        return "Zone-request";
      default:
        return "Unknown" + eventType;
    }
  },
};

export default EventTypeFormatHelper;
