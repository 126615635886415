/**
 * This class implements LobsterApiService interface.
 * It utilizes the ApiService to perform HTTP requests to job-related API endpoints.
 * Methods in this class handle initializing jobs, fetching job details, and retrieving job results.
 */

import { LobsterApiService } from "./LobsterApiService";
import { serviceLocator } from "../ServiceLocatorImpl";

class LobsterApiServiceImpl implements LobsterApiService {
  /**
   * Sends a POST request to initialize a new job with the provided payload.
   * Uses the ApiService to handle the HTTP interaction.
   *
   * @param {any} payload - The payload data required to initialize the job.
   * @returns {Promise<any>} - A promise that resolves with the response data from the job initialization request.
   */
  async initializeJob(payload: any): Promise<any> {
    //TODO uses global config for endpoint info...
    return serviceLocator.getApiService().post("/jobs/dataExport", payload);
  }

  /**
   * Sends a GET request to retrieve details of a specific job using its unique identifier.
   *
   * @param {string} jobUid - The unique identifier of the job to fetch.
   * @returns {Promise<any>} - A promise that resolves with the response data containing job details.
   */
  async fetchJob(jobUid: string): Promise<any> {
    //TODO uses global config for endpoint info...
    return serviceLocator.getApiService().get(`/jobs/${jobUid}`);
  }

  /**
   * Sends a GET request to fetch the result of a job execution.
   * Supports pagination by allowing a page number to be specified.
   *
   * @param {string} queryExecutionId - The unique identifier for the job's query execution.
   * @param {number} [page=0] - Optional page number for paginated results, defaults to 0.
   * @returns {Promise<any>} - A promise that resolves with the response data containing the job result.
   */
  async fetchJobResult(
    queryExecutionId: string,
    page: number = 0
  ): Promise<any> {
    //TODO uses global config for endpoint info...
    return serviceLocator
      .getApiService()
      .get(
        `/query/query-execution/content/id/${queryExecutionId}?page=${page}&size=1000`
      );
  }
}

export { LobsterApiServiceImpl };
