import DateHelper from "./DateHelper";

const dateTimeFormatter = new Intl.DateTimeFormat("default", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: false,
  timeZone: "UTC",
});

const ymdFormatter = new Intl.DateTimeFormat("default", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  timeZone: "UTC",
});

const mdFormatter = new Intl.DateTimeFormat("default", {
  month: "numeric",
  day: "numeric",
  timeZone: "UTC",
});

const dFormatter = new Intl.DateTimeFormat("default", {
  day: "numeric",
  timeZone: "UTC",
});

const DateFormatHelper = {
  toPrettyDate: (dateRange) => {
    if (!dateRange || !Array.isArray(dateRange)) return "NA";

    const startDate = new Date(DateHelper.parseDateTime(dateRange[0]));
    const endDate = new Date(DateHelper.parseDateTime(dateRange[1]));

    let prettyDateRange = "";
    if (startDate.getUTCFullYear() !== endDate.getUTCFullYear()) {
      prettyDateRange = ymdFormatter.format(startDate) + " to ";
    } else if (startDate.getUTCMonth() !== endDate.getUTCMonth()) {
      prettyDateRange = mdFormatter.format(startDate) + " to ";
    } else if (startDate.getUTCDay() !== endDate.getUTCDay()) {
      prettyDateRange = dFormatter.format(startDate) + " to ";
    }
    prettyDateRange = prettyDateRange + ymdFormatter.format(endDate);

    return prettyDateRange;
  },

  toPrettyDatetime: (datetimeValue) => {
    return (
      datetimeValue &&
      dateTimeFormatter.format(DateHelper.parseDateTime(datetimeValue))
    );
  },
};

export default DateFormatHelper;
