import ObjectHelper from "../../../../utils/ObjectHelper";
import Comparator from "../helper/Comparator";

const OpportunityModel = {
  // model structure example:
  // opps = [
  //  {
  //    oppEvents: [...],
  //    trailObject: object,
  //    nodes: [props..., nodes:[...], bids:[...]]
  //  },
  //  {...},
  //  ...
  // ]
  OPPS_MODEL_CONTEXT: {
    oppEvents: undefined, // raw format from the API, it contains 1 entry and possible duplicates coming from "AIS batch processing..."
    trailObject: undefined, // trail object
    nodes: undefined, // main nodes entrypoint, array of Node objects, From: trail -> trailObject -> tree of opp nodes (typically 0 0/0 0/0/1 0/1 + consider 1 1/0)
  },

  NODE_MODEL_PROTOTYPE: {
    nodes: undefined, // reflection association // child opps of the trail
    bids: undefined, // from the linkage
  },

  BID_MODEL_CONTEXT: {
    bidEvent: undefined,
    bidresponsesv2Object: undefined, // every item contains a boolean 'matched' which is set to true if it matches with an opp node, false otherwise (default)
  },

  ZONE_ROLL_TYPE_ORDER: ["PRE_ROLL", "MID_ROLL", "POST_ROLL", "UNDEFINED"],

  zoneRollTypeComp: (a, b) =>
    Comparator.listBasedComp(
      a.zonerolltype,
      b.zonerolltype,
      OpportunityModel.ZONE_ROLL_TYPE_ORDER
    ),

  topNodeOpportunityComparator: (a, b) => {
    return Comparator.multipleItemComp(
      a.nodes && a.nodes.length > 0 ? a.nodes[0] : [],
      b.nodes && b.nodes.length > 0 ? b.nodes[0] : [],
      [
        {
          name: "zonerolltype",
          orderList: OpportunityModel.ZONE_ROLL_TYPE_ORDER,
        },
        {
          name: "client",
          orderList: [],
        },
      ]
    );
  },

  nodeOpportunityComparator: (a, b) => {
    return Comparator.multipleItemComp(a, b, [
      {
        name: "zonerolltype",
        orderList: OpportunityModel.ZONE_ROLL_TYPE_ORDER,
      },
      {
        name: "client",
        orderList: [],
      },
    ]);
  },

  populateModel: (oppsEvents, bidsEvents) => {
    const opps = OpportunityModel.toOppsModel(oppsEvents);
    // console.log(JSON.stringify(opps));
    const bids = OpportunityModel.toBidsModel(bidsEvents);

    OpportunityModel.doOppsBidsLinkage(opps, bids); // associate every bidsresponsesV2 and opp nodes

    OpportunityModel.sortBids(opps);

    //TODO isolate in retrieveUnmappedBidResponses();
    const unmappedBidResponses = [];
    if (Array.isArray(bids)) {
      bids.forEach(($) => {
        if ($.bidresponsesv2Object) {
          $.bidresponsesv2Object.forEach(($$) => {
            const check = $$.matched;
            if (!check) {
              unmappedBidResponses.push({ ...$$, client: $.bidEvent.client });
            }
          });
        }
      });
    }

    return [opps, unmappedBidResponses];
  },

  toOppsModel: (oppEvents) => {
    if (!oppEvents) return null;

    // must work with the trail item to keep the opportunities of the same context together

    // normalize opps: remove duplicated events (reminder - query without unnesting the trail prop) TODO check if the remove is a good approach ?!
    const trailSet = new Set();
    oppEvents.forEach(($) => trailSet.add($.trail));

    const oppsModel = [...trailSet].map((trail) => {
      const events = oppEvents.filter(($$) => $$.trail === trail);

      // convert trail prop to trail object
      const trailObject = JSON.parse(
        ObjectHelper.convertPseudoObjectToJsonText(trail)
      );
      // map the trail object to a parent-child tree using NODE_PROTOTYPE
      const nodes = OpportunityModel.flatTrailToTreeNode(trailObject);

      return {
        ...OpportunityModel.OPPS_MODEL_CONTEXT,
        oppEvents: events, // (content unchanged!), typically a single event (nested) + potentially "duplicates" (has been observed, seems due to AIS batch processing...). We just pick one...
        trailObject,
        nodes,
      };
    });

    // recursively sort each level of nodes by zone roll type and name
    return OpportunityModel.sortEvents(oppsModel);
  },

  parseZoneRequestDetailsList: (rawDetailsList) => {
    return JSON.parse(
      ObjectHelper.convertPseudoObjectToJsonText(rawDetailsList)
    );
  },

  sortEvents: (oppsModel) => {
    if (!oppsModel) {
      return oppsModel;
    }
    return oppsModel
      .sort(OpportunityModel.topNodeOpportunityComparator)
      .map((modelItem) => {
        return {
          ...modelItem,
          nodes:
            Array.isArray(modelItem.nodes) && modelItem.nodes.length > 0
              ? OpportunityModel.sortNodes(modelItem.nodes)
              : [],
        };
      });
  },

  sortNodes: (nodes) => {
    if (!nodes) {
      return nodes;
    }
    return nodes.sort(OpportunityModel.nodeOpportunityComparator).map(($) => {
      return {
        ...$,
        nodes:
          Array.isArray($.nodes) && $.nodes.length > 0
            ? OpportunityModel.sortNodes($.nodes)
            : [],
      };
    });
  },

  // we assume the items are ordered from parent to child: 0, 0/0, 0/1, 1/0, 1/1, 1/1/0...
  // root trail value contains 1 item at least
  flatTrailToTreeNode: (initTrail) => {
    const countSlash = (str) => (str.match(/\//g) || []).length;

    // extract all the items from the index position (excluded) until the next item of the same depth (excluded)
    // trail: 0,0/1,0/1/0,0/1/1,0/2 with index 0, and depth 0 gives: 0/1,0/1/0,0/1/1,0/2
    // same trail with index 1, and depth 1 gives: 0/1/0,0/1/1
    const extractSubTrail = (trail, index, depth) => {
      for (let i = index + 1; i < trail.length; i++)
        if (countSlash(trail[i].metanodepath) === depth)
          return trail.slice(index + 1, i);
      return trail.slice(index + 1, undefined);
    };

    // recursion logic
    const visit = (trail, depth, parent) => {
      for (let index = 0; index < trail.length; index++) {
        const iDepth = countSlash(trail[index].metanodepath);

        if (iDepth === depth) {
          const node = {
            ...OpportunityModel.NODE_MODEL_PROTOTYPE,
            ...trail[index],
            nodes: [],
          };

          if (parent.nodes) parent.nodes.push(node);
          else parent.push(node); // root case

          const subTrail = extractSubTrail(trail, index, depth);

          if (subTrail.length > 0) visit(subTrail, depth + 1, node);
        }
      }
      if (parent && parent.nodes && parent.nodes.length > 1)
        parent.nodes.sort(OpportunityModel.zoneRollTypeComp);
    };

    const result = [];
    visit(initTrail, 0, result);

    return result;
  },

  toBidsModel: (bidsEvents) => {
    if (!bidsEvents) return null;

    // copy list and convert bidresponsesv2 prop to bidresponsesv2 object list
    return bidsEvents.map((bidEvent) => {
      const bidresponsesv2Object = JSON.parse(
        ObjectHelper.convertPseudoObjectToJsonText(bidEvent.bidresponsesv2)
      );

      const hydratedBidresponsesv2Object = bidresponsesv2Object.map(($) => ({
        ...$,
        matched: false,
      }));

      const bidModelItem = {
        ...OpportunityModel.BID_MODEL_CONTEXT,
        bidEvent: bidEvent,
        bidresponsesv2Object: hydratedBidresponsesv2Object,
      };

      return bidModelItem;
    });
  },

  doOppsBidsLinkage: (treeOpps, bids) => {
    if (!bids) return;

    const loop = (opps) => {
      opps.forEach((opp) => {
        opp.client &&
          bids.forEach((bid) => {
            if (opp.client === bid.bidEvent.client) {
              const list = bid.bidresponsesv2Object
                .filter(($$) => $$.supplyagencyid === opp.agencyid)
                .filter(($$) => $$.supplypublisherid === opp.publisherid)
                .filter(($$) => $$.zoneid === opp.zoneid)
                .filter(($$) => $$.zonerolltype === opp.zonerolltype);

              const hydratedList = list.map(($) => {
                $.matched = true; // it matches with an opportunity node
                $.buyerbidrejectinformation =
                  bid.bidEvent.buyerbidrejectinformation;
                return $;
              });

              opp.bids = (opp.bids || []).concat(hydratedList);
            }
          });
        opp.nodes && loop(opp.nodes);
      });
    };

    loop(treeOpps);
  },

  compareBidsItems: (a, b) => {
    const multipleItemOrderConfig = [
      {
        ...Comparator.MULTIPLE_ITEM_ORDER_CONFIGURATION,
        name: "status",
        orderList: [
          "WON",
          "BELOW_FLOOR_PRICE",
          "INVALID_CONFIGURATION",
          "LOST_ON_YIELD",
        ],
      },
      {
        ...Comparator.MULTIPLE_ITEM_ORDER_CONFIGURATION,
        name: "pricetype",
        orderList: ["SSP", "AS"],
      },
    ];

    return Comparator.multipleItemComp(a, b, multipleItemOrderConfig);
  },

  sortBids: (treeOpps) => {
    const loop = (opps) => {
      opps.forEach(($) => {
        $.client &&
          $.bids &&
          $.bids.length > 1 &&
          $.bids.sort(OpportunityModel.compareBidsItems);
        $.nodes && loop($.nodes);
      });
    };

    loop(treeOpps);
  },
};

export default OpportunityModel;
