import React, { ReactNode } from "react";
import App from "./App";
import { Provider } from "react-redux";
import store from "../store";
import { Store } from "redux";

// Define the shape of your Redux store if not already typed
// This is optional but recommended if you want a more strongly typed store
interface ReduxProviderWrapperProps {
  children?: ReactNode;
}

// Define the provider wrapper component
const ReduxProviderWrapper: React.FC<ReduxProviderWrapperProps> = () => {
  return (
    <Provider store={store as Store}>
      <App />
    </Provider>
  );
};

export default ReduxProviderWrapper;
