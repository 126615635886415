import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import CommonButton from "../common/CommonButton";
import "./History.css";
import lobsterApiService from "../../services/lobsterApiService";
import useSafeAsync from "../../hooks/useSafeAsync";
import CommonFilter from "../common/CommonFilter";
import JobsTable from "./common/JobsTable";
import { ReloadOutlined } from "@ant-design/icons";
import CornerLoader from "../../utils/CornerLoader";
import { serviceLocator } from "../../services/ServiceLocatorImpl";

const History = ({ history }) => {
  const [results, setResults] = useState([]);
  const [jobState, setJobState] = useState(
    serviceLocator.getGlobalConfiguration().jobConfig.defaultJobState
  );
  const [number, setNumber] = useState(
    serviceLocator.getGlobalConfiguration().jobConfig.defaultPage
  );
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const safeAsync = useSafeAsync();

  const nextPage = number + (results && results.length > 1 ? 1 : 0);

  //TODO fetch it from API at some point
  const jobStates = [
    "CREATED",
    "ERROR",
    "FAILED",
    "QUEUED",
    "RUNNING",
    "SUCCEEDED",
    "TERMINATED",
  ];

  const setJobList = useCallback((data) => {
    const { content, number } = data;

    const updatedNumber =
      content && content.length > 0 ? number : Math.max(number - 1, 0);

    if (
      number === serviceLocator.getGlobalConfiguration().jobConfig.defaultPage
    ) {
      setResults(content);
    } else {
      setResults((prevResults) => [...prevResults, ...content]);
    }
    setNumber(updatedNumber);
    setIsLoading(false);
  }, []);

  const fetchJobList = useCallback(
    (
      jobState,
      page = serviceLocator.getGlobalConfiguration().jobConfig.defaultPage,
      ownRequestsOnly = true
    ) => {
      setError(null);
      setIsLoading(true);

      // Destructure global configuration for easier access
      const { pathBase, jobConfig } = serviceLocator.getGlobalConfiguration();

      // Define individual query parameters
      const jobStateParam = `${jobConfig.paramJobState}=${jobState}`;
      const directionParam = `${jobConfig.paramDirection}=${jobConfig.defaultDirection}`;
      const pageParam = `${jobConfig.paramPage}=${page}`;
      const sizeParam = `${jobConfig.paramSize}=${jobConfig.defaultSize}`;
      const jobTypesParam = `${jobConfig.paramJobTypes}=${jobConfig.defaultHistoryJobTypes}`;
      const ownRequestsOnlyParam = `ownRequestsOnly=${ownRequestsOnly}`;

      // Construct the full URL with template literals
      const url = `${pathBase}${jobConfig.jobListEndpoint}?${jobStateParam}&${directionParam}&${pageParam}&${sizeParam}&${jobTypesParam}&${ownRequestsOnlyParam}`;

      const { promise } = safeAsync(serviceLocator.getApiService().get(url));
      promise
        .then((result) => setJobList(result.data))
        .catch((error) => setError(error));
    },
    [safeAsync, setJobList] // Dependencies of fetchJobList
  );

  useEffect(() => {
    fetchJobList(jobState);

    return () => {
      setIsLoading(false);
    };
  }, [fetchJobList, jobState]);

  const onReload = () => {
    setNumber(serviceLocator.getGlobalConfiguration().jobConfig.defaultPage); // reset page
    fetchJobList(jobState);
  };

  const onChange = (value) => {
    const jobState =
      value === undefined
        ? serviceLocator.getGlobalConfiguration().jobConfig.defaultJobState
        : value;

    setNumber(serviceLocator.getGlobalConfiguration().jobConfig.defaultPage); // reset page
    setJobState(jobState); // change state

    fetchJobList(jobState);
  };

  const fileLink = (item) => lobsterApiService.buildExportFileLink(item);

  const onEditQuery = (item) => {
    // only BuiltQueryDataExportJob can be edited
    if (
      !item ||
      (item.type !== "BuiltQueryDataExportJob" &&
        item.type !== "PlaintextQueryDataExportJob")
    )
      return;

    if (item.type === "BuiltQueryDataExportJob") {
      // persist the item in the saved job stored in the localStorage
      const dto = item.dataExportDto; // present by design
      delete dto.taskId; // clean up
      localStorage.setItem("reportExport.formFieldsValue", JSON.stringify(dto));

      // navigate to the Report builder view
      history.push("/export");
    } else if (item.type === "PlaintextQueryDataExportJob") {
      // persist the item in the saved job stored in the localStorage
      const dto = item.plaintextQueryDataExportDto; // present by design
      delete dto.taskId; // clean up
      localStorage.setItem("queryEditor.formFieldsValue", JSON.stringify(dto));

      // navigate to the Query editor view
      history.push("/queryEditor");
    } // else // no action
  };

  return (
    <div className="job-list-page">
      {isLoading && CornerLoader}
      <div className={"filter-container"}>
        <CommonFilter
          value={jobState}
          options={jobStates}
          filterLabel={"State"}
          onChange={onChange}
          onReload={onReload}
        >
          Filter:
        </CommonFilter>
        <CommonButton onClick={onReload}>
          <ReloadOutlined />
        </CommonButton>
      </div>
      {error ? (
        <div>
          <p>Something went wrong.</p>
        </div>
      ) : results && results.length > 0 ? (
        <JobsTable
          list={results}
          onEditQuery={onEditQuery}
          fileLink={fileLink}
          hasAdditionalInfo={false}
        />
      ) : (
        <div>
          <p>No results.</p>
        </div>
      )}
      <div className="job-list-more">
        <CommonButton
          type="primary"
          onClick={() => fetchJobList(jobState, nextPage)}
          disabled={isLoading}
        >
          More
        </CommonButton>
      </div>
    </div>
  );
};

export default withRouter(History);
