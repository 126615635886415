import React, { useState } from "react";
import { Card, Drawer, Empty, List, Select } from "antd";
import CommonButton from "../../common/CommonButton";
import {
  CloseOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import ReactDragListView from "react-drag-listview";
import { withRouter } from "react-router-dom";
import FieldFormatHelper from "../../../utils/FieldFormatHelper";

const { Option } = Select;

// hydrated field list & filter & order by field display value
const normalizeFields = (fields) =>
  fields
    .filter((field) => field.selectable)
    .map((field) => FieldFormatHelper.enrichField(field))
    .sort((o1, o2) => (o1.display > o2.display ? 1 : -1));

const DetailsCardTabPane = withRouter(({ job, fields, history }) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const buildOptions = (fields) => {
    if (!fields) return;

    return normalizeFields(fields).map((field) => (
      <Option key={field.name} value={field.name}>
        {field.display}
      </Option>
    ));
  };

  // drawer + drag & drop
  const [items, setItems] = useState(job.favoriteItems);
  const onChange = (item) => {
    // add to item list if not present
    if (items.includes(item)) return;
    setItems((oldItems) => [...oldItems, item]);

    clearSelected();
  };
  const onRemoveItem = (item) => {
    // remove from item list if present
    if (!items.includes(item)) return;
    const newItems = items.filter((e) => e !== item);
    setItems((oldItems) => [...newItems]);
  };
  const [selected, setSelected] = useState();
  // handler
  const clearSelected = () => {
    setSelected(null);
  };
  const onDragEnd = (fromIndex, toIndex) => {
    // Ignores if outside designated area
    if (toIndex < 0) return;

    const newItems = [...items];
    const item = newItems.splice(fromIndex, 1)[0];
    newItems.splice(toIndex, 0, item);

    setItems((oldItems) => [...newItems]);
  };

  // On event, navigate the Report Builder view and fill the query builder form using job.data.dataExportDto.
  // Filter out taskId, mailRequested, mailTo props
  const onEditQuery = (job) => {
    // persist the item in the saved job stored in the localStorage
    const dto = job.data.dataExportDto; // present by design

    // hydration
    job.data.dataExportDto.fields.push(...items);

    // clean up
    delete dto.taskId;
    delete dto.mailRequested;
    delete dto.mailTo;

    localStorage.setItem("reportExport.formFieldsValue", JSON.stringify(dto));

    // navigate to the Report builder view
    history.push("/export");
  };

  const buildResult = (job, items) => {
    if (!(job.result && job.result.length > 0)) return;

    return (
      <>
        <ul>
          {job.result.map((r) => {
            return (
              <>
                <li>
                  {items
                    .filter((item) => r[item])
                    .map((item) => {
                      return (
                        <p>
                          {FieldFormatHelper.convertNameToDisplayName(item)}:{" "}
                          {r[item]}
                        </p>
                      );
                    })}
                </li>
              </>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <Card
      title={job.title}
      type="inner"
      style={{ margin: 5 }}
      extra={
        <>
          <div>
            <CommonButton
              title="Configure Field List"
              type="text"
              onClick={showDrawer}
            >
              <SettingOutlined />
            </CommonButton>
            <CommonButton
              title="Edit Query"
              type="text"
              onClick={() => onEditQuery(job)}
            >
              <EditOutlined />
            </CommonButton>
            <Drawer
              title="Select your Fields"
              placement="right"
              onClose={onClose}
              visible={visible}
              footer={
                <span style={{ color: "lightgrey" }}>
                  {job.title} [{fields && fields.length}]
                </span>
              }
            >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a field"
                onChange={onChange}
                value={selected}
              >
                {buildOptions(fields)}
              </Select>

              <ReactDragListView
                nodeSelector=".ant-list-item.draggble"
                onDragEnd={onDragEnd}
              >
                <List
                  size="small"
                  dataSource={items}
                  renderItem={(item) => {
                    return (
                      <List.Item
                        className={"draggble"}
                        extra={
                          <CommonButton
                            type="text"
                            size="small"
                            onClick={() => onRemoveItem(item)}
                          >
                            <CloseOutlined />
                          </CommonButton>
                        }
                      >
                        {FieldFormatHelper.convertNameToDisplayName(item)}
                      </List.Item>
                    );
                  }}
                />
              </ReactDragListView>
            </Drawer>
          </div>
        </>
      }
    >
      {job && (!job.result || (job.result && job.result.length === 0)) && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{
            height: 30,
          }}
        />
      )}
      {job && job.data && job.data.state === "SUCCEEDED" && (
        <div>
          {buildResult(job, items)}
          {job.resultError ? (
            <div>{"Error! reason: " + JSON.stringify(job.resultError)}</div>
          ) : (
            ""
          )}
        </div>
      )}
    </Card>
  );
});

export default DetailsCardTabPane;
