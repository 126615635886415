import React from "react";
import { Spin } from "antd";
import "./CornerLoader.css";

export const CornerLoader = (
  <div className="common-loading">
    <Spin size="large" />
  </div>
);

export default CornerLoader;
